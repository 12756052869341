/* eslint-disable no-alert */
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';
import React, { useState, useEffect } from 'react';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Link } from '@abyss/web/ui/Link';
import {
  DataTableSearchLabel,
  MlreDataTable,
  MlreLoader,
} from '@src/common/MlreAbyssComponent';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import axios from 'axios';

import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import useAuth from '@src/auth/useAuth';

import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import { useRouter } from '@abyss/web/hooks/useRouter';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import { CustomerPolicyListIcon } from '@src/components/customer/CustomerHelpIcons';
import { isEqual } from 'lodash';
import notes_available from '../../assets/notes_available.png';
import notes_empty from '../../assets/notes_empty.png';
import env from '../../../env.json';
import { CustomerHoldStatus } from './CustomerHoldStatus';
import { BASE_URL } from '../utils/endpoints';
import { CustomerNote } from './CustomerNote';
import { CustomerPolicyListHistory } from './CustomerPolicyListHistory';

const CustomerPolicyList = () => {
  const { getLocation } = useRouter();
  const { location, hash, state } = getLocation();
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [childPolicies, setChildPolicies] = useState([]);
  const [policyDetails, setPolicyDetails] = useState([]);
  const { token, userRolesGroups } = useAuth();
  const { navigate } = useRouter();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [componentType, setComponentType] = useState('');
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const [notesStatus, setNotesStatus] = useState('empty');
  const [notes, setNotes] = useState([]);
  const [refreshNote, setRefreshNote] = useState(false);
  const [customerStateData, setCustomerStateData] = useState(() => {
    const searchData = localStorage.getItem('searchData');
    return searchData ? JSON.parse(searchData) : [];
  });

  const custId = location?.state?.[0]?.custId;
  const data = location?.state;
  const breadcrums = state?.breadcrums;
  const breadcrumsInfo = state?.breadcrumsInfo;

  const changeComponent = (type) => {
    setComponentType(type);
    window.scrollTo(0, 280);
  };

  useEffect(() => {
    if (showHistory) {
      changeComponent('history');
    }
  }, [showHistory]);
  const newBreadCrum = breadcrums || breadcrumsInfo;

  const breadItem = breadcrums
    ? [
        ...breadcrums,
        {
          title: 'Customer Policy List',
          href: '/customer-policyList',
        },
      ]
    : [
        { title: 'Customer Search', href: '/Customer/' },
        {
          title: 'Customer Policy',
          href: '/Customer#form',
          state: {
            breadcrumData: state.data,
            SearchData: state.data.SearchData,
          },
        },
        { title: 'Customer Policy List ', href: '/customer-policyList' },
      ];

  const zip = state?.data?.SearchData[0]?.custZipCd || '';
  const zip2 = state?.data?.SearchData[0]?.custZipExtCd || '';

  let splitZip = zip?.includes('-') ? zip.split('-') : [zip, zip2];

  if (!zip.includes('-') && zip2) {
    splitZip = [zip, zip2];
  }

  const formDefaultData = {
    ...state?.data?.SearchData[0],
    zip_code: splitZip?.[0] || '',
    zip_code2: splitZip?.[1] || '',
    'customer-name': state?.data?.SearchData[0]?.custNm || '',
    address: state?.data?.SearchData[0]?.custAdrLn1 || '',
    address_1: state?.data?.SearchData[0]?.custAdrLn2 || '',
    address_2: state?.data?.SearchData[0]?.custAdrLn3 || '',
    city: state?.data?.SearchData[0]?.custCityNm || '',
    state: state?.data?.SearchData[0]?.custStCd || '',
  };

  const form = useForm({
    defaultValues: formDefaultData,
  });

  const [initialFormValues, setInitialFormValues] = useState(formDefaultData);

  useEffect(() => {
    form.setValue('customer-name', customerStateData[0]?.custNm);
    form.setValue('address', customerStateData[0]?.custAdrLn1);
    form.setValue('address_1', customerStateData[0]?.custAdrLn2);
    form.setValue('address_2', customerStateData[0]?.custAdrLn3);
    form.setValue('city', customerStateData[0]?.custCityNm);
    form.setValue('state', customerStateData[0]?.custStCd);
  }, [state]);

  useEffect(() => {
    if (custId) {
      axios
        .post(
          `${BASE_URL}customer/get-customer-detail`,
          { custId },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          setCustomerData(res.data);
        })
        .catch((error) => {
          console.error('Error', error);
        });
    }
  }, [custId]);

  const fetchChildPolicies = async () => {
    const payload = {
      srcSystemCd: customerStateData[0]?.srcSystemCd,
      srcSysCustId: customerStateData[0]?.srcSysCustId,
      atr5: customerStateData[0]?.atr5,
      rbtReportYr: customerStateData[0]?.rbtReportYr,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}customer/get-child-detail`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setChildPolicies(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateAddress = async () => {
    const data = form.getValues();
    setSaveIsButtonDisabled(true);
    setLoading(true);

    if (isEqual(data, initialFormValues)) {
      alert('No changes to save.');
      setSaveIsButtonDisabled(false);
      setLoading(false);
      return;
    }
    if (!data['customer-name'] || data['customer-name'].trim() === '') {
      alert('Customer name is required.');
      setSaveIsButtonDisabled(false);
      setLoading(false);
      return;
    }
    if (!data.address || data.address.trim() === '') {
      alert('Customer address is required.');
      setSaveIsButtonDisabled(false);
      setLoading(false);
      return;
    }
    if (!data.city || data.city.trim() === '') {
      alert('City name is required.');
      setSaveIsButtonDisabled(false);
      setLoading(false);
      return;
    }
    if (!data.state || data.state.trim() === '') {
      alert('State code is required.');
      setSaveIsButtonDisabled(false);
      setLoading(false);
      return;
    }
    if (!data.zip_code || data.zip_code.trim() === '') {
      alert('ZIP code is required.');
      setSaveIsButtonDisabled(false);
      setLoading(false);
      return;
    }

    const payload = {
      custAdrLn1: encodeURI(data.address).replaceAll('%', 'perct'),
      custAdrLn2: encodeURI(data.address_1).replaceAll('%', 'perct') || '',
      custAdrLn3: encodeURI(data.address_2).replaceAll('%', 'perct') || '',
      custCityNm: data.city,
      custStCd: data.state,
      custZipCd: data.zip_code,
      srcSystemCd: state?.data?.SearchData[0]?.srcSystemCd,
      srcSysCustId: state?.data?.SearchData[0]?.srcSysCustId,
      rbtReportYr: state?.data?.SearchData[0]?.rbtReportYr,
      custNm: encodeURI(data['customer-name']).replaceAll('%', 'perct') || '',
      custZipExtCd: data.zip_code2,
      adrSrcInd: state?.data?.SearchData[0]?.adrSrcInd,
      custNmSrcInd: state?.data?.SearchData[0]?.custNmSrcInd,
      custId: state?.data?.SearchData[0]?.custId,
      modDttm: state?.data?.SearchData[0]?.modDttm,
      atr5: state?.data?.SearchData[0]?.atr5,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}customer/update-addess`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data) {
        alert(response.data);
        handleCustomerData();
        setIsUpdateDisabled(true);
        setInitialFormValues(data);
        setSaveIsButtonDisabled(false);
        await fetchChildPolicies();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
      setSaveIsButtonDisabled(false);
      setLoading(false); // Hide loader on error
    }
  };
  const customerType = state?.data?.SearchData[0]?.custTypeCd;

  const onSubmit = (data) => {
    console.log('Data', data);
  };

  const handlePolicy = async (policyId) => {
    try {
      const payload = {
        rbtReportYr: state?.data?.SearchData[0]?.rbtReportYr,
        srcSystemCd: state?.data?.SearchData[0]?.srcSystemCd,
        srcSysCustId: policyId,
        atr5: state?.data?.SearchData[0]?.atr5,
      };

      const response = await axios.post(
        `${BASE_URL}customer/search-customer`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const policyDataResponse = response.data;

      if (!policyDataResponse || policyDataResponse.length === 0) {
        console.log('Policy not found.');
      } else {
        setPolicyDetails(policyDataResponse);
        if (breadcrums) {
          if (breadcrums[3]) {
            navigate('/aog-customer', {
              state: {
                ...breadcrums[3]?.state,
                isPolicyList: true,
                SearchData: policyDataResponse,
              }, // from ag to customer
            });
          } else if (breadcrums[2]) {
            navigate('/aog-customer', {
              state: {
                ...breadcrums[2]?.state,
                isPolicyList: true,
                SearchData: policyDataResponse,
              }, // form aog to customer
            });
          } else {
            navigate('/Customer#form', {
              state: {
                ...breadcrums[1]?.state,
                breadcrumData: policyDataResponse,
                SearchData: policyDataResponse,
              },
            });
          }
        } else {
          navigate('/Customer#form', {
            state: {
              breadcrumData: policyDataResponse,
              SearchData: policyDataResponse,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePolicyData = () => {
    if (breadcrums) {
      navigate('/aog-customer', {
        state: breadcrums[3]?.state,
      });
    } else {
      navigate('/Customer#form', {
        state: { breadcrumData: state.data, SearchData: state.data.SearchData },
      });
    }
  };

  const handleReset = () => {
    const currentFormValues = form.getValues();
    const hasUnsavedChanges = !isEqual(currentFormValues, initialFormValues);

    if (hasUnsavedChanges) {
      Object.keys(initialFormValues).forEach((key) => {
        form.setValue(key, initialFormValues[key]);
      });
    }
  };

  const handleCancel = () => {
    const currentFormValues = form.getValues();
    const hasUnsavedChanges = !isEqual(currentFormValues, initialFormValues);

    if (hasUnsavedChanges) {
      const confirmation = window.confirm(
        'Are you sure you want to cancel without saving changes?'
      );
      if (confirmation) {
        Object.keys(initialFormValues).forEach((key) => {
          form.setValue(key, initialFormValues[key]);
        });
        setIsUpdateDisabled(true);
      }
    } else {
      setIsUpdateDisabled(true);
    }
  };

  useEffect(() => {
    const hasUpdateAccess = env.Customer_Update_policy.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups, env.Customer_Update_policy]);

  const fetchNotes = () => {
    const noteType = 'Customer';

    try {
      const result = axios
        .get(
          `${BASE_URL}customer/get/notes/list/${state?.data?.SearchData[0]?.rbtReportYr}/${state?.data?.SearchData[0]?.custContrId}/${noteType}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setNotes(response.data);
            setNotesStatus('available');
          } else {
            setNotes([]);
            setNotesStatus('empty');
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (componentType === 'note') {
      fetchNotes();
    }
  }, [componentType]);

  useEffect(() => {
    if (refreshNote) {
      fetchNotes();
      setRefreshNote(false);
    }
  }, [refreshNote]);

  useEffect(() => {
    fetchChildPolicies();
    fetchNotes();
  }, []);

  const policyData = childPolicies.map((item) => {
    return {
      col1: item?.srcSystemContrId,
      col2: item?.custContrNm,
      col3: item?.custNm,
      col4: [
        item?.custContrAdrLn1,
        item?.custContrAdrLn2,
        item?.custContrAdrLn3,
        item?.custContrCityNm,
        item?.custContrStCd,
        item?.custContrZipCd,
        item?.custContrExZipCd ? `-${item?.custContrExZipCd}` : null,
      ]
        .filter(Boolean)
        .join(', '),
    };
  });

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Policy',
        accessor: 'col1',
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                handlePolicy(value);
              }}
              href="#"
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Policy Name',
        accessor: 'col2',
        disableSortBy: true,
      },
      {
        Header: 'Customer Name',
        accessor: 'col3',
        disableSortBy: true,
      },
      {
        Header: 'Address',
        accessor: 'col4',
        disableSortBy: true,
      },
    ];
  }, []);

  const searchLabel = [
    {
      fieldName: 'Source System',
      value: state?.data?.SearchData[0]?.srcSystemCd,
    },
    {
      fieldName: 'Rebate Report Year',
      value: state?.data?.SearchData[0]?.rbtReportYr,
    },
  ];

  const handleDisableFields = () => {
    setIsUpdateDisabled(false);
    if (componentType === 'history') {
      setComponentType('');
    }
    if (customerType === 'E') {
      setIsUpdateDisabled(true);
    } else {
      setIsUpdateDisabled(false);
    }
  };

  const handleCustomerData = async () => {
    try {
      const payload = {
        rbtReportYr: customerStateData[0].rbtReportYr,
        srcSystemCd: customerStateData[0].srcSystemCd,
        srcSysCustId: customerStateData[0].srcSystemContrId,
        atr5: customerStateData[0].atr5,
      };
      const response = await axios.post(
        `${BASE_URL}customer/search-customer`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCustomerStateData(response.data);
      localStorage.setItem('searchData', JSON.stringify(response.data));
      return response.data; // Return the response data
    } catch (error) {
      console.error(error);
      return null; // Return null or an appropriate fallback value in case of an error
    }
  };

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItem}
                infoTitle="Customer Policy List"
                InfoContent={CustomerPolicyListIcon}
              />
              <DataTableSearchLabel searchLabel={searchLabel} />
              <br />
              <Grid>
                <Grid.Col span={{ xs: 12, md: 6 }}>
                  <Grid>
                    <Grid.Col span={{ xs: 6, md: 6 }}>
                      <Text fontWeight="bold">Customer ID</Text>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 6, md: 6 }}>
                      <Text>: {state?.data?.SearchData[0]?.srcSysCustId}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 6, md: 6 }}>
                      <Text fontWeight="bold">Customer Type</Text>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 6, md: 6 }}>
                      <Text>: {state?.data?.SearchData[0]?.custTypeCd}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 6, md: 6 }}>
                      <Text fontWeight="bold">
                        Customer Name
                        <Text
                          color="#000"
                          fontWeight="bold"
                          css={{ color: 'red !important' }}
                        >
                          *
                        </Text>
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 6, md: 6 }}>
                      <FormProvider state={form} onSubmit={onSubmit}>
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label=""
                          model="customer-name"
                          onBlur={(e) => {
                            if (e.target.value) {
                              form.setValue(
                                'customer-name',
                                e.target.value.toUpperCase()
                              );
                            }
                          }}
                        />
                      </FormProvider>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 6 }}>
                  <Grid>
                    <Grid.Col span={{ xs: 11, md: 9 }}>
                      <FormProvider state={form} onSubmit={onSubmit}>
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label="Address"
                          model="address"
                          maxLength={60}
                          validators={{ required: true }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              form.setValue(
                                'address',
                                e.target.value.toUpperCase()
                              );
                            }
                          }}
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label=""
                          model="address_1"
                          maxLength={60}
                          onBlur={(e) => {
                            if (e.target.value) {
                              form.setValue(
                                'address_1',
                                e.target.value.toUpperCase()
                              );
                            }
                          }}
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label=""
                          model="address_2"
                          maxLength={60}
                          onBlur={(e) => {
                            if (e.target.value) {
                              form.setValue(
                                'address_2',
                                e.target.value.toUpperCase()
                              );
                            }
                          }}
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label="City"
                          model="city"
                          maxLength={60}
                          validators={{ required: true }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              form.setValue(
                                'city',
                                e.target.value.toUpperCase()
                              );
                            }
                          }}
                        />
                        <TextInput
                          isDisabled={isUpdateDisabled}
                          label="State"
                          model="state"
                          maxLength={10}
                          validators={{ required: true }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              form.setValue(
                                'state',
                                e.target.value.toUpperCase()
                              );
                            }
                          }}
                        />
                        <Grid>
                          <Grid.Col span={{ xs: 11, md: 6 }}>
                            <TextInput
                              isDisabled={isUpdateDisabled}
                              label="ZIP Code"
                              model="zip_code"
                              maxLength={5}
                              onChange={(e) => {
                                const charCode = e.keyCode || e.which;
                                const charStr = String.fromCharCode(charCode);

                                if (!/^[0-9]+$/.test(charStr)) {
                                  e.preventDefault();
                                }
                              }}
                              validators={{
                                required: true,
                              }}
                              onBlur={(e) => {
                                if (e.target.value) {
                                  form.setValue(
                                    'zip_code',
                                    e.target.value.toUpperCase()
                                  );
                                }
                              }}
                            />
                          </Grid.Col>
                          <Grid.Col
                            span={{ xs: 11, md: 6 }}
                            css={{ marginTop: '25px' }}
                          >
                            <TextInput
                              isDisabled={isUpdateDisabled}
                              label=""
                              model="zip_code2"
                              maxLength={4}
                              onChange={(e) => {
                                const charCode = e.keyCode || e.which;
                                const charStr = String.fromCharCode(charCode);

                                if (!/^[0-9]+$/.test(charStr)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Grid.Col>
                        </Grid>
                      </FormProvider>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 1, md: 3 }}>
                      <Tooltip content="History">
                        <Button
                          rounded
                          size="$sm"
                          css={{ backgroundColor: 'none !important' }}
                        >
                          <IconMaterial
                            css={{ cursor: 'pointer' }}
                            icon="history"
                            onClick={() => {
                              changeComponent('history');
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12} css={{ textAlign: 'center' }}>
                  <Button
                    onClick={() => {
                      changeComponent('hold');
                    }}
                    style={{ margin: '10px', borderRadius: '4px' }}
                  >
                    Hold Status
                  </Button>

                  {customerType !== 'I' && customerType !== 'E' && (
                    <React.Fragment>
                      {isUpdateDisabled ? (
                        <Button
                          isDisabled={isButtonDisabled}
                          onClick={(e) => {
                            e.preventDefault();
                            handleDisableFields();
                          }}
                          style={{ margin: '10px', borderRadius: '4px' }}
                        >
                          Update Detail
                        </Button>
                      ) : (
                        <React.Fragment>
                          <Button
                            type="submit"
                            onClick={updateAddress}
                            isDisabled={isSaveButtonDisabled}
                            style={{ margin: '0px 10px', borderRadius: '4px' }}
                          >
                            Save
                          </Button>
                          <Button
                            style={{ margin: '0px 10px', borderRadius: '4px' }}
                            onClick={handleReset}
                          >
                            Reset
                          </Button>
                          <Button
                            onClick={handleCancel}
                            style={{ margin: '0px 10px', borderRadius: '4px' }}
                          >
                            Cancel
                          </Button>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}

                  <Button
                    onClick={() => {
                      changeComponent('note');
                    }}
                    style={{
                      color: notesStatus !== 'available' ? '#15A796' : 'white',
                      backgroundColor:
                        notesStatus !== 'available' ? 'white' : '#15A796',
                      border:
                        notesStatus !== 'available' ? '1px solid #15A796' : '',
                      margin: '10px',
                      borderRadius: '4px',
                      transition: 'all 0.3s ease', // Optional for smooth transition
                    }}
                  >
                    Notes
                  </Button>
                </Grid.Col>
                <Grid.Col span={12}>
                  {componentType === 'note' ? (
                    <React.Fragment>
                      <CustomerNote
                        data={customerStateData}
                        notesData={notes}
                        onClose={() => {
                          return setComponentType('');
                        }}
                        handleNoteSave={() => {
                          setRefreshNote(true);
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {componentType === 'hold' ? (
                    <React.Fragment>
                      <CustomerHoldStatus
                        data={customerStateData}
                        onClose={() => {
                          return setComponentType('');
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </Grid.Col>
              </Grid>

              {componentType === 'history' ? (
                <React.Fragment>
                  <CustomerPolicyListHistory
                    data={customerStateData}
                    onClose={() => {
                      return setComponentType('');
                    }}
                  />
                </React.Fragment>
              ) : (
                <div style={{ marginTop: '20px' }}>
                  {loading ? (
                    <MlreLoader />
                  ) : (
                    <MlreDataTable data={policyData} columns={columns} />
                  )}
                </div>
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default withAuth(CustomerPolicyList);
