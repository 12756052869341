import React, { useState, useEffect } from 'react';
import { AddLegalEntity, LegalEntityTable } from '@src/components/legal-entity';
import { styled } from '@abyss/web/tools/styled';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';

import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import {
  AddHelpContent,
  HelpContent,
} from '@src/components/legal-entity/LegalEntityHelpIcon';
import { shouldProcessLinkClick } from 'react-router-dom/dist/dom';
import useAuth from '@src/auth/useAuth';
import { MlreLoader } from '@src/common/MlreAbyssComponent';
import env from '../../../env.json';

const LegalEntity = () => {
  const { userRolesGroups } = useAuth();
  const [showSearchData, setShowSearchData] = useState(true);
  const { navigate, getLocation } = useRouter();
  const { hash } = getLocation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonHidden, setIsButtonHidden] = useState(false);

  const [isHyperlinkDisabled, setIsHyperlinkDisabled] = useState(false);
  const [isHyperlinkHidden, setIsHyperlinkHidden] = useState(false);
  const [loading, setLoading] = useState(true);

  const [breadItem, setBreadItem] = useState([
    { title: 'Legal Entity', href: '/legal-entity/' },
  ]);

  const isForm = hash == '#form';
  const handleLegalEntity = () => {
    navigate('#form');
    setShowSearchData(false);
    setBreadItem([
      { title: 'Legal Entity', href: '/legal-entity/' },
      { title: 'Add Legal Entity', href: '/add-legal-entity' },
    ]);
  };
  const handleCancelLegalEntity = () => {
    setShowSearchData(true);
  };

  useEffect(() => {
    if (!isForm) {
      setShowSearchData(true);
    } else {
      setShowSearchData(false);
      // navigate('');
    }
  }, [isForm]);

  useEffect(() => {
    const hasWriteAccess = env.LegalEntity_Add.some((role) => {
      return userRolesGroups.includes(role);
    });
    setIsButtonDisabled(!hasWriteAccess);
    const hasUpdateAccess = env.LegalEntity_Update.some((role) => {
      return userRolesGroups.includes(role);
    });

    setIsHyperlinkDisabled(!hasUpdateAccess);

    setLoading(false);
  }, [userRolesGroups, env.LegalEntity_Update]);

  const FormOuterContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center;',
    width: '100%',
  });

  const FormContainer = styled('div', {
    width: '40%',
    textAlign: 'center',
    variants: {
      medium: {
        true: {
          width: '90%',
        },
      },
    },
  });

  const isMedium = useMediaQuery('(max-width: 744px)');

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            {!showSearchData ? (
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Edit Legal Entity"
                  InfoContent={AddHelpContent}
                />

                <FormOuterContainer>
                  <FormContainer medium={isMedium}>
                    <AddLegalEntity
                      onCancelActivity={handleCancelLegalEntity}
                    />
                  </FormContainer>
                </FormOuterContainer>
              </BodyPageContainer>
            ) : (
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Legal Entity Search"
                  InfoContent={HelpContent}
                />
                {loading ? (
                  <MlreLoader />
                ) : isButtonDisabled === null ||
                  isHyperlinkDisabled === null ? (
                  <MlreLoader />
                ) : (
                  <LegalEntityTable
                    onAddEntity={handleLegalEntity}
                  // isButtonDisabled={isButtonDisabled}
                  // isHyperlinkDisabled={isHyperlinkDisabled}
                  />
                )}
              </BodyPageContainer>
            )}
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default withAuth(LegalEntity);
