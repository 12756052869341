import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from '@abyss/web/ui/Router';
import Home from '@src/pages/Home';
import Configuration from '@src/pages/MLRE/Configuration';
import AggregationGroup from '@src/pages/MLRE/AggregationGroup';
import AggregationOperationGroup from '@src/pages/MLRE/AggregationOperationGroup';
import AggregationGroupOpration from '@src/pages/MLRE/AggregationGroupOpration';
import LegalEntity from '@src/pages/MLRE/LegalEntity';
import Customer from '@src/pages/MLRE/Customer';
import EmpolyerConversionCheck from '@src/components/customer/EmployerConversionChecks';
import PaymentDetails from '@src/components/customer/PaymentDetails';
// import SubscriberDataTable from '@src/components/Subscriber';
import SubscriberDataTable from '@src/components/Subscriber/SubscriberDataTable';
import SubscriberView from '@src/components/Subscriber/SubscriberView';
import Reports from '@src/pages/MLRE/Reports';
import CustomerPolicyList from '@src/components/customer/CustomerPolicyList';
import Subscriber from '@src/pages/MLRE/Subscriber';
import Payment from '@src/pages/MLRE/Payments';
import AddLegalEntity from '@src/components/legal-entity/AddLegalEntity';
import UpdateLegalEntity from '@src/components/legal-entity/UpdateLegalEntity';

import { LegalEntityTable } from '@src/components/legal-entity/';
import Login from '@src/auth/Login';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import { msalConfig } from '@src/auth/authConfig';
import AOGCustomer from '@src/pages/MLRE/AOGCustomer';
import CustomerSubscriberTable from '@src/components/customer/CustomerSubscriberTable';
import SubscriberPayment from '@src/pages/MLRE/SubscriberPayment';
import { PaymentDetail } from '@src/components/payments/PaymentDetail';
import CheckReissueApproval from '@src/pages/MLRE/CheckReissueApproval';
import CheckPendingReissueApproval from '@src/components/checkReissueApproval/CheckPendingReissueApproval';
import CheckReissueResult from '@src/components/checkReissueApproval/CheckReissueResult';
import CheckPendingRedistributeApprove from '@src/components/checkReissueApproval/CheckPendingRedistributeApprove';
import CheckRedistribeApprove from '@src/components/checkReissueApproval/CheckRedistribeApprove';
import CheckPendingReject from '@src/components/checkReissueApproval/CheckPendingReject';
import SubscriberCloneSearch from '@src/components/subscriberclone/SubscriberCloneSearch';
import RevenueNoSubsciber from '@src/components/subscriberclone/RevenueNoSubsciber';
import SelectSourceAOG from '@src/components/subscriberclone/SelectSourceAOG';

export const msalInstance = new PublicClientApplication(msalConfig);

export const Routes = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Router.Routes title="{{title}} | Abyss">
          <Router.Route path="/login" element={<Login />} />
          <Router.Route path="/" element={<Home />} />
          <Router.Route path="/configuration" element={<Configuration />} />
          <Router.Route path="/customer" element={<Customer />} />

          <Router.Route
            path="/aggregation-group"
            element={<AggregationGroup />}
          />

          <Router.Route
            path="/aggregation-group-operation"
            element={<AggregationGroupOpration />}
          />
          <Router.Route
            path="/aggregation-operation-group"
            element={<AggregationOperationGroup />}
          />
          <Router.Route path="/aog-customer" element={<AOGCustomer />} />
          <Router.Route path="/subscriber" element={<Subscriber />} />
          <Router.Route path="/legal-entity" element={<LegalEntity />} />
          <Router.Route path="/payment-detail" element={<PaymentDetail />} />
          <Router.Route
            path="/customer-policyList"
            element={<CustomerPolicyList />}
          />
          <Router.Route
            path="/employer-conversion-check"
            element={<EmpolyerConversionCheck />}
          />
          <Router.Route
            path="/subscriber-list"
            element={<SubscriberDataTable />}
          />
          <Router.Route
            path="/check-reissue-approval"
            element={<CheckReissueApproval />}
          />

          <Router.Route
            path="/pending-reissue-approval"
            element={<CheckPendingReissueApproval />}
          />
          <Router.Route
            path="/reissue-approved"
            element={<CheckReissueResult />}
          />

          <Router.Route
            path="/reissue-rejected"
            element={<CheckPendingReject />}
          />
          <Router.Route
            path="/pending-redistribute-approval"
            element={<CheckPendingRedistributeApprove />}
          />

          <Router.Route path="/reports" element={<Reports />} />
          <Router.Route
            path="/subscriber-view/:subscriberID?"
            element={<SubscriberView />}
          />
          <Router.Route
            path="/subscriber-payment"
            element={<SubscriberPayment />}
          />
          <Router.Route path="/payment" element={<Payment />} />
          <Router.Route
            path="/update-legal-entity"
            element={<UpdateLegalEntity />}
          />
          <Router.Route
            path="/customer/subscriber/list"
            element={<CustomerSubscriberTable />}
          />
          <Router.Route
            path="/subscriber-clone"
            element={<SubscriberCloneSearch />}
          />
          <Router.Route
            path="/subscriber-clone-result"
            element={<RevenueNoSubsciber />}
          />

          <Router.Route
            path="/check-redistribe-approve"
            element={<CheckRedistribeApprove />}
          />

          <Router.Route path="/ssaog" element={<SelectSourceAOG />} />
        </Router.Routes>
      </BrowserRouter>
    </MsalProvider>
  );
};
