import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { Routes } from './routes';

const themeOverride = {
  theme: {
    colors: {
      // primary
      primary1: '#15A796',
      primary2: '#FFFFFF',

      secondary1: '#00BED5',
      secondary2: '#F5B700',

      // interactive
      interactive1: '#218371',
      interactive2: '#15A796',
      interactiveCb: 'rgb(112 172 169)',
      // interactiveCb: '#a9a9a9',
      inactive: 'rgb(112 172 169)',

      pageBackgroundColor: '#E5F8FB',
      headerBackgroundColor: '#D0F1F5',
      sideBarBackgroundColor: '#D0F1F5',
      'pageFooter-color-background-root': '$interactive2',

      gray1: '#E5F8FB',
      gray2: '#D0F1F5',
    },
  },
};

// const theme = createTheme('uhg');
const theme = createTheme('uhg', themeOverride);
const router = createRouter(Routes);

export const browser = () => {
  return <AbyssProvider theme={theme} router={router} />;
};
