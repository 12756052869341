import { Grid } from '@abyss/web/ui/Grid';
import React from 'react';
import { Text } from '@abyss/web/ui/Text';

export const DataTableSearchLabel = ({ searchLabel }) => {
  return (
    <Grid css={{ padding: '20px 30px' }}>
      {searchLabel.map((item) => {
        if (!item.value) {
          return '';
        }
        return (
          <React.Fragment>
            <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 8, md: 2 }}>
              <Text color="$interactive1" fontWeight="bold">
                {item.fieldName}
              </Text>
            </Grid.Col>
            <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 4, md: 2 }}>
              <Text fontWeight="bold">: {item.value}</Text>
            </Grid.Col>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
