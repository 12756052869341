import React, { useEffect, useState } from 'react';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import axios from 'axios';
import { AggregationFormData } from './AggregationGroupConstants';
import { BASE_URL } from '../utils/endpoints';

export const AggregationForm = ({ onSearch }) => {
  const form = useForm();
  const [selectBoxData, setSelectBoxData] = useState({
    rbtRptYr: [],
    runTypCd: [],
    runTypSeqNbr: [],
    mlrCseSz: [],
    mlrSitusCd: [],
    lglEntyId: [],
  });

  const [formData, setFormData] = useState({
    rbtRptYr: '',
    runTypCd: '',
    runTypSeqNbr: '',
    mlrCseSz: '',
    mlrSitusCd: '',
    lglEntyId: '',
  });

  const [isDropDownLoading, setIsDropDownLoading] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (data) => {
    // Save form state to localStorage
    localStorage.setItem('aggregationFormData', JSON.stringify(data));
    onSearch(data);
  };

  useEffect(() => {
    if (!form.formState.isValid) {
      setLoading(false);
    }
  }, [form.formState]);

  // To sort the array of objects in ascending order
  const sortParameter = (data, field) => {
    if (field === 'rbtRptYr') {
      return data.sort((a, b) => {
        return a.value < b.value ? 1 : -1; // Descending order
      });
    }
    return data.sort((a, b) => {
      return a.value > b.value ? 1 : -1; // Ascending order
    });
  };

  const fetchSelectBoxRows = async (payload = {}, field) => {
    try {
      const result = await axios.post(
        `${BASE_URL}aggregation-group/get-ag-dropdowns`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const rows = result?.data?.response;
      const selectBoxRows = [];
      rows?.map((item) => {
        selectBoxRows.push({ value: item, label: item });
      });
      return sortParameter(selectBoxRows, field);
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
      return [];
    } finally {
      setIsDropDownLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchSelectBoxRows({}, 'rbtRptYr');
      const savedFormData = localStorage.getItem('aggregationFormData');
      if (savedFormData) {
        const parsedFormData = JSON.parse(savedFormData);
        Object.keys(parsedFormData).forEach((key) => {
          form.setValue(key, parsedFormData[key]);
        });

        // Fetch dependent dropdown data based on saved form data
        const runTypCdResult = parsedFormData.rbtRptYr
          ? await fetchSelectBoxRows(
              { rbtRptYr: parsedFormData.rbtRptYr },
              'runTypCd'
            )
          : [];
        const runTypSeqNbrResult = parsedFormData.runTypCd
          ? await fetchSelectBoxRows(
              {
                rbtRptYr: parsedFormData.rbtRptYr,
                runTypCd: parsedFormData.runTypCd,
              },
              'runTypSeqNbr'
            )
          : [];
        const mlrCseSzResult = parsedFormData.runTypSeqNbr
          ? await fetchSelectBoxRows(
              {
                rbtRptYr: parsedFormData.rbtRptYr,
                runTypCd: parsedFormData.runTypCd,
                runTypSeqNbr: parsedFormData.runTypSeqNbr,
              },
              'mlrCseSz'
            )
          : [];
        const mlrSitusCdResult = parsedFormData.mlrCseSz
          ? await fetchSelectBoxRows(
              {
                rbtRptYr: parsedFormData.rbtRptYr,
                runTypCd: parsedFormData.runTypCd,
                runTypSeqNbr: parsedFormData.runTypSeqNbr,
                mlrCseSz: parsedFormData.mlrCseSz,
              },
              'mlrSitusCd'
            )
          : [];
        const lglEntyIdResult = parsedFormData.mlrSitusCd
          ? await fetchSelectBoxRows(
              {
                rbtRptYr: parsedFormData.rbtRptYr,
                runTypCd: parsedFormData.runTypCd,
                runTypSeqNbr: parsedFormData.runTypSeqNbr,
                mlrCseSz: parsedFormData.mlrCseSz,
                mlrSitusCd: parsedFormData.mlrSitusCd,
              },
              'lglEntyId'
            )
          : [];

        setSelectBoxData({
          rbtRptYr: result,
          runTypCd: runTypCdResult,
          runTypSeqNbr: runTypSeqNbrResult,
          mlrCseSz: mlrCseSzResult,
          mlrSitusCd: mlrSitusCdResult,
          lglEntyId: lglEntyIdResult,
        });
      } else {
        setSelectBoxData({
          rbtRptYr: result,
          runTypCd: [],
          runTypSeqNbr: [],
          mlrCseSz: [],
          mlrSitusCd: [],
          lglEntyId: [],
        });
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once on mount

  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <SelectInput
        isDisabled={selectBoxData?.rbtRptYr?.length == 0}
        label={AggregationFormData.value}
        isLoading={isDropDownLoading}
        placeholder="Select One"
        model={AggregationFormData.value9}
        isSearchable={false}
        value={form.getValues(AggregationFormData.value9)}
        validators={{ required: AggregationFormData.value10 }}
        onChange={async () => {
          const response = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
          });
          const result = sortParameter(response);

          setSelectBoxData({
            ...selectBoxData,
            runTypCd: result,
            runTypSeqNbr: [],
            mlrCseSz: [],
            mlrSitusCd: [],
            lglEntyId: [],
          });
        }}
        options={selectBoxData.rbtRptYr}
      />
      <SelectInput
        label={AggregationFormData.value1}
        // placeholder="Select One"
        placeholder={selectBoxData?.runTypCd?.length === 0 ? '' : 'Select One'}
        model={AggregationFormData.value13}
        isLoading={isDropDownLoading}
        value={formData.runTypCd}
        isSearchable={false}
        validators={{ required: AggregationFormData.value11 }}
        onChange={async () => {
          const response = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
          });
          const result = sortParameter(response);
          let respMlreCaseSize = [];
          if (result.length === 1) {
            respMlreCaseSize = await fetchSelectBoxRows({
              rbtRptYr: form.control._formValues.rbtRptYr,
              runTypCd: form.control._formValues.runTypCd,
              runTypSeqNbr: result[0].value,
            });
            form.setValue(AggregationFormData.value14, result[0].value);
          }
          setSelectBoxData({
            ...selectBoxData,
            runTypSeqNbr: result,
            mlrCseSz: sortParameter(respMlreCaseSize),
            mlrSitusCd: [],
            lglEntyId: [],
          });
        }}
        options={selectBoxData.runTypCd}
        isDisabled={selectBoxData?.runTypCd?.length == 0}
      />
      <SelectInput
        label={AggregationFormData.value2}
        placeholder={
          selectBoxData?.runTypSeqNbr?.length === 0 ? '' : 'Select One'
        }
        model={AggregationFormData.value14}
        isSearchable={false}
        value={formData.runTypSeqNbr}
        isLoading={isDropDownLoading}
        // validators={{ required: AggregationFormData.value12 }}
        onChange={async () => {
          const response = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
          });
          const result = sortParameter(response);
          setSelectBoxData({
            ...selectBoxData,
            mlrCseSz: result,
            mlrSitusCd: [],
            lglEntyId: [],
          });
        }}
        options={selectBoxData.runTypSeqNbr}
        isDisabled={
          selectBoxData?.runTypSeqNbr?.length === 0 ||
          selectBoxData?.runTypSeqNbr?.length === 1
        }
        // value={
        //   selectBoxData.runTypSeqNbr.length === 1
        //     ? selectBoxData.runTypSeqNbr[0].value
        //     : ''
        // }
      />
      <SelectInput
        label={AggregationFormData.value3}
        placeholder={
          selectBoxData.mlrSitusCd.length === 0 &&
          form.control._formValues.runTypSeqNbr
            ? 'Select One'
            : ''
        } // Ensure placeholder is 'Select One' when runTypSeqNbr is selected
        model={AggregationFormData.value15}
        isSearchable={false}
        isLoading={isDropDownLoading}
        value={form.getValues(AggregationFormData.value15)}
        onChange={async () => {
          const response = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
            mlrCseSz: form.control._formValues.mlrCseSz,
          });
          const result = sortParameter(response);
          setSelectBoxData({
            ...selectBoxData,
            mlrSitusCd: result,
            lglEntyId: [],
          });
        }}
        options={selectBoxData.mlrCseSz}
        isDisabled={selectBoxData.mlrCseSz.length == 0}
      />
      <SelectInput
        label={AggregationFormData.value4}
        placeholder={selectBoxData.mlrSitusCd.length === 0 ? '' : 'Select One'}
        model={AggregationFormData.value16}
        isSearchable={false}
        isLoading={isDropDownLoading}
        value={formData.mlrSitusCd}
        onChange={async () => {
          const response = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
            mlrCseSz: form.control._formValues.mlrCseSz,
            mlrSitusCd: form.control._formValues.mlrSitusCd,
          });
          const result = sortParameter(response);
          setSelectBoxData({ ...selectBoxData, lglEntyId: result });
        }}
        options={selectBoxData.mlrSitusCd}
        isDisabled={selectBoxData.mlrSitusCd.length == 0}
      />
      <SelectInput
        label={AggregationFormData.value5}
        placeholder={selectBoxData.lglEntyId.length === 0 ? '' : 'Select One'}
        model={AggregationFormData.value17}
        isLoading={isDropDownLoading}
        isSearchable={false}
        value={formData.lglEntyId}
        options={selectBoxData.lglEntyId}
        isDisabled={selectBoxData.lglEntyId.length == 0}
      />
      <Button
        type="submit"
        isDisabled={isSearchDisabled}
        isLoading={isLoading}
        ariaLoadingLabel="Importing data"
        onClick={() => {
          return setLoading(!isLoading);
        }}
        style={{ marginTop: '15px', marginRight: '5px' }}
      >
        {AggregationFormData.value6}
      </Button>
      <Button
        onClick={() => {
          form.reset();
          localStorage.removeItem('aggregationFormData');
          setSelectBoxData({
            ...selectBoxData,
            runTypCd: [],
            runTypSeqNbr: [],
            mlrCseSz: [],
            mlrSitusCd: [],
            lglEntyId: [],
          });
        }}
        style={{ marginTop: '15px', marginLeft: '5px' }}
      >
        {AggregationFormData.value7}
      </Button>
    </FormProvider>
  );
};
