export const SubscriberCloneSearchIcon = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
        This screen allows the users to search the AOG/Subscriber for cloning based on search criteria's - Revenue No Subscriber or Clone by Policy
        </li>
        <li>
        Revenue No Subscriber is the default option. Revenue No Subscriber is the situation when we have AG's eligible for rebate but no subscribers to associate it with. Source System is Mandatory. However, the search icon for Policy Number is enabled only when the user enters values in all its preceding fields.
        </li>
<li>Clone by Policy option is selected when the subscriber records are partially cloned, thus the entire list of subscribers for a particular policy could be obtained. The search parameters are mandatory .When User clicks the "Search" button, system navigates to the Subscriber Clone>>Select Source AOG screen which lists all the possible SOURCE rebate eligible and non-rebate eligible AOG's with matched subscribers for the selected target AOG.
</li>
        <li>
        Irrespective of the clone option selected, The Run Type must be Final as subscribers can be selected for cloning only during final run type
        </li>
        <li>
        Subscribers can be selected for cloning only prior to commencement of rebate calculations.
        </li>
      </ul>
    </div>
  );
};

export const SubscriberRevenueNoSubscriber = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>This screen allows the users to view the list of Revenue No Subscribers for the selected criteria in the Subscriber Clone Search screen.
        </li>
        <li>Each AOG record in the result set has a "Select" button.
        </li>
        <li>It enables the User to Select' for a particular AOG record.
        </li>
        <li>On clicking the "Download to excel" button, system successfully exports Revenue No Subscriber data to excel format.
        </li>
      </ul>
    </div>
  );
};

export const SubscriberCloneSelectSource = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
       <li>This screen allows the users to view the list of Source AOG's for the selected Target AOG .</li>
       <li>The selected Target AOG is displayed on the screen along with the list of possible Source AOG's.</li>
       <li>The user could select the source by checking the Clone check box.</li>
       <li>On Clicking the "Save" button and the Target AOG along with the selected Source AOG's are inserted to a holding/staging table in the DB.</li>
       <li>On selecting the "Revenue No Subscriber" breadcrumb, the user is pointed to the last visited page on the "Revenue No Subscriber" screen.</li>
      </ul>
    </div>
  );
};