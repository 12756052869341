import { styled } from '@abyss/web/tools/styled';

export const HeaderDiv = styled('div', {
  padding: '20px',
  alignItems: 'center',
});

export const BodyContainer = styled('div', {
  background: '$pageBackgroundColor',
  alignItems: 'center',
});

export const BodyPageContainer = styled('div', {
  padding: '20px 10px',
  minHeight: '100vh',
});
