import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { SubHeader } from '@src/common/components';
import { HelpContent } from '@src/components/configuration/utils';
import React, { useEffect, useState } from 'react';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { Grid } from '@abyss/web/ui/Grid';
import { HelperContent } from '@src/components/configuration/helpContent';
import useAuth from '@src/auth/useAuth';

import { ConfigurationTable } from '../../components/configuration/ConfigurationTable';
import { ConfigurationForm } from '../../components/configuration/ConfigurationForm';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Hamburger } from '../../components/Hamburger';
import withAuth from '../../auth/withAuth';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '../../common/customStyle';
import {
  FormContainer,
  FormOuterContainer,
  mediumSize,
} from '../../common/utils/StyleConstant';
import env from '../../../env.json';

const Configuration = () => {
  const { userRolesGroups } = useAuth();
  const [showConfigForm, setShowConfigForm] = useState(true);
  const [searchData, setSearchData] = useState(false);
  const isMedium = useMediaQuery(mediumSize);
  const { navigate, getLocation } = useRouter();
  const { state, hash } = getLocation();
  const [configurationUserAccess, setConfigurationUserAccess] = useState('');
  const [userGroups, setUserGroups] = useState(userRolesGroups);
  const [permissions, setPermissions] = useState({});

  const configurationRoles = {
    Configuration_MenuLink_Read: env.Configuration_MenuLink_Read,
    Configuration_MenuLink_Write: env.Configuration_MenuLink_Write,
  };

  const hasReadAccessToConfigurationMenuLink = (userGroups) => {
    return userGroups.some((group) => {
      return configurationRoles.Configuration_MenuLink_Read.includes(group);
    });
  };

  const hasWriteAccessToConfigurationMenuLink = (userGroups) => {
    return userGroups.some((group) => {
      return configurationRoles.Configuration_MenuLink_Write.includes(group);
    });
  };

  useEffect(() => {
    const hasReadAccess = hasReadAccessToConfigurationMenuLink(userGroups);
    const hasWriteAccess = hasWriteAccessToConfigurationMenuLink(userGroups);
    setPermissions({ hasReadAccess, hasWriteAccess });
  }, [userGroups]);

  const [breadItem, setBreadItem] = useState([
    { title: 'Configuration Search', href: '/configuration' },
  ]);

  // const isTable = query.get('table');
  const isTable = hash === '#table';

  const handleSubmit = (data) => {
    navigate('#table');
    setBreadItem([
      { title: 'Configuration Search', href: '/configuration' },
      { title: 'Configuration Search Results', href: '/configuration#table' },
    ]);
    setSearchData(data);
    setShowConfigForm(false);
  };

  useEffect(() => {
    if (!isTable) {
      setShowConfigForm(true);
    } else {
      setShowConfigForm(false);
      // navigate('');
    }
  }, [isTable]);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            {showConfigForm ? (
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Configuration Results"
                  InfoContent={HelpContent}
                />
                <FormOuterContainer>
                  <FormContainer medium={isMedium}>
                    <ConfigurationForm handleSubmit={handleSubmit} />
                  </FormContainer>
                </FormOuterContainer>
              </BodyPageContainer>
            ) : (
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Configuration Results"
                  InfoContent={HelperContent}
                />
                <ConfigurationTable searchData={searchData} />
              </BodyPageContainer>
            )}
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default withAuth(Configuration);
