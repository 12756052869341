/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { MlreDataTable, MlreLoader } from '@src/common/MlreAbyssComponent';
import React, { useEffect, useState } from 'react';
import { Link } from '@abyss/web/ui/Link';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import useAuth from '@src/auth/useAuth';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Flex } from '@abyss/web/ui/Flex';
import { SubHeader } from '@src/common/components';
import { getHistoryColumns, notificationData } from './utils';
import { SubscriberNote } from './SubscriberNote';
import notes_available from '../../assets/notes_available.png';
import notes_empty from '../../assets/notes_empty.png';
import { BASE_URL } from '../utils/endpoints';

export const SubscriberViewDetail = ({
  showHistory,
  onCallBack,
  historyTableData = [],
  subNoteData = [],
  subDetails,
  subscriberData,
  isSubNotesDisabled,
  breadcrums,
  triggerAction,
  isSaveButtonDisable,
  isButtonDisabled,
  isEditDisabled,
  resetEdit = false,
}) => {
  const [componentType, setComponentType] = useState('');
  const [paymentTableData, setPaymentTableData] = useState([]);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [notificationHistoryTd, setNotificationHistoryTd] = useState([]);
  const [notificationAction, setNotificationAction] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notificationTableData, setNotificationTableData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [rbtid, setRbtId] = useState();
  const [loading, setLoading] = useState(true);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [loadingNotification, setLoadingNotification] = useState(true);
  const [refreshNote, setRefreshNote] = useState(false);
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);

  const [notesStatus, setNotesStatus] = useState('empty');

  // const [isLoading, setIsLoading] = useState(true);
  const { navigate } = useRouter();
  const { token } = useAuth();
  const { msid } = useAuth();

  const changeComponent = (type) => {
    setComponentType(type);
    window.scrollTo(0, 280);
  };
  const notificationForm = useForm({
    defaultValues: { notification_status: 'Returned' },
  });

  const getHistoryData = async () => {
    const { data, error } = await axios.get(
      `${BASE_URL}subscriber/notification/history/${id}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      }
    );
  };

  const getPaymentData = async () => {
    setPaymentLoader(true);
    const id = subDetails?.custContrSbscrId;
    if (id) {
      try {
        const { data } = await axios.post(
          `${BASE_URL}subscriber/payment/`,
          { id },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setPaymentTableData(data);
      } catch (error) {
        console.error('Error fetching payment data:', error);
      } finally {
        setPaymentLoader(false);
      }
    } else {
      setPaymentLoader(false);
    }
  };

  const getNotificationData = async () => {
    setLoadingNotification(true);
    const id = subDetails?.custContrSbscrId;
    if (id) {
      try {
        const { data, error } = await axios.get(
          `${BASE_URL}subscriber/notification/${id}/${subscriberData?.year}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (!error) {
          setNotificationTableData(data);
        }
      } catch (error) {
        console.log(error);
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      } finally {
        setLoadingNotification(false);
      }
    }
  };

  const getNotificationhistoryData = async () => {
    try {
      const id = subDetails?.custContrSbscrId;
      if (id) {
        const { data, error } = await axios.get(
          `${BASE_URL}subscriber/notification/history/${rbtid}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (!error) {
          setNotificationHistoryTd(data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchNotesDetails = async () => {
    const payload = {
      rbtYear: subscriberData?.year,
      custContrSbscrId: subscriberData?.custContrSbscrId,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}subscriber/sub/notes/getNotes`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data && response.data.length > 0) {
        setNotesData(response.data);

        setNotesStatus('available');
      } else {
        setNotesData([]);
        setNotesStatus('empty');
        console.log('data not found');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotesDetails();
  }, []);

  useEffect(() => {
    if (notificationAction === 'history') {
      getNotificationhistoryData();
    }
  }, [notificationAction]);

  useEffect(() => {
    if (componentType === 'note') {
      fetchNotesDetails();
    }
    if (componentType === 'notification') {
      getNotificationData();
    }
    if (componentType === 'payment') {
      getPaymentData();
    }
  }, [componentType]);

  useEffect(() => {
    if (refreshNote) {
      fetchNotesDetails();
      setRefreshNote(false);
    }
  }, [refreshNote]);

  useEffect(() => {
    if (showHistory) {
      changeComponent('history');
      onCallBack();
    }
  }, [showHistory]);

  const fetchData = async (payloadData) => {
    try {
      const { data, error } = await axios.post(
        `${BASE_URL}payment/search`,
        payloadData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const paymentRowData = data.filter((item) => {
        return item.pmtDocNbr === payloadData.pmtDocNbr;
      });

      navigate('/payment-detail', {
        state: {
          selectedPaymentData: paymentRowData?.[0],
          breadItem: [
            ...breadcrums,
            {
              title: 'Subscriber View',
              href: '/subscriber-view',
              state: { navigateData: subscriberData, breadcrums },
            },
          ],
        },
      });
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const handleCheckClick = (checkId) => {
    const payload = {
      pmtDocTypCd: 'check',
      pmtDocNbr: checkId,
    };

    fetchData(payload);
  };

  const getColumns = () => {
    return [
      {
        Header: 'Check #',
        accessor: 'payDocNbr',
        disableSortBy: true,
        Cell: ({ value, cellActions, row, ...props }) => {
          return (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleCheckClick(value);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Check Amount',
        accessor: 'totPayAmt',
        disableSortBy: true,
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Check Date',
        accessor: 'payDocDt',
        disableSortBy: true,
        Cell: ({ value }) => {
          if (value === null || value === '' || value === undefined) {
            return '';
          }
          const payData = value ? value.substring(0, value.indexOf('T')) : '';
          const modifiedDate = payData.replaceAll('-', '/');
          const splitDate = modifiedDate.split('/');
          const modifiedSplitDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
          return modifiedSplitDate;
        },
      },
    ];
  };

  const getNotificationColumns = () => {
    return [
      {
        Header: 'LE Code',
        accessor: 'lglEntyId',
        disableSortBy: true,
      },
      {
        Header: 'LE Name',
        accessor: 'lglEntyNm',
        disableSortBy: true,
      },
      {
        Header: 'Situs',
        accessor: 'mlrSitusStCd',
        disableSortBy: true,
      },
      {
        Header: 'INN Catgy',
        accessor: 'innSbscrCatgyCd',
        disableSortBy: true,
      },
      {
        Header: 'ONN Catgy',
        accessor: 'oonSbscrCatgyCd',
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'pstMrkSts',
        disableSortBy: true,
      },
      {
        Header: 'Post Mark Date',
        accessor: 'pstMrkDt',
        disableSortBy: true,
        Cell: ({ value, cellActions, row, ...props }) => {
          // const date = value ? value.substring(0, value.indexOf('T')) : '';

          return value;
        },
      },
      {
        Header: 'Edit/History',
        accessor: 'custRbtNtfyId',
        disableSortBy: true,
        Cell: ({ value, cellActions, row, ...props }) => {
          if (!row.original?.pstMrkSts) {
            return '';
          }
          return (
            <React.Fragment>
              {!isEditDisabled ? (
                <IconMaterial
                  css={{ cursor: 'pointer' }}
                  icon="edit"
                  onClick={() => {
                    setRbtId(row.original.custRbtNtfyId);
                    setNotificationAction('edit');
                  }}
                />
              ) : (
                ''
              )}

              <Tooltip content="History">
                <Button
                  rounded
                  size="$sm"
                  css={{ backgroundColor: 'none !important' }}
                >
                  <IconMaterial
                    css={{ cursor: 'pointer' }}
                    icon="history"
                    onClick={() => {
                      setRbtId(row.original.custRbtNtfyId);
                      setNotificationAction('history');
                    }}
                  />
                </Button>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    ];
  };

  const handleNotificationSave = async (inputData) => {
    setSaveIsButtonDisabled(true);
    const payload = {
      rbtRptYr: subscriberData?.year,
      loginID: msid,
      custRbtNtfyId: rbtid,
      pstMrkDt: inputData.mail_date,
      pstMrkSts: inputData.notification_status,
    };

    try {
      const { data, error } = await axios.post(
        `${BASE_URL}subscriber/notification/save`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!error) {
        alert(data);
        setSaveIsButtonDisabled(false);
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
      setSaveIsButtonDisabled(false);
    }
  };

  const getNotiHistoryColumns = () => {
    return [
      {
        Header: 'Notification Status',
        accessor: 'pstMrkSts',
        disableSortBy: true,
      },
      {
        Header: 'Post Mark Date',
        accessor: 'pstMrkDt',
        disableSortBy: true,
        Cell: ({ value, cellActions, row, ...props }) => {
          // const date = value ? value.substring(0, value.indexOf('T')) : '';
          return value;
        },
      },
    ];
  };

  const columns = React.useMemo(getColumns, []);
  const notificationColumns = React.useMemo(getNotificationColumns, [
    isEditDisabled,
  ]);
  const historyColumns = React.useMemo(getHistoryColumns, []);
  const notificationHistoryCol = React.useMemo(getNotiHistoryColumns, []);

  useEffect(() => {
    const subscription = notificationForm.watch((value, { name, type }) => {
      setIsFormChanged(true);
    });
    return () => {
      return subscription.unsubscribe();
    };
  }, [notificationForm]);

  const handleCancel = () => {
    const userConfirmed = window.confirm(
      'Are you sure you want to cancel without saving changes?.'
    );
    if (userConfirmed) {
      notificationForm.reset();
      setComponentType('');
      setNotificationAction('');
    }
  };

  useEffect(() => {
    if (resetEdit) {
      setIsUpdateDisabled(true);
    }
  }, [resetEdit]);

  return (
    <React.Fragment>
      <Grid>
        <Grid.Col span={12} css={{ textAlign: 'center' }}>
          {isUpdateDisabled ? (
            <Button
              style={{ margin: '10px' }}
              onClick={() => {
                setIsUpdateDisabled(false);
                triggerAction('update');
                setTimeout(() => {
                  triggerAction('');
                }, 500);
              }}
              isDisabled={isButtonDisabled}
            >
              Update Address
            </Button>
          ) : (
            <React.Fragment>
              <Button
                type="submit"
                isDisabled={isSaveButtonDisable}
                onClick={() => {
                  triggerAction('save');
                  setTimeout(() => {
                    triggerAction('');
                  }, 500);
                }}
              >
                Save
              </Button>
              <Button
                style={{ margin: '0px 10px' }}
                onClick={() => {
                  triggerAction('reset');
                  setTimeout(() => {
                    triggerAction('');
                  }, 500);
                }}
              >
                Reset
              </Button>
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  setIsUpdateDisabled(true);
                  triggerAction('cancel');
                  setTimeout(() => {
                    triggerAction('');
                  }, 500);
                }}
              >
                Cancel
              </Button>
            </React.Fragment>
          )}
          <Button
            onClick={() => {
              changeComponent('payment');
            }}
          >
            Payment Status
          </Button>
          <Button
            onClick={() => {
              changeComponent('notification');
            }}
            style={{ margin: '10px' }}
          >
            Notification Status
          </Button>
          <Button
            onClick={() => {
              changeComponent('note');
            }}
            style={{
              color: notesStatus !== 'available' ? '#15A796' : 'white',
              backgroundColor:
                notesStatus !== 'available' ? 'white' : '#15A796',
              border: notesStatus !== 'available' ? '1px solid #15A796' : '',

              transition: 'all 0.3s ease', // Optional for smooth transition
            }}
          >
            Notes
          </Button>
          {/* {notesStatus === 'available' ? (
            <img
              style={{ width: '25px', marginLeft: '10px' }}
              src={notes_available.src}
              alt="Notes Available"
            />
          ) : (
            <img
              style={{ width: '25px', marginLeft: '10px' }}
              src={notes_empty.src}
              alt="Notes Empty"
            />
          )} */}
        </Grid.Col>
        <Grid.Col span={12}>
          {componentType === 'payment' ? (
            <React.Fragment>
              {paymentLoader ? (
                <MlreLoader />
              ) : (
                <MlreDataTable data={paymentTableData} columns={columns} />
              )}
              <Grid.Col span={12} css={{ textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    setComponentType('');
                  }}
                >
                  Close
                </Button>
              </Grid.Col>
            </React.Fragment>
          ) : (
            ''
          )}

          {componentType === 'notification' ? (
            <React.Fragment>
              {loadingNotification ? (
                <MlreLoader />
              ) : (
                <MlreDataTable
                  data={notificationTableData}
                  columns={notificationColumns}
                  refreshColumn
                />
              )}
              {notificationAction === 'edit' ? (
                <React.Fragment>
                  <Flex css={{ justifyContent: 'center' }}>
                    <Grid style={{ width: '50%' }}>
                      <FormProvider
                        style={{ width: '100%' }}
                        state={notificationForm}
                        onSubmit={handleNotificationSave}
                      >
                        <SelectInput
                          label="Notification Status"
                          model="notification_status"
                          validators={{ required: true }}
                          options={[
                            { value: 'Returned', label: 'Returned' },
                            { value: 'Remailed', label: 'Remailed' },
                            { value: 'Forwarded', label: 'Forwarded' },
                          ]}
                        />
                        <DateInput
                          label="Mail Date"
                          model="mail_date"
                          placeholder=""
                          subText=""
                          validators={{ required: true }}
                        />
                        <Button
                          type="submit"
                          style={{ marginTop: '15px', marginRight: '5px' }}
                          isDisabled={isSaveButtonDisabled}
                        >
                          Submit
                        </Button>
                        <Button
                          onClick={() => {
                            notificationForm.reset();
                          }}
                          style={{ marginTop: '15px', marginLeft: '5px' }}
                        >
                          Reset
                        </Button>
                        <Button
                          onClick={handleCancel}
                          style={{ marginTop: '15px', marginLeft: '5px' }}
                        >
                          Cancel
                        </Button>
                      </FormProvider>
                    </Grid>
                  </Flex>
                  <br />
                </React.Fragment>
              ) : (
                ''
              )}
              {notificationAction === 'history' ? (
                <Flex style={{ margin: '10px 10%' }}>
                  {loading ? (
                    <MlreLoader />
                  ) : (
                    <MlreDataTable
                      data={notificationHistoryTd}
                      columns={notificationHistoryCol}
                    />
                  )}
                </Flex>
              ) : (
                ''
              )}
              <Grid.Col span={12} css={{ textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    setComponentType('');
                    setNotificationAction('');
                  }}
                >
                  Close
                </Button>
              </Grid.Col>
            </React.Fragment>
          ) : (
            ''
          )}

          {componentType === 'note' ? (
            <React.Fragment>
              <SubscriberNote
                subNoteData={notesData}
                subDetails={subDetails}
                subscriberData={subscriberData}
                handleChangeClose={() => {
                  return setComponentType('');
                }}
                handleNoteSave={() => {
                  setRefreshNote(true);
                }}
              />
              <br />
              {/* <Button
                onClick={() => {
                  setComponentType('');
                }}
              >
                Close
              </Button> */}
            </React.Fragment>
          ) : (
            ''
          )}

          {componentType === 'history' ? (
            <React.Fragment>
              <MlreDataTable data={historyTableData} columns={historyColumns} />
              <Grid.Col span={12} css={{ textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    setComponentType('');
                  }}
                >
                  Close
                </Button>
              </Grid.Col>
            </React.Fragment>
          ) : (
            ''
          )}
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
