interface ConfigurationForm {
  rebateReportYear: string;
  parameterType: string;
  parameterCategory: string;
  parameterName: string;
  parameterValue: string;
  attribute: string;
  attribute1: string;
  attribute2: string;
  attribute3: string;
  attribute4: string;
  attribute5: string;
  attribute6: string;
  attribute7: string;
  attribute8: string;
  attribute9: string;
  attribute10: string;
  attribute11: string;
  attribute12: string;
  attribute13: string;
  attribute14: string;
  attribute15: string;
  attribute16: string;
  attribute17: string;
  attribute18: string;
  attribute19: string;
  attribute20: string;
}
export const ConfigurationForm: ConfigurationForm = {
  rebateReportYear: 'Rebate Report Year',
  parameterType: 'Parameter Type',
  parameterCategory: 'Parameter Category',
  parameterName: 'Parameter Name',
  parameterValue: 'Parameter Value',
  attribute: 'Start Date',
  attribute1: 'Attribute1',
  attribute2: 'Attribute2',
  attribute3: 'Attribute3',
  attribute4: 'Attribute4',
  attribute5: 'Attribute5',
  attribute6: 'Attribute6',
  attribute7: 'Attribute7',
  attribute8: 'Attribute8',
  attribute9: 'Attribute9',
  attribute10: 'Attribute10',
  attribute11: 'Attribute11',
  attribute12: 'Attribute12',
  attribute13: 'Attribute13',
  attribute14: 'Attribute14',
  attribute15: 'Attribute15',
  attribute16: 'Attribute16',
  attribute17: 'Attribute17',
  attribute18: 'Attribute18',
  attribute19: 'Attribute19',
  attribute20: 'Attribute20',
};

interface ConfigurationFormData {
  value: string;
  value1: string;
  value2: string;
  search: string;
  reset: string;
  save: string;
  details: string;
  value5: string;
  value6: string;
  value7: string;
  value8: string;
  value9: string;
}
export const ConfigurationFormData: ConfigurationFormData = {
  value: 'Rebate Report Year',
  value1: 'Parameter Type',
  value2: 'Parameter Category',
  search: 'Search',
  reset: 'Reset',
  save: 'Save',
  details: 'Detail',
  value5: 'Parameter Name',
  value6: 'Cancel',
  value7: 'rebate_report_year',
  value8: 'parameter_type',
  value9: 'parameter_category',
};
