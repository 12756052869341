import React, { useState, useEffect } from 'react';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { Layout } from '@abyss/web/ui/Layout';
import axios from 'axios';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { BASE_URL } from '../utils/endpoints';
import { ConfigurationFormData } from './Constants';

export const ConfigurationForm = ({ handleSubmit }) => {
  const form = useForm();
  const [year, setYear] = useState([]);
  const [typeCode, setTypeCode] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedTypeCode, setSelectedTypeCode] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDropDownLoading, setIsDropDownLoading] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleDropDownData = async () => {
    try {
      await axios
        .get(`${BASE_URL}configuration/config`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          const uniqueValues = {
            year: new Set(),
            typeCode: new Set(),
          };
          res.data.forEach((item) => {
            uniqueValues.year.add(item.rbtRptYr);
            uniqueValues.typeCode.add(item.parameterType);
          });

          const yearArray = Array.from(uniqueValues.year);
          const typeCodeArray = Array.from(uniqueValues.typeCode);
          setYear(yearArray);
          setTypeCode(typeCodeArray);

          const savedYear = localStorage.getItem('selectedYear');
          if (!savedYear && yearArray.length > 0) {
            setSelectedYear(yearArray[0]);
            storeSelections('selectedYear', yearArray[0]);
          }

          const savedTypeCode = localStorage.getItem('selectedTypeCode');
          if (!savedTypeCode && typeCodeArray.length > 0) {
            setSelectedTypeCode(typeCodeArray[0]);
            storeSelections('selectedTypeCode', typeCodeArray[0]);
          }

          setIsDropDownLoading(false);
          // setSelectedYear(yearArray[0]);
          // setSelectedTypeCode(typeCodeArray[0]);
        });
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const fetchCategoryData = async (year, typeCode) => {
    // setIsDropDownLoading(true);
    const configCategory = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URL}configuration/${year}/${typeCode}`,
        configCategory
      );
      const categoryData = response.data.response.map((item) => {
        return { value: item, label: item };
      });

      setCategory(categoryData);
      const savedCategory = localStorage.getItem('selectedCategory');

      if (
        savedCategory &&
        categoryData.some((cat) => {
          return cat.value === savedCategory;
        })
      ) {
        setSelectedCategory(savedCategory);
      } else if (categoryData.length > 0) {
        setSelectedCategory(categoryData[0].value);
        storeSelections('selectedCategory', categoryData[0].value);
      }
      // setSelectedCategory(
      //   categoryData.length > 0 ? categoryData[0].value : null
      // );

      // if (categoryData.length > 0) {
      //   setSelectedCategory(categoryData[0].value);
      // } else {
      //   setSelectedCategory(null);
      // }
    } catch (error) {
      console.log(error);
      // alert(
      //   'An error occurred while processing your request. Please try again later.'
      // );
      setCategory([]);
      setSelectedCategory(null);
    } finally {
      setIsDropDownLoading(false);
    }
  };

  const storeSelections = (key, value) => {
    localStorage.setItem(key, value);
  };

  useEffect(() => {
    handleDropDownData();

    const savedYear = localStorage.getItem('selectedYear');
    const savedTypeCode = localStorage.getItem('selectedTypeCode');
    const savedCategory = localStorage.getItem('selectedCategory');

    if (savedYear) setSelectedYear(savedYear);
    if (savedTypeCode) setSelectedTypeCode(savedTypeCode);
    if (savedYear && savedTypeCode) fetchCategoryData(savedYear, savedTypeCode);
    if (savedCategory) setSelectedCategory(savedCategory);
  }, []);

  useEffect(() => {
    if (selectedYear && selectedTypeCode) {
      fetchCategoryData(selectedYear, selectedTypeCode);
    }
  }, [selectedYear, selectedTypeCode]);

  const handleYearChange = (e) => {
    const newYear = e;

    setSelectedYear(newYear);
    storeSelections('selectedYear', newYear);
    setCategory([]);
    // setSelectedCategory(null);

    if (selectedTypeCode) {
      fetchCategoryData(newYear, selectedTypeCode);
    }
  };

  const handleTypeCodeChange = (e) => {
    const newType = e;
    setSelectedTypeCode(newType);
    storeSelections('selectedTypeCode', newType);
    setCategory([]);
    setSelectedCategory(null);

    if (selectedYear) {
      fetchCategoryData(selectedYear, newType);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryValue = e;
    setSelectedCategory(selectedCategoryValue);
    storeSelections('selectedCategory', selectedCategoryValue);
  };

  const onSubmit = () => {
    console.log('form values', form.getValues());
    setIsSearchDisabled(true);
    const searchData = {
      rebateReportYear: selectedYear || year[0],
      parameterType: selectedTypeCode || typeCode[0],
      parameterCategory:
        selectedCategory || (category.length > 0 ? category[0].value : null),
    };
    handleSubmit(searchData);
    setIsSearchDisabled(false);
  };

  const resetForm = () => {
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('selectedTypeCode');
    localStorage.removeItem('selectedCategory');

    if (year.length > 0) {
      setSelectedYear(year[0]);
    }
    if (typeCode.length > 0) {
      setSelectedTypeCode(typeCode[0]);
    }

    setCategory([]);
    setSelectedCategory(null);

    if (year.length > 0 && typeCode.length > 0) {
      fetchCategoryData(year[0], typeCode[0]);
    }

    form.reset();
  };

  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <SelectInput
        label={ConfigurationFormData.value}
        placeholder={selectedYear}
        isLoading={isDropDownLoading}
        model={ConfigurationFormData.value7}
        value={
          selectedYear ? { value: selectedYear, label: selectedYear } : null
        }
        options={year.map((item: any) => {
          return {
            value: item,
            label: item,
          };
        })}
        onChange={handleYearChange}
      />

      <SelectInput
        label={ConfigurationFormData.value1}
        placeholder={selectedTypeCode}
        isLoading={isDropDownLoading}
        model={ConfigurationFormData.value8}
        value={
          selectedTypeCode
            ? { value: selectedTypeCode, label: selectedTypeCode }
            : null
        }
        options={typeCode.map((item: any) => {
          return {
            value: item,
            label: item,
          };
        })}
        onChange={handleTypeCodeChange}
      />

      <SelectInput
        label={ConfigurationFormData.value2}
        placeholder={category.length > 0 ? category[0].label : ''}
        model={ConfigurationFormData.value9}
        isLoading={isDropDownLoading}
        options={category}
        value={selectedCategory}
        onChange={handleCategoryChange}
      />
      <Layout.Group alignLayout="center" css={{ paddingTop: '32px' }}>
        <Button
          type="submit"
          css={{
            borderRadius: '4px',
          }}
          isDisabled={isSearchDisabled}
          isLoading={isLoading}
          ariaLoadingLabel="Importing data"
          onClick={() => {
            return setLoading(!isLoading);
          }}
        >
          {ConfigurationFormData.search}
        </Button>

        <Button
          onClick={() => {
            return resetForm();
          }}
          css={{ borderRadius: '4px' }}
        >
          {ConfigurationFormData.reset}
        </Button>
      </Layout.Group>
    </FormProvider>
  );
};
