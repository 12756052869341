export const PAYMENT_MODE: string = 'Payment Mode';
export const CHECK_NUMBER: string = 'Check Number';
export const POLICY_NUMBER: number = 112346;
export const POLICY_NUM: string = 'Policy Number';
export const LEGAL_ENTITY: string = 'Legal Entity';
export const MLR_CASE_SIZE: string = 'MLR Case Size';
export const SITUS_STATE: string = 'Situs State';
export const CHECK_DATE: string = 'Check Date';
export const CHECK_AMOUNT: string = 'Check Amount';
export const DISTRIBUTION_STATUS: string = 'Distribution Status';
export const REMAILED_DATE: string = 'Remailed Date';
export const ADDRESS: string = 'Address';
export const PAYMENT_STATUS: string = 'Payment Status';
export const POST_MARK_DATE: string = 'Post Mark Date';
export const VOID: string = 'Void';
export const REDISTRIBUTE: string = 'Redistribute';
export const REISSUE: string = 'Reissue';
export const CLOSE: string = 'Close';
export const SAVE: string = 'Save';
export const RESET: string = 'Reset';
export const CANCEL: string = 'Cancel';
export const POLICY_TYPE: string = 'Policy Type';
