import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { Link } from '@abyss/web/ui/Link';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { SubHeader } from '@src/common/components';
import { EmpolyerConversionCheckTable } from './EmployerConversionCheckTable';
import { HelpContentPaymentRedistribute } from '../payments/utils';

const CheckRedistribeApprove = () => {
  const { getLocation } = useRouter();
  const { state } = getLocation();
  const { breadcrums } = state;

  const breadItems = breadcrums
    ? [
        ...breadcrums,
        {
          title: 'Approve',
          href: '/check-redistribe-approve/',
        },
      ]
    : [];

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItems}
                infoTitle="Check Redistribute Submission"
                InfoContent={HelpContentPaymentRedistribute}
              />
              <Layout.Stack
                css={{
                  alignContent: 'center',
                }}
              >
                <EmpolyerConversionCheckTable
                  data={state?.data}
                  breadcrums={breadcrums}
                />
              </Layout.Stack>
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(CheckRedistribeApprove);
