/* eslint-disable react/react-in-jsx-scope */
import { Grid } from '@abyss/web/ui/Grid';
import React, { useEffect, useState } from 'react';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import { MlreDataTable, MlreLoader } from '@src/common/MlreAbyssComponent';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '../utils/endpoints';
import { getHistoryColumns } from './utils';

export const CustomerPolicyListHistory = ({ data, onClose }) => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noHistoryFound, setNoHistoryFound] = useState(false);
  const { navigate } = useRouter();

  console.log('hostory data', data);

  const checkIfAllNull = (resonseData) => {
    return resonseData.every((item) => {
      return Object.values(item).every((val) => {
        return val === null;
      });
    });
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Customer Name',
        accessor: 'custNm',
        disableSortBy: true,
      },
      {
        Header: 'Address',
        accessor: 'address',
        disableSortBy: true,
      },
      {
        Header: 'City',
        accessor: 'custCityNm',
        disableSortBy: true,
      },
      {
        Header: 'State',
        accessor: 'custStCd',
        disableSortBy: true,
      },
      {
        Header: 'Zip Code',
        accessor: 'zip',
        disableSortBy: true,
      },
      {
        Header: 'Modified By',
        accessor: 'modById',
        disableSortBy: true,
      },
      {
        Header: 'Modified Date',
        accessor: (row) => {
          const date = new Date(row.modByDtm);
          return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
            .getDate()
            .toString()
            .padStart(2, '0')}/${date.getFullYear()}`;
        },
        disableSortBy: true,
      },
    ];
  }, []);

  useEffect(() => {
    if (data) {
      const fetchData = async () => {
        try {
          axios
            .post(
              `${BASE_URL}customer/address-customer-list`,
              {
                custId: data[0]?.custId,
              },
              {
                headers: {
                  authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then((response) => {
              setHistoryData(response.data);
              setLoading(false);
            });
        } catch (error) {
          setHistoryData([]);
        }
      };
      fetchData();
    }
  }, [data]);

  return (
    <Grid css={{ marginTop: '30px' }}>
      {loading ? (
        <MlreLoader />
      ) : (
        <React.Fragment>
          <MlreDataTable data={historyData} columns={columns} />
          <Grid.Col span={12} css={{ textAlign: 'center', marginTop: '10px' }}>
            <Button onClick={onClose}>Close</Button>
          </Grid.Col>
        </React.Fragment>
      )}
    </Grid>
  );
};
