/* eslint-disable react/react-in-jsx-scope */
import { Grid } from '@abyss/web/ui/Grid';
import React, { useEffect, useState } from 'react';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import { MlreDataTable, MlreLoader } from '@src/common/MlreAbyssComponent';
import { BASE_URL } from '../utils/endpoints';

export const CustomerBroker = ({ data, onClose }) => {
  const [brokerData, setBrokerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alerted, setAlerted] = useState(false);

  const checkIfAllNull = (resonseData) => {
    return resonseData.every((item) => {
      return Object.values(item).every((val) => {
        return val === null;
      });
    });
  };

  const BrokerColumns = React.useMemo(() => {
    return [
      {
        Header: 'Broker ID/Agency ID',
        accessor: 'srcSysBrkrId',
        disableSortBy: true,
      },
      {
        Header: 'Broker Name',
        accessor: 'brkrNm',
        disableSortBy: true,
        Cell: ({ row }) => {
          const firstName =
            row.original.brkrfstNm && row.original.brkrfstNm !== 'null'
              ? row.original.brkrfstNm
              : '';
          const lastName =
            row.original.brkrlstNm && row.original.brkrlstNm !== 'null'
              ? row.original.brkrlstNm
              : '';
          return `${firstName} ${lastName}`;
        },
      },
      {
        Header: 'Agency Name',
        accessor: 'brkrAgencyNm',
        disableSortBy: true,
      },
    ];
  }, []);

  useEffect(() => {
    if (data) {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            `${BASE_URL}customer/getBrkrByPolicy`,
            {
              custContrId: data[0]?.custContrId,
            },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );

          if (Array.isArray(response.data) && response.data.length > 0) {
            if (checkIfAllNull(response.data)) {
              setBrokerData([]);
              alert('No broker found.');
            } else {
              setBrokerData(response.data);
              // setAlerted(false);
            }
          } else {
            setBrokerData([]);
            alert('No broker found.');
          }
        } catch (error) {
          setBrokerData([]);
          // setAlerted(true);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [data]);

  return (
    <Grid css={{ marginTop: '30px' }}>
      {loading ? (
        <MlreLoader />
      ) : brokerData.length > 0 ? (
        <React.Fragment>
          <MlreDataTable data={brokerData} columns={BrokerColumns} />
          <Grid.Col span={12} css={{ textAlign: 'center', marginTop: '10px' }}>
            <Button onClick={onClose}>Close</Button>
          </Grid.Col>
        </React.Fragment>
      ) : null}
    </Grid>
  );
};
