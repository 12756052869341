import React, { useState } from 'react';
import { Breadcrumbs } from '@abyss/web/ui/Breadcrumbs';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { styled } from '@abyss/web/tools/styled';
import { Modal } from '@abyss/web/ui/Modal';
import { Tooltip } from '@abyss/web/ui/Tooltip';

const HelpInfoModal = ({ infoTitle, InfoContent, openInfoModal, onClose }) => {
  return (
    <Modal
      title={infoTitle}
      isOpen={openInfoModal}
      onClose={onClose}
      titleAlign="center"
    >
      <Modal.Section>
        <InfoContent />
      </Modal.Section>
    </Modal>
  );
};

export const SubHeader = (props) => {
  const { breadItem } = props;
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const SubHeaderContainer = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  });
  return (
    <React.Fragment>
      <SubHeaderContainer>
        <Breadcrumbs size="$md" items={breadItem} />
        <Tooltip content="Help">
          <Button
            rounded
            size="$sm"
            onClick={() => {
              setOpenInfoModal(true);
            }}
          >
            <IconMaterial icon="question_mark" size="18px" />
          </Button>
        </Tooltip>
      </SubHeaderContainer>

      <HelpInfoModal
        {...props}
        openInfoModal={openInfoModal}
        onClose={() => {
          setOpenInfoModal(false);
        }}
      />
    </React.Fragment>
  );
};
