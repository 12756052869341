import React from 'react';

export const HelpContent = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          MLRE maintains a set of configuration parameters that drive annual
          processing. Those parameters must be reviewed, approved and configured
          prior to the start of a new [RRY].
        </li>
        <li>
          Configuration Parameters are basically same as look up data and its
          look up value or values are consumed by the MLR Rebate Engine
          processes. These parameters value can be changed by the administrator.
          Each parameter will have fields such as Parameter Category, Parameter
          Name Parameter Value & some attributes.
        </li>
        <li>
          There are two types of Parameters: <br /> 1. LOOKUP: A group or a list
          of keys and its respective values for a single Parameter Category.{' '}
          <br /> 2. CONFIG: Individual key with respective values
        </li>
        <li>
          Parameter Category is the unique name of the Parameter that is used by
          the Application. By using this Screen user can search the
          configuration parameter.
        </li>
      </ul>
    </div>
  );
};
