import React, { useState, useEffect } from 'react';
import { CheckReissueApprovalForm } from '@src/components/checkReissueApproval/CheckReissueApprovalForm';
import { SubHeader } from '@src/common/components';
import { useRouter } from '@abyss/web/hooks/useRouter';
import {
  FormContainer,
  FormOuterContainer,
  mediumSize,
} from '@src/common/utils/StyleConstant';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';

import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { Link } from '@abyss/web/ui/Link';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';

import { CheckingReissueIcon } from '@src/components/checkReissueApproval/CheckReissueHelpIcon';


const CheckReissueApproval = () => {
  const { getLocation } = useRouter();
  const location = getLocation();
  const isMedium = useMediaQuery(mediumSize);

  const [breadItem, setBreadItem] = useState([
    { title: 'Check Reissue Approval Search', href: '/check-reissue-approval' },
    { title: 'Check Reissue Approval Search', href: '/check-reissue' },
  ]);



  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItem}
                infoTitle="Check Reissue Search"
                InfoContent={CheckingReissueIcon}
              />
              <FormOuterContainer>
                <FormContainer medium={isMedium}>
                  <CheckReissueApprovalForm />
                </FormContainer>
              </FormOuterContainer>
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(CheckReissueApproval);
