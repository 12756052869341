import React, { useState } from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { Flex } from '@abyss/web/ui/Flex';
import { useRouter } from '@abyss/web/hooks/useRouter';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import {
  BUSINESS_UNIT,
  ENTITY_ID,
  ENTITY_NAME,
  ADD_ENTITY_CONFIRMATION,
  ENTITY_VALIDATION,
  SAVE,
  RESET,
  CANCEL,
  ID_VALIDATION,
  UNIT_VALIDATION,
  CANCEL_UPDATE,
} from './LegalEntityConstants';
import styles from '../../styles/styles.module.css';
import { BASE_URL } from '../utils/endpoints';

export const AddLegalEntity = ({ onCancelActivity }) => {
  const { msid } = useAuth();
  const [data, setData] = useState('');
  const [legalEntityId, setLegalEntityId] = useState('');
  const [legalEntityName, setLegalEntityName] = useState('');
  const [businessUnit, setBusinessUnit] = useState('');
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);

  const url = `${BASE_URL}legalEntity/create-legal-entity`;
  const { navigate } = useRouter();
  const form = useForm();

  const addingEntity = async () => {
    setSaveIsButtonDisabled(true);
    if (legalEntityId && businessUnit !== '') {
      try {
        await axios
          .post(
            `${BASE_URL}legalEntity/create-legal-entity`,
            {
              LglEntyNm: legalEntityName,
              LglEntyId: legalEntityId,
              payeeTypeCd: '',
              createdById: '',
              modifiedById: msid,
              loginId: '',
              Atr1: businessUnit,
            },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          )
          .then((response) => {
            setData(response.data);
            alert(ADD_ENTITY_CONFIRMATION);
            setSaveIsButtonDisabled(false);
          });
      } catch (error) {
        console.log(error);
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
        setSaveIsButtonDisabled(false);
      }
    } else {
      alert(ENTITY_VALIDATION);
      setSaveIsButtonDisabled(false);
    }
  };
  const onSubmit = () => {};
  const cancelAlert = () => {
    const userConfirmed = window.confirm(CANCEL_UPDATE);
    if (userConfirmed) {
      navigate('/legal-entity');
    }
  };

  const cancelUpdate = () => {
    if (legalEntityId || legalEntityName || businessUnit !== '') {
      cancelAlert();
    } else {
      navigate('/legal-entity');
    }
  };

  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <TextInput
        label={ENTITY_ID}
        model={ENTITY_ID}
        isClearable
        validators={{ required: ID_VALIDATION }}
        onChange={(e) => {
          return setLegalEntityId(e.target.value);
        }}
        maxLength={10}
      />
      <TextInput
        label={ENTITY_NAME}
        model={ENTITY_NAME}
        onChange={(e) => {
          return setLegalEntityName(e.target.value);
        }}
        maxLength={240}
      />
      <TextInput
        label={BUSINESS_UNIT}
        model={BUSINESS_UNIT}
        // isClearable
        validators={{ required: UNIT_VALIDATION }}
        onChange={(e) => {
          return setBusinessUnit(e.target.value);
        }}
        maxLength={5}
      />
      <Flex direction="row" justify="center" className={styles.Layout}>
        <Button
          type="submit"
          className={styles.Button}
          onClick={addingEntity}
          isDisabled={isSaveButtonDisabled}
        >
          {SAVE}
        </Button>

        <Button
          onClick={() => {
            form.reset();
            setLegalEntityId('');
            setLegalEntityName('');
            setBusinessUnit('');
          }}
          className={styles.Button}
        >
          {RESET}
        </Button>
        <Button
          className={styles.Button}
          onClick={
            // onCancelActivity;
            cancelUpdate
          }
        >
          {CANCEL}
        </Button>
      </Flex>
    </FormProvider>
  );
};

// export default withAuth(AddLegalEntity);
