import React, { useEffect, useState } from 'react';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { Layout } from '@abyss/web/ui/Layout';
import { TextInput } from '@abyss/web/ui/TextInput';
import axios from 'axios';
import { CHECK_NUMBER, PAYMENT_MODE } from './Constant';
import { BASE_URL } from '../utils/endpoints';

export const PaymentSearch = ({ onPaymentSearch }) => {
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [checkType, setCheckType] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const form = useForm();

  const handleSubmit = async (data) => {
    const formData = {
      paymentMode: data.paymentMode,
      checkNumber: data.checkNumber,
    };
    localStorage.setItem('paymentFormData', JSON.stringify(formData));
    const payload = {
      pmtDocTypCd: data.paymentMode,
      pmtDocNbr: data.checkNumber,
    };
    setIsSearchDisabled(true);

    try {
      const result = await axios.post(`${BASE_URL}payment/search`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (result.data.length === 0) {
        alert('Check Number Not Found');
        setIsSearchDisabled(false);
        setLoading(false);
      } else {
        onPaymentSearch(result.data, true);
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (!form.getValues('checkNumber')) {
      setLoading(false);
    }
  }, [form.formState]);

  const fetchCheckData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}payment/check`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response?.data) {
        setCheckType([{ value: response?.data, label: response?.data }]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCheckData();
  }, []);

  useEffect(() => {
    const savedFormData = localStorage.getItem('paymentFormData');

    setLoading(false);
    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      form.setValue('paymentMode', parsedFormData.paymentMode);
      form.setValue('checkNumber', parsedFormData.checkNumber);
    }
  }, []); // Empty dependency array to run only once on mount

  return (
    <FormProvider state={form} onSubmit={handleSubmit}>
      <SelectInput
        label={PAYMENT_MODE}
        placeholder="Select One"
        model="paymentMode"
        value={form.getValues('paymentMode')}
        onChange={(value) => {
          return form.setValue('paymentMode', value);
        }}
        options={checkType.map((item) => {
          return {
            value: item.value,
            label: item.label,
          };
        })}
      />
      <TextInput
        label={CHECK_NUMBER}
        model="checkNumber"
        value={form.getValues('checkNumber')}
        onChange={(e) => {
          return form.setValue('checkNumber', e.target.value);
        }}
        validators={{ required: 'Enter Check Number' }}
      />
      <Layout.Group alignLayout="center" css={{ paddingTop: '32px' }}>
        <Button
          type="submit"
          css={{
            borderRadius: '4px',
          }}
          isDisabled={isSearchDisabled}
          isLoading={isLoading}
          ariaLoadingLabel="Importing data"
          onClick={() => {
            return setLoading(!isLoading);
          }}
        >
          Search
        </Button>

        <Button
          onClick={() => {
            form.reset();
            setLoading(false);
            localStorage.removeItem('paymentFormData');
          }}
          css={{ borderRadius: '4px' }}
        >
          Reset
        </Button>
      </Layout.Group>
    </FormProvider>
  );
};
