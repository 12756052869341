export const ENTITY_ID: string = 'Legal Entity ID';
export const ENTITY_NAME: string = 'Legal Entity Name';
export const BUSINESS_UNIT: string = 'G/L Business Unit';
export const ADD_ENTITY_CONFIRMATION: string =
  'Add Legal Entity Saved Successfully';
export const ADD_ENTITY: string = 'Add Legal Entity';
export const BASE_URL: string =
  'https://mlre-nonprod-api-fdn-c5f9dmaba4dzewc9.a03.azurefd.net';
export const FEATURE: string = '/legalEntity';
export const END_POINT: string = '/get-legal-entity-items';
export const EDIT_ENTITY_CONFIRMATION: string =
  'Edit Legal Entity Saved Successfully';
export const ENTITY_VALIDATION = 'Please fill all the required fields';
export const SAVE: String = 'Save';
export const RESET: String = 'Reset';
export const CANCEL: String = 'Cancel';
export const ID_VALIDATION: String = 'Legal entity ID is required.';
export const UNIT_VALIDATION: String = 'G/L business unit is required.';
export const LEGAL_ENTITY: String = 'Legal Entity';
export const UPDATE_LGLENT_PATH = '/update-legal-entity';
export const CANCEL_UPDATE =
  'Are you sure you want to cancel without saving changes?';
export const NOCHANGE_VALIDATION = 'No legal entity data changed.';
