import React from 'react';
import { Text } from '@abyss/web/ui/Text';

export const HelpContent = () => {
  return (
    <React.Fragment>
      <Text size="$lg">
        An Aggregation Group (AG) is defined by the MLR regulations.It is the
        combination of Legal Entity, Situs State and MLR Case Size. The AG is
        the level at which premiums and medical losses are pooled, in order to
        calculate a Medical Loss Ratio.
      </Text>
      <br />
      <br />
      <Text size="$lg">
        This screen allows the user to find out the list of Aggregation Groups
        based on search parameter such as Rebate Report Year, Run Type, Run
        Sequence, Legal Entity, Situs State and MLR Case Size.
      </Text>
    </React.Fragment>
  );
};

export const HelpContentResult = () => {
  return (
    <React.Fragment>
      <Text size="$lg">
        This screen displays the list of Aggregation Groups based on search
        parameter.
      </Text>
      <br />
      <br />
      <Text size="$lg">
        This screen allows the user to hold or approve payment for an AG
      </Text>
    </React.Fragment>
  );
};
