import { styled } from '@abyss/web/tools/styled';

export const mediumSize = '(max-width: 744px)';
export const FormOuterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

export const FormContainer = styled('div', {
  width: '40%',
  textAlign: 'center',
  variants: {
    medium: {
      true: {
        width: '90%',
      },
    },
  },
});
export const WrapperDiv = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
});

export const PaymentOuterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

export const PaymentContainer = styled('div', {
  width: '90%',
  textAlign: 'center',

  variants: {
    medium: {
      true: {
        width: '90%',
      },
    },
  },
});

export const PaymentMainContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});
