import React, { useState, useEffect } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { Link } from '@abyss/web/ui/Link';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import { useIsAuthenticated } from '@azure/msal-react';
import withAuth from '@src/auth/withAuth';
import { BASE_URL } from '@src/components/utils/endpoints';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import env from '../../env.json';

const Home = () => {
  const [homeURL, setHomeURL] = useState([]);

  const isAuthenticated = useIsAuthenticated();

  const url = env.Home_Url[0];

  const fetchHomeUrl = async () => {
    const payload = {
      attributeCategory: url,
    };

    try {
      const response = await axios.post(`${BASE_URL}home/url`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });
      setHomeURL(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchHomeUrl();
  }, []);

  const sendUserDetails = async () => {
    const applicationName = "MLRE - Medical Loss Ratio Rebate Engine"
    try {
      const localToken = localStorage.getItem('jwttoken');

      const decodedToken = jwtDecode(localToken);

      const fullName = decodedToken?.name;
      const [lastName, firstName] = fullName.split(',').map((part) => {
        return part.trim();
      });

      const payload = {
        applicationNm: applicationName,
        lstNm: lastName,
        fstNm: firstName,
      };

      await axios.post(
        `${BASE_URL}home/userdetails`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  useEffect(() => {
    const userDetails = sessionStorage.getItem('hasUserDetailsSent');

    if (isAuthenticated && !userDetails) {
      sendUserDetails();
      sessionStorage.setItem('hasUserDetailsSent', 'true');
    }
  }, [isAuthenticated]);

  const handleRedirect = (url) => {
    try {
      const validUrl = new URL(url);

      if (validUrl) {
        window.open(url, '_blank');
      }
    } catch {
      const urlRegex =
        /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9]+\.[a-z]{2,6})(?=\/|\?|$)/i;
      if (urlRegex.test(url)) {
        window.open(`//${url}`, '_blank');
      } else {
        alert('Invalid URL: Please correct the URL in configuration.');
      }
    }
  };
  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <Grid css={{ paddingTop: '150px', paddingLeft: '8px' }}>
                <Link
                  href="#"
                  onClick={() => {
                    return handleRedirect(homeURL[0]?.paramVal);
                  }}
                  css={{
                    textDecoration: 'underline',
                  }}
                  hideIcon
                >
                  {homeURL[0]?.atr20}
                </Link>
              </Grid>
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default withAuth(Home);
