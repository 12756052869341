import React, { useEffect, useRef } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';

export const MlreDataTable = ({
  data = 'no_data',
  columns,
  config = {},
  page = null,
  refreshColumn = false,
  reloadData = false,
}) => {
  const hasRun = useRef(false);
  const dataTableProps = useDataTable({
    showColumnSort: true,
    showTopPagination: false,
    initialColumns: columns,
    ...config,
  });

  // useEffect(() => {
  //   if (page !== null && !hasRun.current) {
  //     dataTableProps.pagination.gotoPage(page);
  //     setTimeout(() => {
  //       hasRun.current = true;
  //     }, 1000);
  //   }
  // }, [dataTableProps]);

  useEffect(() => {
    if (data !== 'no_data' && data?.length > 0) {
      dataTableProps.setData(data || [], true);
    }
  }, [data]);

  useEffect(() => {
    if (refreshColumn && columns) {
      dataTableProps.setColumns(columns || []);
    }
  }, [columns]);

  useEffect(() => {
    if (reloadData) {
      dataTableProps.reloadTableData();
    }
  }, [reloadData]);

  return (
    <DataTable
      css={{
        justifyContent: 'center',
        'abyss-table-styled-header': {
          height: 'auto',
        },
        'abyss-table-header-data-container': {
          justifyContent: 'center',
        },
        'abyss-data-table-pagination-bottom-root': {
          background: ' $pageBackgroundColor',
        },
        'abyss-data-table-root': {
          background: ' $pageBackgroundColor',
        },
      }}
      title="Aggregation Group Table"
      hideTitleHeader
      tableState={dataTableProps}
    />
  );
};
