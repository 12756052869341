export const subscriberCLabels = {
  REBATE_REPORT_YEAR: 'Rebate Report Year',
  RUN_TYPE: 'Run Type',
  REVENUE_NO_SUBSCRIBER: 'Revenue No Subscriber',
  CLONE_BY_POLICY: 'Clone By Policy',
  LEGAL_ENTITY: 'Legal Entity',
  SOURCE_SYSTEM: 'Source System',
  CASE_SIZE: 'Case Size',
  SITUS_STATE: 'Situs State',
  POLICY_NUMBER: 'Policy Number',
  SEARCH: 'Search',
  RESET: 'Reset',
  BUSINESS_UNIT: 'G/L Business Unit',
  LOCATION: 'G/L Location',
  REBATE_INDICATOR: 'Rebate Indicator',
  SUBSCRIBER_COUNT: 'Subscriber Count',
  CLONE_SELECTED: 'Clone Selected',
  CLONE: 'Clone',
  SELECT: 'Select',
  DOWNLOAD: 'Download To Excel',
  SOURCE_AOG: 'Source Aggregation Operational Group',
  TARGET_AOG: 'Target Aggregation Operational Group',
  REVEN_NOSUB_VALUE: 'Revenue No Subscriber',
  CLONE_BY_POLICY_VALUE: 'Clone By Policy',
  SAVE: 'Save',
}
