import React from 'react';

export const HelpContent = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          The legal entity is basically the segment of the company that
          underwrites the plan or products. It is same as general ledger
          business unit.
        </li>
        <li>
          This screen shows the list of legal entities that are used for rebate
          processing for the current rebate reporting year.
        </li>

        <li>
          From this screen user can add a new legal entity or can modify the
          existing one by clicking on the legal entity.
        </li>
      </ul>
    </div>
  );
};

export const AddHelpContent = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          Here the user can modify the legal entity ( all fields are editable).
        </li>
      </ul>
    </div>
  );
};
