import React from 'react';
import { Text } from '@abyss/web/ui/Text';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

export const aogHeader = () => {
  return [
    {
      Header: 'ASO Exception',
      accessor: 'asoXcptInd',
      disableSortBy: true,
      Cell: ({ value, cellActions, row, ...props }) => {
        return (
          <label htmlFor={row.id}>
            <input
              id={row.id}
              type="checkbox"
              onChange={() => {
                cellActions.modifyRow(row, {
                  asoXcptInd: !value,
                });
              }}
              style={{ cursor: 'pointer' }}
            />
            <IconSymbol
              icon={value ? 'check_box' : 'check_box_outline_blank'}
              size="md"
            />
          </label>
        );
      },
    },
    {
      Header: 'Policy',
      accessor: 'srcSysCustContrId',
      Cell: ({ value, cellActions, row, ...props }) => {
        return <a href="#">{value}</a>;
      },
    },
    {
      Header: 'Primary AOG?',
      accessor: 'priAggrOperGrpInd',
    },
    {
      Header: 'Rebatable?',
      accessor: 'inclInRbtInd',
    },
    {
      Header: 'G/L Business Unit',
      accessor: 'glBuId',
    },
    {
      Header: 'G/L Location',
      accessor: 'glLocCd',
    },
    {
      Header: 'G/L Product',
      accessor: 'glPrdctCd',
    },
    {
      Header: 'G/L Customer',
      accessor: 'glCustId',
    },
    {
      Header: 'G/L operational Unit',
      accessor: 'glOperUnitId',
    },
    {
      Header: 'Source System',
      accessor: 'srcSysCd',
    },
    {
      Header: 'Earned Premium Amount',
      accessor: 'epAmt',
    },
    {
      Header: 'Adj. Earned Premium Amount',
      accessor: 'adjEpAmt',
    },
    {
      Header: 'Comb Earned Premium Amount',
      accessor: 'custContrCombAogEpAmt',
    },
    {
      Header: 'Comb Adj Earned Premium Amount',
      accessor: 'custContrCombAogAdjEpAmt',
    },
    {
      Header: 'Calc. Ag. Cust. Contr. Rebate Amount',
      accessor: 'calcAgCustContrRbtAmt',
    },
  ];
};

export const HelpContent = () => {
  return (
    <React.Fragment>
      <Text size="$lg">
        An Aggregation Operation Group (AOG) is the combination of AG, Source
        System, G/L Business Unit, G/L Location and Policy.
      </Text>
      <br />
      <br />
      <Text size="$lg">
        This screen allows the user to find out the list of AOG based on search
        parameter for given Aggregation Group.
      </Text>
    </React.Fragment>
  );
};

export const HelpContentResult = () => {
  return (
    <React.Fragment>
      <Text size="$lg">
        This screen displays the list of Aggregation Operation Groups/policy
        list based on search parameter. Here the user can be able to see the
        Earned Premium Amount & Adjusted Earned Premium Amount associated with
        the policy.
      </Text>
      <br />
      <br />
      <Text size="$lg">
        By selecting the Policy, user can be able to view policy address.
      </Text>
    </React.Fragment>
  );
};
