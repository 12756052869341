/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Text } from '@abyss/web/ui/Text';

export const HelpContent = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen enables the user to search a specific subscriber present
          in the MLR Rebate Engine system for a Policy.
        </li>
        <li>
          User can search details by providing the Source system, Policy Number,
          Last Name, First Name, Last 4 digits of SSN and Alternate ID as input.
        </li>
      </ul>
    </div>
  );
};

export const HelpContentResult = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen shows the list of subscriber and their premium
          contribution details associated with the policy selected in previous
          screen. It also shows their address details and premium contribution
          details.
        </li>
        <li>
          Here user can be able to view/update the subscriber details and the
          address by clicking on the subscriber id
        </li>
      </ul>
    </div>
  );
};

export const HelpContentSubView = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen displays the subscriber details such as the basic
          subscriber information, premium contribution details, check details ,
          and the address information.
        </li>
        <li>User can view/update the subscriber details and the address.</li>
        <li>
          User can view the history of subscriber addresses by clicking on the
          History icon beside the Address Field.
        </li>
        <li>
          User can view the Notes linked to Subscriber by clicking on "Notes"
          button.
        </li>
        <li>
          User can view Subscriber Category along with its AG and AOG details by
          clicking on the Notification Status button.
        </li>
        <li>Possible Subscriber Category values are.</li>
        <li>A-Rebate Year Only.</li>
        <li>B-Rebate and Current Year.</li>
        <li>C-Current Year Only.</li>
        <li>D-NA for MLR.</li>
        <li>
          Notification related information like the Post mark status and Date
          are available for View/Edit under the Notification Status frame
        </li>
        <li>User can view/update the subscriber details and the address.</li>
      </ul>
    </div>
  );
};

export const HelpContentCustomerSearchView = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen shows the list of subscriber and their premium
          contribution details associated with the policy selected in previous
          screen. It also shows their address details and premium contribution
          details.
        </li>
        <li>
          Here user can be able to view/update the subscriber details and the
          address by clicking on the subscriber id.
        </li>
      </ul>
    </div>
  );
};

export const getNotificationColumns = () => {
  return [
    {
      Header: 'LE Code',
      accessor: 'col1',
      disableSortBy: true,
    },
    {
      Header: 'LE Name',
      accessor: 'col2',
      disableSortBy: true,
    },
    {
      Header: 'Situs',
      accessor: 'col3',
    },
    {
      Header: 'INN Catgy',
      accessor: 'col4',
    },
    {
      Header: 'ONN Catgy',
      accessor: 'col5',
    },
    {
      Header: 'Status',
      accessor: 'col6',
    },
    {
      Header: 'Post Mark Date',
      accessor: 'col7',
    },
    {
      Header: 'Edit History',
      accessor: 'col8',
    },
  ];
};

export const getHistoryColumns = () => {
  return [
    {
      Header: 'Address',
      accessor: 'address',
      disableSortBy: true,
    },
    {
      Header: 'City',
      accessor: 'city',
      disableSortBy: true,
    },
    {
      Header: 'State',
      accessor: 'state',
      disableSortBy: true,
    },
    {
      Header: 'ZIP Code',
      accessor: 'zip',
      disableSortBy: true,
    },
    {
      Header: 'Modified By',
      accessor: 'modifiedBy',
      disableSortBy: true,
    },
    {
      Header: 'Modified Date',
      // accessor: 'modifiedDate',
      disableSortBy: true,
      accessor: (row) => {
        const date = new Date(row.modifiedDate);
        return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
          .getDate()
          .toString()
          .padStart(2, '0')}/${date.getFullYear()}`;
      },
    },
  ];
};

export const paymentData = [
  {
    col1: '849674333',
    col2: '$10',
    col3: '02-02-2024',
  },
  {
    col1: '849674333',
    col2: '$10',
    col3: '02-02-2024',
  },
];

export const notificationData = [
  {
    col1: '849674333',
    col2: 'United Healthcare insurance Company',
    col3: 'ABCDE',
    col4: 'A',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
  },
  {
    col1: '849674334',
    col2: 'United Healthcare insurance Company',
    col3: 'ABCDE',
    col4: 'A',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
  },
];
