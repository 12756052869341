import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import { BASE_URL } from '@src/components/utils/endpoints';
import { MlreLoader } from '@src/common/MlreAbyssComponent';
import { PaymentSearchResults } from '@src/components/payments/PaymentSearchResults';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import { SubHeader } from '@src/common/components';
import { HelpContentSubView } from '@src/components/Subscriber/utils';

const SubscriberPayment = () => {
  const { getLocation } = useRouter();
  const { state, location } = getLocation();
  const [apiData, setApiData] = useState([]);
  const [isFetched, setIsisFetched] = useState(false);
  const payloadData = state?.payload;
  const { token } = useAuth();
  const { breadcrums } = state;

  const fetchData = async () => {
    const { data, error } = await axios.post(
      `${BASE_URL}payment/search`,

      payloadData,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!error) {
      setApiData(data);
    }
    setIsisFetched(true);
  };
  useEffect(() => {
    if (payloadData) {
      fetchData();
    }
  }, [payloadData, state]);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              {breadcrums ? (
                <SubHeader
                  breadItem={[
                    ...breadcrums,
                    { title: 'Subscriber Payment', href: '/subscriber-payment/' },
                  ]}
                  infoTitle="Subscriber Details"
                  InfoContent={HelpContentSubView}
                />
              ) : (
                ''
              )}
              {isFetched ? (
                <PaymentSearchResults paymentData={apiData} breadcrums={[
                  ...breadcrums,
                  { title: 'Subscriber Payment', href: '/subscriber-payment/', state: { payload: payloadData, breadcrums } },
                ]} />
              ) : (
                <MlreLoader />
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default withAuth(SubscriberPayment)
