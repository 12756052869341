/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import axios from 'axios';
import { BASE_URL } from '../utils/endpoints';

export const ConfirmVoidModal = ({ isOpen, onClose, onConfirm, data }) => {
  const [validatePaymentData, setValidatePaymentData] = useState();
  const validatePayment = async () => {
    try {
      const payload = {
        payDocNum: data.pmtDocNbr,
        modifiedDateTime: data.modifiedDateTime,
      };
      const result = await axios.post(`${BASE_URL}payment/validate`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });
      if (result?.data) {
        setValidatePaymentData(result?.data);
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const handleValidatedSubmit = async () => {
    const validationResult = await validatePayment();
    if (validationResult === 'validation passed') {
      onConfirm();
      onClose();
    } else {
      alert('Failed: Current data has been modified by other user');
      onClose();
    }
  };

  return (
    <Modal title="" isOpen={isOpen} onClose={onClose} size="md">
      <Modal.Section>Are you sure you want to void check?</Modal.Section>

      <Modal.Section>
        <Layout.Group alignLayout="right">
          <Button
            // onClick={() => {
            //   onClose();
            //   onConfirm();
            // }}
            onClick={handleValidatedSubmit}
          >
            OK
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};

export const ConfirmSaveModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal title="" isOpen={isOpen} onClose={onClose} size="md">
      <Modal.Section>
        Are you sure you want to cancel without saving data?
      </Modal.Section>

      <Modal.Section>
        <Layout.Group alignLayout="right">
          <Button
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            OK
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};
