import React, { useEffect, useState } from 'react';
import { SubNavigationMenu } from '@abyss/web/ui/SubNavigationMenu';
// import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import axios from 'axios';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from './utils/endpoints';

export const Hamburger = () => {
  const { navigate } = useRouter();
  const { getLocation } = useRouter();
  const { pathname } = getLocation();
  const [activePath, setActivePath] = useState(pathname);

  useEffect(() => {
    setActivePath(pathname);
  }, [pathname]);

  // const checkSubCloneRedirection = async () => {
  //   // navigate('/subscriber-clone');
  //   const result = await axios.get(`${BASE_URL}subscriber-clone/count`, {
  //     headers: {
  //       authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
  //       'Content-Type': 'application/json',
  //     },
  //   });

  //   if (result?.data?.showMessage) {
  //     alert(result?.data?.showMessage);
  //   } else {
  //     navigate('/subscriber-clone');
  //   }
  // };
  const checkSubCloneRedirection = async () => {
    try {
      const result = await axios.get(`${BASE_URL}subscriber-clone/count`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (result?.data?.showMessage) {
        alert(result?.data?.showMessage);
      } else {
        navigate('/subscriber-clone');
      }
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
      console.error('API call failed:', error);
    }
  };

  const sideBarMenuItems = [
    {
      title: 'Home',
      href: '/',
    },
    {
      title: 'Configuration',
      href: '/configuration',
    },
    {
      title: 'Legal Entity',
      href: '/legal-entity',
    },
    {
      title: 'Aggregation Group',
      href: '/aggregation-group',
    },
    {
      title: 'Aggregation Operational Group',
      href: '/aggregation-operation-group',
    },
    {
      title: 'Customer',
      href: '/customer',
    },
    {
      title: 'Subscriber',
      href: '/subscriber',
    },
    {
      title: 'Subscriber Clone',
      href: '/subscriber-clone',
      onClick: (e) => {
        e.preventDefault();
        checkSubCloneRedirection();
      },
    },
    {
      title: 'Payment',
      href: '/payment',
    },
    {
      title: 'Check Reissue Approval',
      href: '/check-reissue-approval',
    },
    {
      title: 'Reports',
      href: '/reports',
    },
  ];

  const setActive = (menuItem) => {
    const activePaths = {
      '/subscriber-view': '/subscriber',
      '/subscriber-payment/': '/subscriber',
      '/subscriber-payment': '/subscriber',

      '/customer/subscriber/list': '/subscriber',
      '/customer-policyList': '/Customer',

      '/aggregation-group-operation/': '/aggregation-group',

      '/aggregation-group-operation': '/aggregation-group',
      '/aggregation-group/': '/aggregation-group',
      '/aog-customer': '/aggregation-group',
      '/aog-customer/': '/aggregation-group',
      '/update-legal-entity': '/legal-entity',
      '/payment-detail': '/payment',
      '/Payment#table': '/payment',
      '/employer-conversion-check': '/payment',
      '/subscriber-clone-result': '/subscriber-clone',
      '/subscriber-clone/': '/subscriber-clone',
      '/ssaog': '/subscriber-clone',
      '/pending-reissue-approval': '/check-reissue-approval',
      '/reissue-approved': '/check-reissue-approval',
      '/reissue-rejected': '/check-reissue-approval',
      '/pending-redistribute-approval': '/check-reissue-approval',
    };

    return (
      menuItem.href === activePath ||
      (activePaths[activePath] && activePaths[activePath] === menuItem.href)
    );
  };

  return (
    <SubNavigationMenu
      navItems={sideBarMenuItems}
      position="left"
      variant="sideBarMenu"
      headingLevel="h6"
      setActive={setActive}
      css={{
        'abyss-sub-navigation-menu-root': {
          width: 'auto',
          position: 'relative',
          flexGrow: '1',
          background: '$sideBarBackgroundColor',
          bottom: '0 !important',
          boxShadow: '0 1px 6px 0 rgba(32, 33, 36, 0.28) !important',
          height: '100%',
        },
        'abyss-sub-navigation-menu-link-active': {
          color: '#000 !important',
        },
      }}
    />
  );
};
