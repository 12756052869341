/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';
import { useRouter } from '@abyss/web/hooks/useRouter';

import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';

import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import axios from 'axios';
import { Divider } from '@abyss/web/ui/Divider';
import { Text } from '@abyss/web/ui/Text';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import useAuth from '@src/auth/useAuth';
import { MlreDataTable, MlreLoader } from '../../common/MlreAbyssComponent';
import {
  CHECK_REISSUE_STATUS,
  PENDING_REISSUE_APPROVAL,
  RESET,
  SAVE,
} from './CheckReissueContant';
import { BASE_URL } from '../utils/endpoints';
import env from '../../../env.json';
import { PendingReissueApprovalIcon } from './CheckReissueHelpIcon';
import ReissueCheckbox from './ReissueCheckbox';

const CheckPendingReissueApproval = () => {
  const [breadItem, setBreadItem] = useState([
    { title: 'Check Reissue Approval Search', href: '/check-reissue-approval' },
    {
      title: 'Check Reissue Results',
      href: '/pending-redistribute-approval',
    },
  ]);
  const [tableCount, setTableCount] = useState(100);
  const [response, setResponse] = useState('');
  const [checkStatus, setCheckStatus] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { userRolesGroups } = useAuth();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [reset, setReset] = useState(false);
  const [formDefaultValues, setFormDefaultValues] = useState({});
  const [values, setValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const { getLocation } = useRouter();
  const { state } = getLocation();

  useEffect(() => {
    const hasUpdateAccess = env.Pending_Reissue_Approval.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups, env]);

  const form = useForm({
    defaultValues: {
      formDefaultValues,
    },
    values,
  });
  const handleSave = async () => {
    setReset(false);
    setIsLoading(true);

    // if (!firstStatus) return alert('No check selected');
    const formValues = form.getValues();
    const selected = Object.values(formValues).some((item) => {
      return item;
    });
    if (!selected) {
      alert('No check slected');
      setIsLoading(false);
    }
    const keys = Object.keys(formValues);
    const approveItems = keys.filter((item) => {
      return (
        formValues[item] && item.includes('approve') && item !== 'approve_all'
      );
    });
    const rejectItems = keys.filter((item) => {
      return (
        formValues[item] && item.includes('reject') && item !== 'reject_all'
      );
    });

    const payload = [];
    approveItems.forEach((item, index) => {
      const itemPayDocNbr = item.split('_')[2];
      const key = `approve_checked_${itemPayDocNbr}`;
      const selectedValue = formValues[key];
      const selectedRow = tableData.find((data) => {
        return data.payDocNbr === itemPayDocNbr;
      });
      if (selectedRow) {
        const payloadObj = {
          atr2: 'Reissue Approved',
          // atr3: selectedRow?.atr3,
          payDocNbr: selectedRow?.payDocNbr,
          modById: selectedRow?.PayeId,
          rbtPayId: selectedRow?.rbtPayId,
        };
        payload.push(payloadObj);
      }
    });
    if (approveItems.length) {
      await axios
        .post(`${BASE_URL}checkReissue/updateApprove`, payload, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          // APIData();
          setReloadData(true);

          setTimeout(() => {
            setReloadData(false);
          }, 2000);
          setResponse(res.data.success);
          if (res.data.success === 'approve successfully') {
            if (!rejectItems.length) {
              alert('Check reissue approval/reject saved successfully.');
              setTableCount(tableCount - payload.length);

              form.reset();
            }
          } else {
            console.log('error');
          }
          setResponse(res.data.success);
        })
        .catch((error) => {
          alert(
            'An error occurred while processing your request. Please try again later.'
          );
        });
    }
    const rejectPayload = [];
    rejectItems.forEach((item, index) => {
      const itemPayDocNbr = item.split('_')[2];

      const key = `reject_checked_${itemPayDocNbr}`;
      const selectedValue = formValues[key];
      const selectedRow = tableData.find((data) => {
        return data.payDocNbr === itemPayDocNbr;
      });
      if (selectedRow) {
        const rejectPayloadObj = {
          atr2: 'Reissue Rejected',
          payDocNbr: selectedRow?.payDocNbr,
          modById: selectedRow?.PayeId,
          rbtPayId: selectedRow?.rbtPayId,
        };
        rejectPayload.push(rejectPayloadObj);
      }
    });
    if (rejectItems.length) {
      await axios
        .post(`${BASE_URL}checkReissue/updateReject`, rejectPayload, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          // APIData();

          setReloadData(true);
          if (approveItems.length && rejectItems.length) {
            setTableCount(tableCount - (rejectPayload.length + payload.length));
          } else {
            setTableCount(tableCount - rejectPayload.length);
          }
          setTimeout(() => {
            setReloadData(false);
          }, 2000);
          form.reset();
          if (res.data.success === 'reject successfully') {
            setIsLoading(true);
            alert('Check reissue approval/reject saved successfully.');
          } else {
            console.log('error');
          }
          setResponse(res.data.success);
        })
        .catch((error) => {
          console.log(error);
          alert(
            'An error occurred while processing your request. Please try again later.'
          );
        });
    }
    setCheckStatus({});
    setIsLoading(false);
  };
  const handleCheckAll = (e) => {
    const action = e.target.name;
    const formValues = form.getValues();
    const keys = Object.keys(formValues);
    const approveAllItems = keys.filter((item) => {
      return item.includes('approve');
    });
    const rejectAllItems = keys.filter((item) => {
      return item.includes('reject');
    });

    if (e.target.checked && action === 'reject_all') {
      rejectAllItems.forEach((item) => {
        form.setValue(item, true);
      });
      approveAllItems.forEach((item) => {
        form.setValue(item, false);
      });
    }
    if (!e.target.checked && action === 'reject_all') {
      rejectAllItems.forEach((item) => {
        form.setValue(item, false);
      });
      approveAllItems.forEach((item) => {
        form.setValue(item, false);
      });
    }
    if (e.target.checked && action === 'approve_all') {
      approveAllItems.forEach((item) => {
        form.setValue(item, true);
      });
      rejectAllItems.forEach((item) => {
        form.setValue(item, false);
      });
    }
    if (!e.target.checked && action === 'approve_all') {
      approveAllItems.forEach((item) => {
        form.setValue(item, false);
      });
      rejectAllItems.forEach((item) => {
        form.setValue(item, false);
      });
    }
  };
  const handleCheckStatus = (key, value, checked) => {
    const formValues = form.getValues();

    if (value === 'Reject') {
      form.setValue(`approve_checked_${key}`, false);
      form.setValue(`approve_all`, false);
      const rejectedItems = Object.keys(formValues).filter((item) => {
        return (
          formValues[item] === true &&
          item.includes('reject') &&
          item !== 'reject_all'
        );
      });
      if (rejectedItems.length === 10) {
        form.setValue(`reject_all`, true);
      }

      if (!checked) {
        form.setValue(`reject_all`, false);
      }
    } else {
      form.setValue(`reject_checked_${key}`, false);
      form.setValue(`reject_all`, false);
      const approveItems = Object.keys(formValues).filter((item) => {
        return (
          formValues[item] === true &&
          item.includes('approve') &&
          item !== 'approve_all'
        );
      });
      if (approveItems.length === 10) {
        form.setValue(`approve_all`, true);
      }

      if (!checked) {
        form.setValue(`approve_all`, false);
      }
    }

    setCheckStatus((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const fetchData = async (
    page,
    pageSize,
    sortBy,
    globalFilter,
    columnFilters
  ) => {
    const { data, error } = await axios.post(
      `${BASE_URL}checkReissue/search-check-with-params`,
      {
        atr2: 'Pending Reissue Approval',
        pageNumber: page,
        pageSize,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    // const newDataArr = data.map((item) => {
    //   return { ...item, rowStatus: '' };
    // });
    setResponseData(data);
    setTableData(data);
    return {
      results: data,
      count: tableCount,
    };
    setIsLoading(false);
  };

  const fetchCount = async () => {
    const { data, error } = await axios.post(
      `${BASE_URL}checkReissue/count`,
      {
        atr2: 'Pending Reissue Approval',
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (!error && data.length > 0) {
      setTableCount(data[0].count);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (state && state.count[0]) {
      setTableCount(state.count[0].count);
      setIsLoading(false);
    } else {
      fetchCount();
    }
  }, []);
  const config = {
    pageSizeDefault: 50,
    uniqueStorageId: `server-approve`,
    apiPaginationCall: fetchData,
    manualSortBy: false,
    showColumnSort: false,
    apiQueryOptions: {
      requestPolicy: 'no-cache',
    },
  };

  const onSubmit = () => {};

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Check Number',
        accessor: 'payDocNbr',
        disableSortBy: true,
        align: 'center',
      },
      {
        Header: 'Policy Type',
        accessor: 'atr5',
        disableSortBy: true,
        align: 'center',
      },
      {
        Header: 'Payee Name',
        accessor: 'name',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Address',
        accessor: 'address',
        disableSortBy: true,
        width: 250,
        align: 'center',
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
      },
      {
        Header: 'Check Status',
        accessor: 'payStsCd',
        disableSortBy: true,
        align: 'center',
      },
      {
        Header: 'Post Mark Date',
        accessor: 'payPstMrkDt',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => {
          if (value === null || value === '' || value === undefined) {
            return '';
          }

          const payData = value ? value.substring(0, value.indexOf('T')) : '';
          const modifiedDate = payData.replaceAll('-', '/');
          const splitDate = modifiedDate.split('/');
          const modifiedSplitDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
          return modifiedSplitDate;
        },
      },

      {
        Header: (
          <React.Fragment>
            <div>Approve</div>
            <Divider />
            <div>Select All</div>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Checkbox
                label=""
                hideLabel
                isDisabled={isButtonDisabled}
                isChecked={isChecked}
                model="approve_all"
                onChange={handleCheckAll}
              />
            </div>
          </React.Fragment>
        ),

        accessor: 'col6',
        disableSortBy: true,
        align: 'center',

        Cell: ({ row }) => {
          return (
            <ReissueCheckbox
              row={row}
              checkStatus={checkStatus}
              handleCheckStatus={handleCheckStatus}
              value="Approve"
            />
          );
        },
      },
      {
        Header: (
          <React.Fragment>
            <div>Reject</div>
            <Divider />
            <div>Select All</div>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Checkbox
                label=""
                isDisabled={isButtonDisabled}
                hideLabel
                isChecked={isChecked}
                model="reject_all"
                onChange={handleCheckAll}
              />
            </div>
          </React.Fragment>
        ),
        accessor: 'col8',
        align: 'center',

        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <ReissueCheckbox
              row={row}
              checkStatus={checkStatus}
              handleCheckStatus={handleCheckStatus}
              value="Reject"
            />
          );
        },
      },
    ];
  }, [checkStatus, isButtonDisabled]);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <FormProvider state={form} onSubmit={onSubmit}>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ margin: '0px', background: '$headerBackgroundColor' }}
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
          >
            <HeaderDiv>
              <Header />
            </HeaderDiv>
          </Grid.Col>
        </Grid>
        <BodyContainer>
          <Grid css={{ margin: '0px' }}>
            <Grid.Col
              css={{ padding: '0px' }}
              span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
            >
              <MediaQuery largerThan="$md">
                <Hamburger />
              </MediaQuery>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Check Reissue Results"
                  InfoContent={PendingReissueApprovalIcon}
                />
                <Grid>
                  <Grid.Col span={12} css={{ paddingTop: '32px' }}>
                    <Grid.Col span={12}>
                      <Text css={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {CHECK_REISSUE_STATUS}:
                        <Text
                          css={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            paddingLeft: '8px',
                          }}
                        >
                          {PENDING_REISSUE_APPROVAL}
                        </Text>
                      </Text>
                    </Grid.Col>
                    {isLoading ? (
                      <MlreLoader />
                    ) : (
                      <MlreDataTable
                        columns={columns}
                        config={config}
                        reloadData={reloadData}
                        refreshColumn
                      />
                    )}
                  </Grid.Col>
                </Grid>
                <Layout.Group css={{ justifyContent: 'center' }}>
                  <Button
                    onClick={handleSave}
                    css={{ borderRadius: '4px' }}
                    isDisabled={isButtonDisabled}
                  >
                    {SAVE}
                  </Button>
                  <Button
                    type="reset"
                    onClick={() => {
                      form.reset();

                      setCheckStatus('');

                      setTimeout(() => {
                        setReloadData(false);
                      }, 500);
                    }}
                    css={{ borderRadius: '4px' }}
                    isDisabled={isButtonDisabled}
                  >
                    {RESET}
                  </Button>
                </Layout.Group>
              </BodyPageContainer>
            </Grid.Col>
          </Grid>
        </BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ background: '$pageFooter-color-background-root' }}
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
          >
            <Footer />
          </Grid.Col>
        </Grid>
      </FormProvider>
    </div>
  );
};
export default withAuth(CheckPendingReissueApproval);
