/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Link } from '@abyss/web/ui/Link';
import { useRouter } from '@abyss/web/hooks/useRouter';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import {
  MlreLoader,
  MlreDataTable,
  DataTableSearchLabel,
} from '../../common/MlreAbyssComponent';
import { BASE_URL } from '../utils/endpoints';

export const SubscriberDataTable = ({ searchData }) => {
  const [isLoading, setLoading] = useState(true);
  const [tableCount, setTableCount] = useState(searchData?.dataCount || 100);
  const [pageIndex, setPageIndex] = useState(null);
  const [redirectData, setNavigateData] = useState();
  const { navigate } = useRouter();
  const { token } = useAuth();

  useEffect(() => {
    if (searchData) {
      if (searchData.dataCount) {
        setPageIndex(searchData.pageIndex);
        setTableCount(searchData.dataCount);
        setLoading(false);
      } else {
        fetchCount();
      }
    }
  }, [searchData]);

  useEffect(() => {
    if (redirectData) {
      const { navigateData } = redirectData;
      const breadcrums = [
        { title: 'Subscriber Search', href: '/subscriber/' },
        {
          title: 'Subscribers List',
          href: '/subscriber#table',
          state: {
            breadcrumsData: {
              ...searchData,
              dataCount: tableCount,
              pageIndex: redirectData?.pageInd,
            },
          },
        },
      ];

      navigate('/subscriber-view', {
        state: { navigateData: { ...navigateData, searchData }, breadcrums },
      });
    }
  }, [redirectData]);

  const fetchCount = async () => {
    try {
      const { data, error } = await axios.post(
        `${BASE_URL}subscriber/count`,
        searchData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!error) {
        setTableCount(data[0].count);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const fetchData = async (
    page,
    pageSize,
    sortBy,
    globalFilter,
    columnFilters
  ) => {
    try {
      const { data, error } = await axios.post(
        `${BASE_URL}subscriber/subsearch`,
        { ...searchData, pageSize, pageNum: page },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      return {
        results: data,
        count: tableCount,
      };
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Subscriber ID',
        accessor: 'altId',
        Cell: ({ value, row, state }) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                const navigateData = {
                  ...row.original,
                  id: row.values?.custContrSbscrId,
                  year: searchData.rebateYear,
                };
                setNavigateData({
                  navigateData,
                  pageInd: state.pageIndex,
                });
              }}
              href="#"
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Last Name',
        accessor: 'sbscrLstNm',
      },
      {
        Header: 'First Name',
        accessor: 'sbscrFstNm',
      },
      {
        Header: 'Middle Init',
        accessor: 'sbscrMidlInitNm',
      },

      {
        Header: 'Customer ID',
        accessor: 'srcSysCustId',
      },
      {
        Header: 'Premium Contribution',
        accessor: 'totPremCntrbAmt',
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Address',
        accessor: 'sbscrAdrLn1',
        disableSortBy: true,
        align: 'center',

        Cell: ({ value, row }) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {`${row.original.sbscrAdrLn1} ${row.original.sbscrAdrLn2} ${row.original.sbscrAdrLn3} ${row.original.sbscrCtyNm} ${row.original.sbscrStCd} ${row.original.sbscrZipCd}`}
            </div>
          );
        },
      },
    ];
  }, []);

  const searchLabel = [
    { fieldName: 'Policy#', value: searchData?.policy },
    { fieldName: 'Source System', value: searchData?.srcSysCd },
  ];

  if (isLoading) return <MlreLoader />;
  // const key = Math.random();
  const config = {
    pageSizeDefault: 10,
    uniqueStorageId: `server-side`,
    // uniqueStorageId: `server-side${key}`,
    apiPaginationCall: fetchData,
    manualSortBy: false,
    showColumnSort: false,
    apiQueryOptions: {
      requestPolicy: 'no-cache',
    },
  };

  return (
    <React.Fragment>
      <Grid>
        <Grid.Col span={12}>
          <DataTableSearchLabel searchLabel={searchLabel} />
          <MlreDataTable columns={columns} config={config} page={pageIndex} />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
export default withAuth(SubscriberDataTable);
