interface AggregationFormData {
  value: string;
  value1: string;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
  value7: string;
  value8: string;
  value9: string;
  value10: string;
  value11: string;
  value12: string;
  value13: string;
  value14: string;
  value15: string;
  value16: string;
  value17: string;
}
export const AggregationFormData: AggregationFormData = {
  value: 'Rebate Report Year',
  value1: 'Run Type',
  value2: 'Run Sequence',
  value3: 'MLR CaseSize',
  value4: 'Situs State',
  value5: 'Legal Entity',
  value6: 'Search',
  value7: 'Reset',
  value8: 'Hold Payment',
  value9: 'rbtRptYr',
  value10: 'Eneter the  Year',
  value11: 'Enter the run type',
  value12: 'Enter the run sequence',
  value13: 'runTypCd',
  value14: 'runTypSeqNbr',
  value15: 'mlrCseSz',
  value16: 'mlrSitusCd',
  value17: 'lglEntyId',
};
