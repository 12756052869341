/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';

export const ConfirmModal = ({ isOpen, onClose, onConfirm, modalType }) => {
  return (
    <Modal title="" isOpen={isOpen} onClose={onClose} size="md">
      <Modal.Section>
        {modalType === 'Approve Payment'
          ? 'Are you sure you want to Approve for Release payment?'
          : 'Are you sure you want to Release the Hold payment?'}
      </Modal.Section>

      <Modal.Section>
        <Layout.Group alignLayout="right">
          <Button
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            OK
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};

export const ApprovePaymentModal = ({
  isOpen,
  onClose,
  searchData,
  callBack,
  approverId,
  modalType,
}) => {
  const paymentRreleaseInfo = [
    { fieldName: 'Release Reporting year', value: searchData?.rbtRptYr },
    { fieldName: 'Legal Entity', value: searchData?.lglEntyId },
    { fieldName: 'Run Type', value: searchData?.runTypCd },
    { fieldName: 'Status ', value: searchData?.mlrSitusCd },
    { fieldName: 'Run Sequence', value: searchData?.runTypSeqNbr },
    { fieldName: 'MLR Case Size', value: searchData?.mlrCseSz },
  ];

  const [approverData, setApproverData] = useState();
  useEffect(() => {
    if (isOpen) {
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });

      setApproverData(formattedDate);
    }
  }, [isOpen]);
  const isApprove = modalType === 'Approve Payment';
  return (
    <Modal
      title={
        isApprove
          ? 'Approval for Release Payment'
          : 'Approval for Release on Hold Payment'
      }
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      titleAlign="center"
      css={{
        'abyss-modal-content-container': {
          background: '$pageBackgroundColor',
        },
      }}
    >
      <Modal.Section>
        <Grid css={{ padding: '20px 30px' }}>
          {paymentRreleaseInfo.map((item) => {
            if (item.value) {
              return (
                <React.Fragment>
                  <Grid.Col
                    css={{ paddingBottom: '0px' }}
                    span={{ xs: 8, md: 4 }}
                  >
                    <Text color="$interactive1" fontWeight="bold">
                      {item.fieldName}
                    </Text>
                  </Grid.Col>
                  <Grid.Col
                    css={{ paddingBottom: '0px' }}
                    span={{ xs: 4, md: 2 }}
                  >
                    <Text fontWeight="bold">: {item.value}</Text>
                  </Grid.Col>
                </React.Fragment>
              );
            }
          })}
        </Grid>
        <Grid css={{ margin: '0px 50px', borderBottom: '1px solid' }}>
          <br />
        </Grid>

        <Grid css={{ padding: '20px 30px' }}>
          <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 6, md: 3 }}>
            <Text color="$interactive1" fontWeight="bold">
              {isApprove ? 'Approver ID' : 'Released By ID'}
            </Text>
          </Grid.Col>
          <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 6, md: 3 }}>
            <Text fontWeight="bold">: {approverId}</Text>
          </Grid.Col>

          <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 6, md: 3 }}>
            <Text color="$interactive1" fontWeight="bold">
              {isApprove ? 'Approver' : 'Released'} Date
            </Text>
          </Grid.Col>
          <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 6, md: 3 }}>
            <DateInput
              label=""
              subText=""
              hideLabel
              isDisabled
              value={approverData}
              onChange={(e) => {
                setApproverData(e);
              }}
            />
          </Grid.Col>
        </Grid>
      </Modal.Section>

      <Modal.Section>
        <Layout.Group alignLayout="center">
          <Button
            onClick={() => {
              callBack(approverData);
            }}
          >
            Submit
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};
