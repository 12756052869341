import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';

import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';

import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
import { Text } from '@abyss/web/ui/Text';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';
import { SubHeader } from '@src/common/components';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import useAuth from '@src/auth/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { MlreDataTable, MlreLoader } from '../../common/MlreAbyssComponent';
import { BASE_URL } from '../utils/endpoints';
import {
  CHECK_REISSUE_STATUS,
  REISSUE_REJECTED,
  RESET,
  SAVE,
} from './CheckReissueContant';
import { PendingReissueApprovalIcon } from './CheckReissueHelpIcon';
import env from '../../../env.json';
import Row from './Row';

const CheckPendingReject = () => {
  const [breadItem, setBreadItem] = useState([
    { title: 'Check Reissue Approval Search', href: '/check-reissue-approval' },
    {
      title: 'Check Reissue Results',
      href: '/reissue-rejected',
    },
  ]);

  const [tableCount, setTableCount] = useState(100);

  const [checkValue, setCheckValue] = useState({});
  const [response, setResponse] = useState('');
  const [checkStatus, setCheckStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [formDefaultValues, setFormDefaultValues] = useState({});
  const [values, setValues] = useState({});

  const form = useForm({
    defaultValues: {
      formDefaultValues,
    },
    values,
  });
  const { userRolesGroups } = useAuth();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [reset, setReset] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [value, setValue] = useState('Reject');
  const [isChecked, setIsChecked] = useState(true);
  const [resetStatus, setResetStatus] = useState(false);
  const [tableData, settableData] = useState([]);
  const { getLocation } = useRouter();
  const { state } = getLocation();

  const atr2 = 'Reissue Rejected';
  useEffect(() => {
    const hasUpdateAccess = env.Check_Reissue_Status_Reissue_Rejected.some(
      (role) => {
        return userRolesGroups.includes(role);
      }
    );

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups]);

  const fetchData = async (page, pageSize) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}checkReissue/search-check-with-params`,
        {
          atr2,
          pageNumber: page,
          pageSize,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      settableData(data);
      const newData = {};
      data.forEach((item) => {
        const dataValue = `radio_reset_${item.payDocNbr}`;
        newData[dataValue] = 'Reject';
      });
      setValues(newData);
      setFormDefaultValues(newData);
      return {
        results: data,
        count: tableCount,
      };
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const fetchCount = async () => {
    const { data, error } = await axios.post(
      `${BASE_URL}checkReissue/count`,
      {
        atr2,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (!error && data.length > 0) {
      setTableCount(data[0].count);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (state && state.count[0]) {
      setTableCount(state.count[0].count);
      setIsLoading(false);
    } else {
      fetchCount();
    }
  }, []);

  const config = {
    pageSizeDefault: 50,
    uniqueStorageId: `server-approve`,
    apiPaginationCall: fetchData,
    manualSortBy: false,
    showColumnSort: false,
    apiQueryOptions: {
      requestPolicy: 'no-cache',
    },
  };

  const handleSave = async () => {
    setIsLoading(true);
    const formValues = form.getValues();
    const payload = [];
    Object.keys(formValues).forEach((item) => {
      const itemPayDocNbr = item.split('_')[2];
      const key = `radio_reset_${itemPayDocNbr}`;
      const selectedValue = formValues[key];

      if (selectedValue === 'Approve') {
        const selectedRow = tableData.find((data) => {
          return data.payDocNbr === itemPayDocNbr;
        });
        if (selectedRow) {
          const payloadObj = {
            atr2: 'Reissue Approved',
            payDocNbr: selectedRow.payDocNbr,
            modById: selectedRow.PayeId,
            rbtPayId: selectedRow.rbtPayId,
          };
          payload.push(payloadObj);
        }
      }
    });

    if (payload.length > 0) {
      try {
        const res = await axios.post(
          `${BASE_URL}checkReissue/updateApprove`,
          payload,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setReloadData(true);
        setTableCount(tableCount - payload.length);
        setCheckValue({});
        setTimeout(() => {
          setReloadData(false);
        }, 2000);
        if (res.data.success === 'approve successfully') {
          alert('Check reissue approval/reject saved successfully.');
        } else {
          console.log('error');
        }
        setResponse(res.data.success);
      } catch (error) {
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      }
    } else {
      alert('No checks selected');
    }
    setIsLoading(false);
  };

  const onSubmit = () => {};
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Check Number',
        accessor: 'payDocNbr',
        disableSortBy: true,
        align: 'center',
      },
      {
        Header: 'Policy Type',
        accessor: 'atr5',
        disableSortBy: true,
        align: 'center',
      },
      {
        Header: 'Payee Name',
        accessor: 'name',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
      },
      {
        Header: 'Address',
        accessor: 'address',
        disableSortBy: true,
        align: 'center',
        width: 300,
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
      },
      {
        Header: 'Check Status',
        accessor: 'payStsCd',
        disableSortBy: true,
        align: 'center',
      },

      {
        Header: 'Post Mark Date',
        accessor: 'payPstMrkDt',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => {
          if (value === '' || value === null || value === undefined) {
            return '';
          }
          const payData = value ? value.substring(0, value.indexOf('T')) : '';
          const modifiedDate = payData.replaceAll('-', '/');
          const splitDate = modifiedDate.split('/');
          const modifiedSplitDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
          return modifiedSplitDate;
        },
      },

      {
        Header: 'Reissue Status',
        accessor: 'col6',
        disableSortBy: true,
        width: 250,
        align: 'center',

        Cell: ({ row }) => {
          return (
            <Row
              row={row}
              reset={reset}
              setReset={setReset}
              setCheckValue={setCheckValue}
              setCheckStatus={setCheckStatus}
              setValue={setValue}
              setIsChecked={setIsChecked}
            />
          );
        },
      },
    ];
  }, []);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <FormProvider state={form} onSubmit={onSubmit}>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ margin: '0px', background: '$headerBackgroundColor' }}
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
          >
            <HeaderDiv>
              <Header />
            </HeaderDiv>
          </Grid.Col>
        </Grid>
        <BodyContainer>
          <Grid css={{ margin: '0px' }}>
            <Grid.Col
              css={{ padding: '0px' }}
              span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
            >
              <MediaQuery largerThan="$md">
                <Hamburger />
              </MediaQuery>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Check Reissue Results"
                  InfoContent={PendingReissueApprovalIcon}
                />
                <Grid>
                  <Grid.Col span={12} css={{ paddingTop: '24px' }}>
                    <Grid.Col span={12}>
                      <Text css={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {CHECK_REISSUE_STATUS}:
                        <Text
                          css={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            paddingLeft: '8px',
                          }}
                        >
                          {REISSUE_REJECTED}
                        </Text>
                      </Text>
                    </Grid.Col>
                    {isLoading ? (
                      <MlreLoader />
                    ) : (
                      <MlreDataTable
                        // data={tableData}
                        columns={columns}
                        config={config}
                        reloadData={reloadData}
                      />
                    )}
                  </Grid.Col>
                </Grid>
                <Layout.Group css={{ justifyContent: 'center' }}>
                  <Button
                    onClick={handleSave}
                    css={{ borderRadius: '4px' }}
                    isDisabled={isButtonDisabled}
                  >
                    {SAVE}
                  </Button>
                  <Button
                    onClick={() => {
                      setResetStatus(false);
                      setReset(true);
                      setValue('reject');
                      setReloadData(true);
                      form.reset();
                      setCheckStatus('');

                      setTimeout(() => {
                        setReloadData(false);
                      }, 500);
                      setIsChecked(true);
                      form.reset();
                    }}
                    css={{ borderRadius: '4px' }}
                    isDisabled={isButtonDisabled}
                  >
                    {RESET}
                  </Button>
                </Layout.Group>
              </BodyPageContainer>
            </Grid.Col>
          </Grid>
        </BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ background: '$pageFooter-color-background-root' }}
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
          >
            <Footer />
          </Grid.Col>
        </Grid>
      </FormProvider>
    </div>
  );
};
export default withAuth(CheckPendingReject);
