import React, { useState, useEffect } from 'react';
import { Link } from '@abyss/web/ui/Link';

import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';

import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import axios from 'axios';
import { BASE_URL } from '@src/components/utils/endpoints';
import env from '../../../env.json';

const Reports = () => {
  const [reportUrl, setReportUrl] = useState([]);
  const url = env.Report_Url[0];

  const fetchReportUrl = async () => {
    const payload = {
      attributeCategory: url,
    };

    try {
      const response = await axios.post(`${BASE_URL}home/url`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });
      setReportUrl(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReportUrl();
  }, []);

  const handleRedirect = (url) => {
    try {
      const validUrl = new URL(url);

      if (validUrl) {
        window.open(url, '_blank');
      }
    } catch {
      const urlRegex =
        /^((http|https):\/\/)?(www\.)?([a-zA-Z0-9]+\.[a-z]{2,6})(?=\/|\?|$)/i;
      if (urlRegex.test(url)) {
        window.open(`//${url}`, '_blank');
      } else {
        alert('Invalid URL: Please correct the URL in configuration.');
      }
    }
  };

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <Grid css={{ paddingTop: '150px', paddingLeft: '8px' }}>
                <Link
                  href="#"
                  onClick={() => {
                    return handleRedirect(reportUrl[0]?.paramVal);
                  }}
                  css={{
                    textDecoration: 'underline',
                  }}
                  hideIcon
                >
                  {reportUrl[0]?.atr20}
                </Link>
              </Grid>
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(Reports);
