export const REBATE_YEAR: string = 'Rebate Year';
export const SOURCE_SYSTEM: string = 'Source System';
export const POLICY_TYPE: string = 'Policy Type';
export const POLICY: string = 'Policy#';
export const PLAT_CUST: string = 'Plat. Cust#';
export const CUSTOMER_ID: string = 'Customer ID';
export const CANCEL_DATE: string = 'Cancel Date';
export const FRANCHISE_CODE: string = 'Franchise Code';
export const HOLD: string = 'Hold Payment for policy';
export const SEARCH: string = 'Search';
export const SAVE = 'Save';
export const RESET = 'Reset';
export const CANCEL = 'Cancel';
export const SUBMIT = 'Submit';

interface CustomerFormData {
  rebateYear: string;
  sourceSystem: string;
  platCust: string;
  customerId: string;
  policy: string;
  cancelDate: string;
  franchiseCode: string;
  policyType: string;
  holdStatus: string;
}
export const CustomerFormData: CustomerFormData = {
  rebateYear: 'Rebate Year',
  sourceSystem: 'Source System',
  platCust: 'Plat. Cust#',
  customerId: 'Customer ID',
  policy: 'Policy#',
  cancelDate: 'Cancel Date',
  franchiseCode: 'Franchise Code ',
  policyType: 'Policy Type',
  holdStatus: ' Hold Payment for policy',
};
