import React from 'react';

export const CustomerPolicyIcon = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen shows the policy details, associated policy address and
          HCR indicators for the selected policy.
        </li>
        <li>
          The Policy demographics can be updated by clicking on the 'Update
          Address" button for Cust Type ATPUCE.
        </li>
        <li>
          The Erisa category and Written assurance when updated, will be
          propagated down to all the child policies.
        </li>
        <li>
          By clicking the Broker button, user will be able to view Broker
          related details.
        </li>
        <li>
          By clicking the policy number, user can be able to view the list of
          subscribers associated with the policy.
        </li>
        <li>
          By clicking on the Customer Id user can be able to view the list of
          policy associated with the customer.
        </li>
        <li>
          By clicking Payment Status button, Check details like check number,
          amount and date will be displayed.
        </li>
        <li>
          User can view the Notes linked to Policy by clicking on "Notes"
          button.
        </li>
      </ul>
    </div>
  );
};

export const CustomerSearchIcon = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen enables the user to search the customer details by
          providing Policy Number and Source system as input.
        </li>
      </ul>
    </div>
  );
};

export const CustomerPolicyListIcon = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen shows the list of policies associated with the customer
          based on the Customer Id selected in Customer Policy screen.
        </li>
        <li>
          By clicking on Update Detail button, Customer name and Address could
          be edited. This is enabled for Cust Type ATPUC only.
        </li>
        <li>
          Customer Name and address fields would be blank for Cust Type ï¿½Eï¿½.
        </li>
        <li>
          User can view the history of Customer addresses by clicking on the
          History icon beside the Address Field.
        </li>
        <li>
          User can view the Notes linked to Customer by clicking on "Notes"
          button.
        </li>
      </ul>
    </div>
  );
};
