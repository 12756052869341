import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { MlreDataTable, MlreLoader } from '@src/common/MlreAbyssComponent';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';

import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import axios from 'axios';
import { Text } from '@abyss/web/ui/Text';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import useAuth from '@src/auth/useAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '../utils/endpoints';
import {
  CHECK_REISSUE_STATUS,
  PENDING_REDISTRIBUTE_APPROVAL,
  RESET,
  SAVE,
} from './CheckReissueContant';
import { PendingApprovalResultIcon } from './CheckReissueHelpIcon';
import env from '../../../env.json';

const CheckPendingRedistributeApprove = () => {
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const { userRolesGroups, msid } = useAuth();
  const { navigate } = useRouter();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [slectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [validatePaymentData, setValidatePaymentData] = useState();
  const { getLocation } = useRouter();
  const { state } = getLocation();

  const [breadItem, setBreadItem] = useState([
    { title: 'Check Reissue Approval Search', href: '/check-reissue-approval' },
    {
      title: 'Check Redistribute Approval Results',
      href: '/pending-redistribute-approval',
    },
  ]);

  const form = useForm();
  const onSubmit = () => {};

  useEffect(() => {
    if (slectedRow) {
      const selectedRow = selectedRows.filter((row) => {
        return row?.rbtPayId !== slectedRow?.rbtPayId;
      });
      selectedRow.push(slectedRow);
      setSelectedRows(selectedRow);
    }
  }, [slectedRow]);

  useEffect(() => {
    const hasUpdateAccess = env.Pending_Redistribute_Approval.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups, env]);

  const fetchData = async (
    page,
    pageSize,
    sortBy,
    globalFilter,
    columnFilters
  ) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}checkReissue/search-check-with-params`,
        {
          atr2: 'Pending Redistribute Approval',
          pageNumber: page,
          pageSize,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setTableData(data);
      return {
        results: data,
        count: tableCount,
      };
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
    return {
      results: [],
      count: 0,
    };
  };
  const fetchCount = async () => {
    const { data, error } = await axios.post(
      `${BASE_URL}checkReissue/count`,
      {
        atr2: 'Pending Redistribute Approval',
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (!error && data.length > 0) {
      setTableCount(data[0].count);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCount();
  }, []);

  const config = {
    pageSizeDefault: 10,
    uniqueStorageId: `server-approve`,
    apiPaginationCall: fetchData,
    manualSortBy: false,
    showColumnSort: false,
    apiQueryOptions: {
      requestPolicy: 'no-cache',
    },
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Policy Number',
        accessor: 'policyNum',
        disableSortBy: true,
        width: 80,
      },

      {
        Header: 'Policy Name',
        accessor: 'policyName',
        disableSortBy: true,
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
      },

      {
        Header: 'Redistribute Status',
        accessor: 'col3',
        disableSortBy: true,

        Cell: ({ row }) => {
          const [value, setValue] = useState('');
          const isDisabled = row.original.num;
          return (
            <RadioGroup
              label="Radio Sandbox"
              display="row"
              hideLabel
              isDisabled={isDisabled}
              onChange={(e) => {
                const payload = {
                  atr2: 'Pending Redistribute Approval',
                  atr12: '',
                  payDocNbr: row.original.payDocNbr,
                  modById: msid,
                  rbtPayId: row.original.rbtPayId,
                  policyNum: row.original.policyNum,
                  rbtReportYr: row.original.rbtYear,
                  srcSysCd: row.original.srcSysCd,
                  modifiedDateTime: row.original.modifiedDateTime,
                  atr5: row.original.atr5,
                };
                if (e.target.value === 'Approval') {
                  payload.atr12 = '4';
                  navigate('/check-redistribe-approve', {
                    state: { data: payload, breadcrums: breadItem },
                  });
                } else {
                  payload.atr12 = '5';
                  setSelectedRow(payload);
                }
                return setValue(e.target.value);
              }}
              value={value}
            >
              <RadioGroup.Radio
                isDisabled={isButtonDisabled}
                label={<span style={{ color: '#000000' }}>Approval</span>}
                value="Approval"
              />
              <RadioGroup.Radio
                isDisabled={isButtonDisabled}
                label={<span style={{ color: '#000000' }}>Reject</span>}
                value="Reject"
              />
            </RadioGroup>
          );
        },
      },
    ];
  }, [isButtonDisabled]);
  const validatePayment = async () => {
    try {
      const payload = {
        payDocNum: selectedRows[0].payDocNbr,
        modifiedDateTime: selectedRows[0].modifiedDateTime,
      };
      const result = await axios.post(`${BASE_URL}payment/validate`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (result?.data) {
        setValidatePaymentData(result?.data);
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleSave = () => {
    if (!selectedRows.length) {
      alert('No reject request selected for check redistribution.');
      return false;
    }
    const payload = selectedRows;
    axios
      .post(`${BASE_URL}checkReissue/updateApproveRedistribute`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        alert('Check redistribution rejected');
        setReloadData(true);
        setTableCount(tableCount - payload.length);
        setSelectedRows([]);
        setTimeout(() => {
          setReloadData(false);
        }, 1000);
      })
      .catch((error) => {
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      });
  };

  const handleValidatedSubmit = async () => {
    if (!selectedRows.length) {
      alert('No reject request selected for check redistribution.');
      return;
    }
    const validationResult = await validatePayment();
    if (validationResult === 'validation passed') {
      handleSave();
    } else {
      alert('Failed: Current data has been modified by other user');
    }
  };

  return (
    <div style={{ maxWidth: '100vw' }}>
      <FormProvider state={form} onSubmit={onSubmit}>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ margin: '0px', background: '$headerBackgroundColor' }}
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
          >
            <HeaderDiv>
              <Header />
            </HeaderDiv>
          </Grid.Col>
        </Grid>
        <BodyContainer>
          <Grid css={{ margin: '0px' }}>
            <Grid.Col
              css={{ padding: '0px' }}
              span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
            >
              <MediaQuery largerThan="$md">
                <Hamburger />
              </MediaQuery>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItem}
                  infoTitle="Check Redistribute Approval Results"
                  InfoContent={PendingApprovalResultIcon}
                />
                <Grid>
                  <Grid.Col span={12} css={{ paddingTop: '32px' }}>
                    <Grid.Col span={12}>
                      <Text css={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {CHECK_REISSUE_STATUS}:
                        <Text
                          css={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            paddingLeft: '8px',
                          }}
                        >
                          {PENDING_REDISTRIBUTE_APPROVAL}
                        </Text>
                      </Text>
                    </Grid.Col>
                    {isLoading ? (
                      <MlreLoader />
                    ) : (
                      <MlreDataTable
                        columns={columns}
                        config={config}
                        reloadData={reloadData}
                      />
                    )}
                  </Grid.Col>
                </Grid>
                <Layout.Group css={{ justifyContent: 'center' }}>
                  <Button
                    onClick={handleValidatedSubmit}
                    css={{ borderRadius: '4px' }}
                    isDisabled={isButtonDisabled}
                  >
                    {SAVE}
                  </Button>
                  <Button
                    onClick={() => {
                      setReloadData(true);
                      setTimeout(() => {
                        setReloadData(false);
                      }, 1000);
                      setSelectedRows([]);
                    }}
                    css={{ borderRadius: '4px' }}
                    isDisabled={isButtonDisabled}
                  >
                    {RESET}
                  </Button>
                </Layout.Group>
              </BodyPageContainer>
            </Grid.Col>
          </Grid>
        </BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ background: '$pageFooter-color-background-root' }}
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
          >
            <Footer />
          </Grid.Col>
        </Grid>
      </FormProvider>
    </div>
  );
};
export default withAuth(CheckPendingRedistributeApprove);
