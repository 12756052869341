import React, { useState, useEffect } from 'react';

import { Text } from '@abyss/web/ui/Text';
import { Grid } from '@abyss/web/ui/Grid';
import useAuth from 'src/auth/useAuth';
import { useMsal } from '@azure/msal-react';
import { Link } from '@abyss/web/ui/Link';
import { Heading } from '@abyss/web/ui/Heading';
import mlreLogo from '../assets/headImageMlre.png';
import devLogo from '../assets/uhcLogo.png';

export const Header = () => {
  const { userName } = useAuth();
  const { instance } = useMsal();
  const currentDate = new Date();
  const [currentTime, setCurrentTime] = useState(new Date());

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 1000);

  //   return () => {
  //     return clearInterval(intervalId);
  //   };
  // }, []);

  // Set the desired time zone (replace 'America/Chicago' with your desired time zone)
  const desiredTimeZone = 'America/Chicago';
  const formattedTime = currentTime.toLocaleString('en-US', {
    timeZone: desiredTimeZone,
    weekday: 'short',
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  });
  const newFormattedTime = formattedTime.replaceAll(',', '');

  const handleSignOut = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: false,
    });
  };

  return (
    <Grid>
      <Grid.Col span={{ xs: 12, md: 4, lg: 2, xl: 2 }}>
        {/* <PageHeaderPrimitives.Brandmark /> */}
        {/* <img src={headerImg.src} alt="Header Gradient " /> */}

        <img style={{ width: '300px' }} src={devLogo.src} alt="UHCHome Logo" />
      </Grid.Col>
      <Grid.Col
        span={{ xs: 12, md: 4, lg: 8, xl: 8 }}
        css={{ paddingTop: '10px' }}
      >
        {/* <img src={mlreLogo.src} alt="mlrelogo" /> */}
        <Heading textAlign="center" display={4}>
          MEDICAL LOSS RATIO REBATE TOOL
        </Heading>
      </Grid.Col>
      <Grid.Col
        span={{ xs: 12, md: 4, lg: 2, xl: 2 }}
        css={{ textAlign: 'end' }}
      >
        <Text color="$interactive1" fontWeight="bold">
          Welcome {userName}
        </Text>
        <br />
        <Text color="$interactive1" fontWeight="bold">
          {newFormattedTime} CDT
        </Text>
        <br />
        <Link
          color="$interactive1"
          colorHover="$interactive1"
          fontWeight="bold"
          onClick={handleSignOut}
        >
          Sign Out
        </Link>
      </Grid.Col>
    </Grid>
  );
};
