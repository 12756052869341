/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Flex } from '@abyss/web/ui/Flex';
import { Label } from '@abyss/web/ui/Label';
import { Text } from '@abyss/web/ui/Text';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';

import { useRouter } from '@abyss/web/hooks/useRouter';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import withAuth from '@src/auth/withAuth';
import useAuth from '@src/auth/useAuth';
import axios from 'axios';
import { SubHeader } from '@src/common/components';
import { CustomerSearchIcon } from '@src/components/customer/CustomerHelpIcons';
import { json } from 'stream/consumers';
import styles from '../../styles/styles.module.css';

import {
  FormOuterContainer,
  FormContainer,
  mediumSize,
} from '../../common/utils/StyleConstant';
import { subscriberCLabels } from './constants';
import { BASE_URL } from '../utils/endpoints';
import { SubscriberCloneSearchIcon } from './SubscriberHelpIcon';

const SubscriberCloneSearch = () => {
  const initialRadioValue =
    localStorage.getItem('radioValue') || subscriberCLabels.REVEN_NOSUB_VALUE;
  const [radioValue, setRadioValue] = useState(initialRadioValue);
  const [value, setValue] = React.useState('alpine');
  const { navigate } = useRouter();
  const [isClonedPolicy, setIsClonedPolicy] = useState(
    radioValue === subscriberCLabels.CLONE_BY_POLICY_VALUE
  );

  const [breadItem, setBreadItem] = useState([
    { title: 'Subscriber Clone Search', href: '/subscriber-clone/' },
  ]);
  const [isDropDownLoading, setIsDropDownLoading] = useState(true);
  const [rebateYear, setRebateYear] = useState('');
  const [runType, setRunType] = useState('');
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      srcSysCd: '',
      lglEntyId: '',
      mlrestCd: '',
      mlrecsSz: '',
    },
  });

  const reSetState = {
    srcSysCustContrId: [],
    srcSysCd: [],
    lglEntyId: [],
    mlrestCd: [],
    mlrecsSz: [],
  };
  const [selectBoxData, setSelectBoxData] = useState({
    srcSysCustContrId: [],
    srcSysCd: [],
    lglEntyId: [],
    mlrestCd: [],
    mlrecsSz: [],
  });
  const [selectPolicyBoxData, setSelectPolicyBoxData] = useState(reSetState);
  const isMedium = useMediaQuery(mediumSize);
  const [formInitialized, setFormInitialized] = useState(false);

  const fetchSelectBoxRows = async (payload) => {
    try {
      const { data, error, message } = await axios.post(
        `${BASE_URL}subscriber-clone/dropdowns`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const selectBoxRows = [];
      for (const item in data) {
        const selectBoxRowsData = [];
        data[item]?.map((itemRow) => {
          selectBoxRowsData.push({ value: itemRow, label: itemRow });
        });
        selectBoxRows[item] = selectBoxRowsData;
      }

      if (isClonedPolicy) {
        if (payload.srcSysCd) {
          selectBoxRows.srcSysCd = selectPolicyBoxData.srcSysCd;
        }
        setSelectPolicyBoxData({
          ...selectBoxRows,
          lglEntyId: [],
          srcSysCustContrId: [],
          mlrestCd: [],
          mlrecsSz: [],
        });
      } else {
        if (payload.srcSysCd) {
          selectBoxRows.srcSysCd = selectBoxData.srcSysCd;
        }
        setSelectBoxData(selectBoxRows);
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    } finally {
      setIsDropDownLoading(false);
    }
  };

  const getCurrentYear = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}subscriber-clone/getcurrentYear`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setRebateYear(data.year);
      setRunType(data.type);
      return data.year;
    } catch (error) {
      console.log('Error', error);
    }
  };

  const fetchPolicySelectBoxRows = async (payload) => {
    try {
      const { data, error, message } = await axios.post(
        `${BASE_URL}subscriber-clone/dropdowns`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const selectBoxRows = [];
      for (const item in data) {
        const selectBoxRowsData = [];
        data[item]?.map((itemRow) => {
          selectBoxRowsData.push({ value: itemRow, label: itemRow });
        });
        selectBoxRows[item] = selectBoxRowsData;
      }
      return selectBoxRows;
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    } finally {
      setIsDropDownLoading(false);
    }
  };

  const getDropDownDefaultValues = async (parcedData) => {
    const currentYear = await getCurrentYear();

    const sourceSystemResponse = await fetchPolicySelectBoxRows({
      rbtRptYr: currentYear,
      policy: isClonedPolicy,
    });

    const legalEntityResponse = await fetchPolicySelectBoxRows({
      rbtRptYr: currentYear,
      policy: isClonedPolicy,
      srcSysCd: parcedData?.srcSysCd,
    });

    const mlrestCdResponse = await fetchPolicySelectBoxRows({
      rbtRptYr: currentYear,
      policy: isClonedPolicy,
      srcSysCd: parcedData?.srcSysCd,
      legalEntity: parcedData?.lglEntyId,
    });

    const mlreSitusStatusResponse = await fetchPolicySelectBoxRows({
      rbtRptYr: currentYear,
      policy: isClonedPolicy,
      srcSysCd: parcedData?.srcSysCd,
      mlrestCd: parcedData?.mlrecsSz,
      legalEntity: parcedData?.lglEntyId,
    });

    const { srcSysCd } = sourceSystemResponse;
    const { lglEntyId } = legalEntityResponse;
    const { mlrestCd } = mlrestCdResponse;
    const { mlrecsSz } = mlreSitusStatusResponse;

    setSelectPolicyBoxData({
      ...selectPolicyBoxData,
      srcSysCd,
      lglEntyId,
      mlrestCd,
      mlrecsSz,
    });
  };

  const onSubmit = async (data) => {
    setIsSearchDisabled(true);
    localStorage.setItem(
      'subscriberCloneFormData',
      JSON.stringify(form.getValues())
    );
    localStorage.setItem('radioValue', radioValue);
    const searchData = {
      rebateYear,
      ...data,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}subscriber-clone/subclonesearch`,
        searchData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.data || response.data.length === 0) {
        if (radioValue === subscriberCLabels.REVEN_NOSUB_VALUE) {
          alert('Revenue No Subscriber AOG not found');
          setIsSearchDisabled(false);
          setLoading(false);
        } else if (radioValue === subscriberCLabels.CLONE_BY_POLICY_VALUE) {
          alert('Target or Source AOG not found');
          setIsSearchDisabled(false);
          setLoading(false);
        }
        return;
      }

      setIsSearchDisabled(false);
      setLoading(false);

      if (radioValue === subscriberCLabels.REVEN_NOSUB_VALUE) {
        navigate('/subscriber-clone-result', {
          state: {
            searchData,
            breadItem,
            radioValue,
            response: response.data,
          },
        });
      } else {
        navigate('/ssaog', {
          state: {
            searchData,
            breadItem,
            radioValue,
            response: response.data,
          },
        });
      }
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (!form.formState.isValid) {
      setLoading(false);
    }
  }, [form.formState]);

  const handleSourceSystemChange = async () => {
    const { getValues } = form;
    if (isClonedPolicy) {
      const response = await fetchPolicySelectBoxRows({
        rbtRptYr: rebateYear,
        srcSysCd: getValues()?.srcSysCd,
        policy: isClonedPolicy,
      });
      const { lglEntyId } = response;
      setSelectPolicyBoxData({
        ...selectPolicyBoxData,
        lglEntyId,
        srcSysCustContrId: [],
        mlrestCd: [],
        mlrecsSz: [],
      });
    } else {
      fetchSelectBoxRows({
        rbtRptYr: rebateYear,
        policy: isClonedPolicy,
      });
    }
  };

  const handleLegalEntityChange = async () => {
    if (isClonedPolicy) {
      const { getValues } = form;
      const response = await fetchPolicySelectBoxRows({
        rbtRptYr: rebateYear,
        srcSysCd: getValues()?.srcSysCd,
        legalEntity: getValues()?.lglEntyId,
        policy: isClonedPolicy,
      });
      const { mlrestCd } = response;
      setSelectPolicyBoxData({
        ...selectPolicyBoxData,
        mlrestCd,
        srcSysCustContrId: [],
        mlrecsSz: [],
      });
      form.setValue('mlrecsSz', '');
    }
  };

  const handleCaseSize = async () => {
    if (isClonedPolicy) {
      const { getValues } = form;
      const response = await fetchPolicySelectBoxRows({
        rbtRptYr: rebateYear,
        srcSysCd: getValues()?.srcSysCd,
        legalEntity: getValues()?.lglEntyId,
        mlrestCd: getValues()?.mlrecsSz,
        policy: isClonedPolicy,
      });

      const { mlrecsSz } = response;

      setSelectPolicyBoxData({
        ...selectPolicyBoxData,
        mlrecsSz,
        srcSysCustContrId: [],
      });
      // form.setValue('mlrestcd', '');
    }
  };

  const savedSubscriberCloneFormData = localStorage.getItem(
    'subscriberCloneFormData'
  );

  useEffect(() => {
    const parcedData = JSON.parse(savedSubscriberCloneFormData);

    if (parcedData?.length !== 0) {
      getDropDownDefaultValues(parcedData);
    }
  }, [savedSubscriberCloneFormData]);

  useEffect(() => {
    form.reset();
    setSelectPolicyBoxData(reSetState);
    setIsClonedPolicy(radioValue === subscriberCLabels.CLONE_BY_POLICY_VALUE);
  }, [radioValue]);

  useEffect(() => {
    const { getValues } = form;

    fetchSelectBoxRows({
      rbtRptYr: rebateYear,
      policy: isClonedPolicy,
    });
  }, [formInitialized, isClonedPolicy]);

  useEffect(() => {
    localStorage.setItem('radioValue', radioValue);
  }, [radioValue]);

  useEffect(() => {
    const savedSubscriberCloneFormData = localStorage.getItem(
      'subscriberCloneFormData'
    );
    let parsedData = {};

    if (savedSubscriberCloneFormData) {
      parsedData = JSON.parse(savedSubscriberCloneFormData);
    }

    const fetchDataAndSetForm = async () => {
      await fetchSelectBoxRows({
        policy: isClonedPolicy,
        rbtRptYr: rebateYear,
      });

      if (savedSubscriberCloneFormData) {
        Object.keys(parsedData).forEach((key) => {
          form.setValue(key, parsedData[key]);
        });
      }

      setFormInitialized(true);
    };

    fetchDataAndSetForm();
  }, []);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItem}
                infoTitle="Subscriber Clone Search"
                InfoContent={SubscriberCloneSearchIcon}
              />
              <FormProvider state={form} onSubmit={onSubmit}>
                <FormOuterContainer>
                  <FormContainer medium={isMedium}>
                    <Flex style={{ marginTop: '10px' }}>
                      <Label size="md">
                        {subscriberCLabels.REBATE_REPORT_YEAR}
                      </Label>
                      <Text
                        size="md"
                        css={{ marginLeft: '25px', marginRight: '25px' }}
                      >
                        :{rebateYear}
                      </Text>
                      <Label
                        size="md"
                        style={{ marginLeft: '25px', marginRight: '25px' }}
                      >
                        {subscriberCLabels.RUN_TYPE}
                      </Label>
                      <Text
                        size="md"
                        css={{ marginLeft: '25px', marginRight: '25px' }}
                      >
                        :{runType}
                      </Text>
                    </Flex>
                    <Flex style={{ marginTop: '20px', marginBottom: '20px' }}>
                      <RadioGroup
                        onChange={(e) => {
                          return setRadioValue(e.target.value);
                        }}
                        value={radioValue}
                        display="row"
                      >
                        <RadioGroup.Radio
                          label={subscriberCLabels.REVENUE_NO_SUBSCRIBER}
                          value={subscriberCLabels.REVEN_NOSUB_VALUE}
                        />
                        <RadioGroup.Radio
                          label={subscriberCLabels.CLONE_BY_POLICY}
                          value={subscriberCLabels.CLONE_BY_POLICY_VALUE}
                        />
                      </RadioGroup>
                    </Flex>
                    <SelectInput
                      label={subscriberCLabels.SOURCE_SYSTEM}
                      // placeholder={subscriberCLabels.SOURCE_SYSTEM}
                      placeholder="Select One"
                      model="srcSysCd"
                      onChange={handleSourceSystemChange}
                      validators={{ required: true }}
                      isLoading={isDropDownLoading}
                      options={
                        isClonedPolicy
                          ? selectPolicyBoxData?.srcSysCd?.length
                            ? selectPolicyBoxData?.srcSysCd
                            : formInitialized
                              ? [
                                {
                                  value: form.getValues()?.srcSysCd,
                                  label: form.getValues()?.srcSysCd,
                                },
                              ]
                              : []
                          : selectBoxData?.srcSysCd?.length
                            ? selectBoxData.srcSysCd
                            : formInitialized
                              ? [
                                {
                                  value: form.getValues()?.srcSysCd,
                                  label: form.getValues()?.srcSysCd,
                                },
                              ]
                              : []
                      }
                    />
                    <SelectInput
                      label={subscriberCLabels.LEGAL_ENTITY}
                      // placeholder={subscriberCLabels.LEGAL_ENTITY}
                      placeholder={
                        isClonedPolicy
                          ? selectPolicyBoxData?.lglEntyId?.length <= 0
                            ? ''
                            : 'Select One'
                          : 'ALL'
                      }
                      model="lglEntyId"
                      validators={{ required: isClonedPolicy }}
                      isLoading={isDropDownLoading}
                      onChange={handleLegalEntityChange}
                      isDisabled={
                        isClonedPolicy
                          ? selectPolicyBoxData?.lglEntyId?.length === 0
                          : false
                      }
                      options={
                        isClonedPolicy
                          ? selectPolicyBoxData.lglEntyId
                          : selectBoxData.lglEntyId
                      }
                    />
                    <SelectInput
                      label={subscriberCLabels.CASE_SIZE}
                      // placeholder={subscriberCLabels.CASE_SIZE}
                      // placeholder={isClonedPolicy ? '' : 'ALL'}
                      model="mlrecsSz"
                      validators={{ required: isClonedPolicy }}
                      isLoading={isDropDownLoading}
                      onChange={handleCaseSize}
                      isDisabled={
                        isClonedPolicy
                          ? selectPolicyBoxData?.mlrestCd?.length <= 0
                          : false
                      }
                      placeholder={
                        isClonedPolicy
                          ? selectPolicyBoxData?.mlrestCd?.length === 0
                            ? ''
                            : 'Select One'
                          : 'ALL'
                      }
                      options={
                        isClonedPolicy
                          ? selectPolicyBoxData.mlrestCd
                          : selectBoxData.mlrestCd
                      }
                    />
                    <SelectInput
                      label={subscriberCLabels.SITUS_STATE}
                      // placeholder={subscriberCLabels.SITUS_STATE}
                      // placeholder={isClonedPolicy ? '' : 'ALL'}
                      model="mlrestCd"
                      validators={{ required: isClonedPolicy }}
                      isLoading={isDropDownLoading}
                      value={value}
                      // onChange={setValue}
                      isDisabled={
                        isClonedPolicy
                          ? selectPolicyBoxData?.mlrecsSz?.length <= 0
                          : false
                      }
                      placeholder={
                        isClonedPolicy
                          ? selectPolicyBoxData?.mlrecsSz?.length === 0
                            ? ''
                            : 'Select One'
                          : 'ALL'
                      }
                      options={
                        isClonedPolicy
                          ? selectPolicyBoxData.mlrecsSz
                          : selectBoxData.mlrecsSz
                      }
                    />
                    <Grid
                      css={{
                        width: '100%',
                        padding: '10px 0px 0px 0px',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Grid.Col span={11}>
                        <TextInput
                          label={subscriberCLabels.POLICY_NUMBER}
                          model="srcSysCustContrId"
                          validators={{ required: isClonedPolicy }}
                          isDisabled={
                            isClonedPolicy
                              ? selectPolicyBoxData?.mlrecsSz?.length <= 0
                              : false
                          }
                          onChange={(e) => {
                            const upperCaseValue = e.target.value.toUpperCase();
                            form.setValue('srcSysCustContrId', upperCaseValue);
                          }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              form.setValue(
                                'srcSysCustContrId',
                                e.target.value.toUpperCase()
                              );
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid>

                    <Flex
                      direction="row"
                      justify="center"
                      className={styles.Layout}
                    >
                      <Button
                        type="submit"
                        isDisabled={isSearchDisabled}
                        isLoading={isLoading}
                        ariaLoadingLabel="Importing data"
                        onClick={() => {
                          return setLoading(!isLoading);
                        }}
                        className={styles.Button}
                      >
                        {subscriberCLabels.SEARCH}
                      </Button>
                      <Button
                        className={styles.Button}
                        onClick={() => {
                          form.reset();
                          setSelectPolicyBoxData({
                            ...selectPolicyBoxData,
                            lglEntyId: [],
                            srcSysCustContrId: [],
                            mlrestCd: [],
                            mlrecsSz: [],
                          });
                        }}
                      >
                        {subscriberCLabels.RESET}
                      </Button>
                    </Flex>
                  </FormContainer>
                </FormOuterContainer>
              </FormProvider>
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default withAuth(SubscriberCloneSearch);
