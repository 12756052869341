/* eslint-disable react/react-in-jsx-scope */
import { useForm } from '@abyss/web/hooks/useForm';
import { Text } from '@abyss/web/ui/Text';
import { Card } from '@abyss/web/ui/Card';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Flex } from '@abyss/web/ui/Flex';
import { Button } from '@abyss/web/ui/Button';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RichTextEditor } from '@abyss/web/ui/RichTextEditor';
import useAuth from '@src/auth/useAuth';
import styles from '../../styles/styles.module.css';
import { BASE_URL } from '../utils/endpoints';
import env from '../../../env.json';

export const SubscriberNote = ({
  subNoteData,
  subDetails,
  subscriberData,
  handleChangeClose,
  handleNoteSave,
}) => {
  const form = useForm({
    defaultValues: {
      usage: '',
    },
  });
  const { userRolesGroups } = useAuth();
  const [notes, setNotes] = useState('');
  const [editorKey, setEditorKey] = useState(0); // Add a key state
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);
  const [hasaNotesUpadateAccess, setHasNotesUpdateAccess] = useState(false);

  useEffect(() => {
    const notesUpdateAccess = env.Subscriber_Notes.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!notesUpdateAccess) {
      setHasNotesUpdateAccess(false);
    } else {
      setHasNotesUpdateAccess(true);
    }
  }, [userRolesGroups, env]);

  const handleClose = () => {
    handleChangeClose('');
  };

  const handleReset = () => {
    form.reset({
      usage: '', // Reset to default value
    });
    setNotes(''); // Reset notes state
    setEditorKey((prevKey) => {
      return prevKey + 1;
    }); // Change the key to force re-render
  };

  const handleSubmit = async () => {
    setSaveIsButtonDisabled(true);
    const payload = {
      rbtYear: subscriberData?.year,
      custContrSbscrId: subDetails?.custContrSbscrId,
      noteDesc: encodeURIComponent(notes.replaceAll("'", 'cqutes')).replaceAll('%', 'perct'),
      modifiedDate: subscriberData.modifiedDate,
    };

    try {
      const result = await axios
        .post(`${BASE_URL}subscriber/notes/save`, payload, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data) {
            alert(response.data);
            handleNoteSave();
            setNotes('');
            handleReset();
            setSaveIsButtonDisabled(false);
            // handleClose();
          }
        });
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
      setSaveIsButtonDisabled(false);
    }
  };

  return (
    <React.Fragment>
      <Grid>
        {hasaNotesUpadateAccess ? (
          <Grid.Col span={{ xs: 12, md: 6 }}>
            <Grid.Col span={12} css={{ textAlign: 'center' }}>
              <Text color="$interactive1" fontWeight="bold">
                Enter Note
              </Text>
            </Grid.Col>

            <FormProvider state={form}>
              <RichTextEditor
                key={editorKey} // Use the key prop to force re-render
                value={notes}
                label=""
                format="html"
                hideLabel
                onChange={(e) => {
                  return setNotes(e);
                }}
              >
                <RichTextEditor.Toolbar>
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Undo />
                    <RichTextEditor.Redo />
                  </RichTextEditor.ControlsGroup>
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                  </RichTextEditor.ControlsGroup>
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Lists />
                    <RichTextEditor.Headings />
                  </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>
              </RichTextEditor>
              <Flex direction="row" justify="center" className={styles.Layout}>
                <Button
                  type="submit"
                  className={styles.Button}
                  onClick={handleSubmit}
                  isDisabled={isSaveButtonDisabled}
                >
                  Save
                </Button>

                <Button onClick={handleReset} className={styles.Button}>
                  Clear
                </Button>
                <Button className={styles.Button} onClick={handleClose}>
                  Close
                </Button>
              </Flex>
            </FormProvider>
          </Grid.Col>
        ) : (
          <Grid.Col span={12} /> // Full width when access is false
        )}

        <Grid.Col
          span={hasaNotesUpadateAccess ? { xs: 12, md: 6 } : 8}
          css={{ margin: '0 auto' }}
        >
          <Grid.Col span={12} css={{ textAlign: 'center' }}>
            <Text color="$interactive1" fontWeight="bold">
              Notes
            </Text>
          </Grid.Col>
          <Card
            css={{
              textAlign: 'left',
              padding: '5px 15px',
              height: '270px',
              overflowY: 'scroll',
              ul: {
                listStyleType: 'initial',
                paddingLeft: '10px',
              },
              ol: {
                listStyleType: 'decimal',
                paddingLeft: '10px',
              },
            }}
          >
            {subNoteData &&
              subNoteData.map((item) => {
                return (
                  <div
                    key={item.noteId}
                    dangerouslySetInnerHTML={{
                      __html: item.noteDesc,
                    }}
                  />
                );
              })}
          </Card>
          {!hasaNotesUpadateAccess && (
            <Flex direction="row" justify="center" className={styles.Layout}>
              <Button className={styles.Button} onClick={handleClose}>
                Close
              </Button>
            </Flex>
          )}
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
