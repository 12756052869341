/* eslint-disable no-alert */
import { Grid } from '@abyss/web/ui/Grid';
import React, { useState, useEffect } from 'react';
import { Text } from '@abyss/web/ui/Text';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import { CustomerHoldStatusModal } from './CustomerModal';
import { BASE_URL } from '../utils/endpoints';
import env from '../../../env.json';

export const CustomerDataLabel = ({ searchLabel, SearchData }) => {
  const [isChecked, setChecked] = useState(false);
  const [holdStatusData, setHoldStatusData] = useState([]);
  const [holdData, setHoldData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const { userRolesGroups, msid } = useAuth();

  const handleUpdate = async () => {
    const payload = {
      mstrCustContrHldInd: null,
      rsnForHldDesc: null,
      custContrId: SearchData[0]?.custContrId,
      modifiedDate: SearchData[0]?.modifiedDate
    };

    const result = await axios
      .post(`${BASE_URL}customer/hold-payment-status`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response) {
          console.log(response.data);
          alert(response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    try {
      axios
        .post(
          `${BASE_URL}customer/getholddata`,
          {
            srcSystemCd: SearchData[0]?.srcSystemCd,
            srcSysContrId: SearchData[0]?.srcSystemContrId,
            rbtReportYr: SearchData[0]?.rbtReportYr,
            atr5: SearchData[0]?.atr5,
            modDttm: SearchData[0]?.modDttm,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          console.log('hold data', response.data);
          setHoldData(response.data);
          const responseData = Array.isArray(response.data)
            ? response.data
            : [];
          let atr1Values = responseData?.map((item) => {
            return item?.atr1;
          });

          if (atr1Values === null || atr1Values === undefined) {
            atr1Values = '';
          }
          const hasY = atr1Values.includes('Y');
          if (hasY) {
            console.log('atr1value ', atr1Values);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, [SearchData]);

  const handleCheck = (e) => {
    const isCheckedNow = e.target.checked;
    if (!isCheckedNow && isChecked) {
      const confirmed = window.confirm(
        'Are you sure you want to unhold payment for this policy?'
      );
      if (confirmed) {
        handleUpdate();
        setChecked(false);
        setOpenSearchModal(false);
      } else {
        setChecked(true);
      }
    } else if (isCheckedNow) {
      const atr1Values =
        holdData?.map((item) => {
          return item?.atr1;
        }) || '';

      const hasY = atr1Values.includes('Y');
      console.log('hasY ', hasY);
      if (!hasY) {
        console.log('atr1value ', atr1Values);
        setIsButtonDisabled(true);
      } else {
        const confirmed = window.confirm(
          'Are you sure you want to hold payment for this policy?'
        );
        if (confirmed) {
          if (holdData[0]?.count > 1) {
            const secondConfirmed = window.confirm(
              'Policy selected is linked with Multiple Aggregation groups. Do you still want to hold the payment?'
            );
            if (secondConfirmed) {
              setChecked(true);
              setOpenSearchModal(true);
            } else {
              setChecked(false); // Reset checkbox state if second confirmation is canceled
            }
          } else {
            setChecked(true);
            setOpenSearchModal(true);
          }
        } else {
          setChecked(false); // Reset checkbox state if first confirmation is canceled
        }
      }
    } else {
      setChecked(false);
      setOpenSearchModal(false);
    }
  };

  useEffect(() => {
    const hasUpdateAccess = env.Customer_Hold_Payment_for_Policy.some(
      (role) => {
        return userRolesGroups.includes(role);
      }
    );

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups, env.Customer_Hold_Payment_for_Policy, msid]);

  const handleCloseModal = () => {
    setChecked(false);
    setOpenSearchModal(false);
  };

  useEffect(() => {
    if (SearchData) {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            `${BASE_URL}customer/get-hold-list`,
            {
              custContrId: SearchData[0]?.custContrId,
              // id: '1362480',
            },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setHoldStatusData(response.data);
          const holdStatus = response.data[0]?.mstrCustContrHldInd;
          if (holdStatus === 'Y' || holdStatus !== null) {
            setChecked(true);
            // setIsButtonDisabled(false);
          } else {
            setChecked(false);
          }
        } catch (error) {
          setHoldStatusData([]);
          console.log(error);
        }
      };
      fetchData();
    }
  }, [SearchData]);

  return (
    <Grid css={{ padding: '20px 30px' }}>
      {searchLabel.map((item) => {
        return (
          <React.Fragment>
            <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 8, md: 2 }}>
              <Text color="#000" fontWeight="bold">
                {item.fieldName}
              </Text>
            </Grid.Col>
            <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 4, md: 2 }}>
              <Text fontWeight="bold" color="#000">
                : {item.value}
              </Text>
            </Grid.Col>
          </React.Fragment>
        );
      })}
      <Grid css={{ paddingTop: '32px', paddingLeft: '32px' }}>
        <Checkbox
          label="Hold Payment for Policy"
          isChecked={isChecked}
          isDisabled={isButtonDisabled}
          onChange={(e) => {
            return handleCheck(e);
          }}
          css={{
            'abyss-checkbox-label': {
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#000',
            },
          }}
        />
      </Grid>
      <CustomerHoldStatusModal
        isOpen={openSearchModal}
        onClose={() => {
          setOpenSearchModal(false);
        }}
        SearchData={SearchData}
        holdStatus={holdStatusData}
        msid={msid}
      />
    </Grid>
  );
};
