import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import {
  HelpContent,
  HelpContentResult,
} from '@src/components/AggregationOperationGroup/utils';
import { AggregationOprationDataTable } from '@src/components/AggregationOperationGroup';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '../../common/components';

const AggregationGroupOpration = () => {
  const { getLocation } = useRouter();
  const { state } = getLocation();
  const breadcrums = state?.breadcrums;
  const dataCount = state?.dataCount;
  const [breadItem, setBreadItem] = useState(breadcrums || []);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              {state?.payload ? (
                <React.Fragment>
                  <SubHeader
                    breadItem={[
                      ...breadItem,
                      {
                        title: 'AOG Search Result',
                        href: '/aggregation-operation-group#table/',
                      },
                    ]}
                    infoTitle="Aggregation Operational Group Result"
                    InfoContent={HelpContentResult}
                  />
                  <AggregationOprationDataTable
                    searchData={{ ...state.payload, dataCount }}
                    breadItem={[
                      ...breadItem,
                      {
                        title: 'AOG Search Result',
                        href: '/aggregation-group-operation/',
                        state: {
                          payload: state.payload,
                          breadcrums: breadItem,
                        },
                      },
                    ]}
                  />
                </React.Fragment>
              ) : (
                'No Data Found'
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(AggregationGroupOpration);
