import React, { useEffect, useState } from 'react';
import { Link } from '@abyss/web/ui/Link';
// import { Link, useNavigate } from 'react-router-dom';
// import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { SubHeader } from '@src/common/components';
import { HelpContent } from '@src/components/Subscriber/utils';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { CustomerPolicyForm } from './CustomerPolicyForm';
import { CustomerDataLabel } from './CustomerDataLabel';
import { BASE_URL } from '../utils/endpoints';
import { CustomerFormViewDetails } from './CustomerFormViewDetails';
import { CustomerFormData } from './CustomerConstants';

export const CustomerPolicy = ({ SearchData, breadItem }) => {
  const [showHistory, setShowHistory] = useState(false);
  const { navigate } = useRouter();
  const { getLocation } = useRouter();
  const { state } = getLocation();
  const [searchData, setSearchData] = useState(SearchData || []);
  const [breadItems, setBreadItems] = useState(breadItem || []);
  const [policyData, setPolicyData] = useState([]);

  useEffect(() => {
    // Save data to local storage
    if (searchData) {
      localStorage.setItem('searchData', JSON.stringify(searchData));
    }
    if (breadItems) {
      localStorage.setItem('breadItem', JSON.stringify(breadItems));
    }
  }, [searchData, breadItems]);

  useEffect(() => {
    // Retrieve data from local storage
    const savedSearchData = localStorage.getItem('searchData');
    const savedBreadItem = localStorage.getItem('breadItem');
    if (savedSearchData) {
      setSearchData(JSON.parse(savedSearchData));
    }
    if (savedBreadItem) {
      setBreadItems(JSON.parse(savedBreadItem));
    }
  }, []);

  const breadItemInfo =
    breadItems && breadItems.length > 0
      ? breadItems
      : [
          { title: 'Customer Search', href: '/Customer' },
          {
            title: 'Customer Policy',
            href: '/Customer#form',
            state: {
              breadcrumData: true,
              SearchData: searchData,
            },
          },
        ];

  const handleCustomerData = async () => {
    try {
      const payload = {
        rbtReportYr: SearchData[0].rbtReportYr,
        srcSystemCd: SearchData[0].srcSystemCd,
        srcSysCustId: SearchData[0].srcSystemContrId,
        atr5: SearchData[0].atr5,
      };

      const response = await axios.post(
        `${BASE_URL}customer/search-customer`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setPolicyData(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const hanldeCustomerPolicyList = async () => {
    const data = await handleCustomerData();
    navigate('/customer-policyList', {
      state: {
        data: { SearchData: data },
        breadcrums: breadItem,
      },
    });
  };


  const handleCustomerSubscriberList = () => {
    navigate('/customer/subscriber/list', {
      state: {
        data: { SearchData: searchData },
        breadcrums: breadItemInfo,
      },
    });
  };

  const searchLabel = [
    {
      fieldName: CustomerFormData.sourceSystem,
      value: searchData[0]?.srcSystemCd,
    },
    {
      fieldName: CustomerFormData.rebateYear,
      value: searchData[0]?.rbtReportYr,
    },
    { fieldName: CustomerFormData.platCust, value: searchData[0]?.platCustNbr },
    {
      fieldName: CustomerFormData.customerId,
      value: (
        <Link
          onClick={(e) => {
            e.preventDefault();
            hanldeCustomerPolicyList();
          }}
          href="#"
        >
          {searchData[0]?.srcSysCustId}
        </Link>
      ),
    },
    {
      fieldName: CustomerFormData.policy,
      value: (
        <Link
          onClick={(e) => {
            if (SearchData[0]?.atr5 !== 'STT') {
              e.preventDefault();
              handleCustomerSubscriberList();
            }
          }}
          href="#"
          isDisabled={SearchData[0]?.atr5 === 'STT'}
          isActive={SearchData[0]?.atr5 !== 'STT'}
          style={{
            pointerEvents: SearchData[0]?.atr5 === 'STT' ? 'none' : 'auto',
            cursor: SearchData[0]?.atr5 === 'STT' ? 'not-allowed' : 'pointer',
            color: SearchData[0]?.atr5 === 'STT' ? 'grey' : '#196ECF',
          }}
        >
          {searchData[0]?.srcSystemContrId}
        </Link>
      ),
    },
    {
      fieldName: CustomerFormData.cancelDate,
      value: searchData[0]?.cancelDate,
    },
    {
      fieldName: CustomerFormData.franchiseCode,
      value: searchData[0]?.franchCd,
    },
    { fieldName: CustomerFormData.policyType, value: searchData[0]?.atr5 },
  ];

  return (
    <React.Fragment>
      <CustomerDataLabel searchLabel={searchLabel} SearchData={searchData} />
      <CustomerPolicyForm formData={searchData} breadcrumsInfo={breadItems} />

      {}
    </React.Fragment>
  );
};
