import React, { useEffect, useState } from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import {
  HelpContent,
  HelpContentResult,
} from '@src/components/AggregationOperationGroup/utils';
import {
  AggregationOprationForm,
  AggregationOprationDataTable,
} from '@src/components/AggregationOperationGroup';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '../../common/components';
import {
  FormOuterContainer,
  FormContainer,
  mediumSize,
} from '../../common/utils/StyleConstant';

const AggregationOperationGroup = () => {
  const [showSearchData, setShowSearchData] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [breadItem, setBreadItem] = useState([
    { title: 'AOG Search', href: '/aggregation-operation-group/' },
  ]);
  const { navigate, getLocation } = useRouter();
  const { state, hash } = getLocation();
  const isTable = hash === '#table';
  const breadcrumsData = state?.breadcrumsData;
  const dataCount = state?.dataCount;

  const handleSearch = (data) => {
    setSearchData(data);
    navigate('#table');
    setBreadItem([
      { title: 'AOG Search', href: '/aggregation-operation-group/' },
      {
        title: 'AOG Search Result',
        href: '/aggregation-operation-group#table',
        state: {
          breadcrumsData: data,
        },
      },
    ]);
    setShowSearchData(true);
  };

  const isMedium = useMediaQuery(mediumSize);

  useEffect(() => {
    if (!isTable) {
      setShowSearchData(false);
    } else if (breadcrumsData) {
      console.log('BCD', breadcrumsData);
      if (dataCount) {
        handleSearch({ ...breadcrumsData, dataCount });
      } else {
        handleSearch(breadcrumsData);
      }
    } else if (!searchData) {
      navigate('');
    }
  }, [isTable]);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItem}
                infoTitle={
                  isTable
                    ? 'Aggregation Operational Group Result'
                    : 'Aggregation Operational Group Search'
                }
                InfoContent={isTable ? HelpContentResult : HelpContent}
              />
              {!showSearchData ? (
                <FormOuterContainer>
                  <FormContainer medium={isMedium}>
                    <AggregationOprationForm onSearch={handleSearch} />
                  </FormContainer>
                </FormOuterContainer>
              ) : (
                <AggregationOprationDataTable
                  searchData={searchData}
                  breadItem={breadItem}
                />
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(AggregationOperationGroup);
