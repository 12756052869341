import React, { useState, useEffect } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { MlreDataTable, MlreLoader } from '@src/common/MlreAbyssComponent';
import { Link } from '@abyss/web/ui/Link';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import { updateWith } from 'lodash';
import useAuth from '@src/auth/useAuth';
import {
  ADD_ENTITY,
  LEGAL_ENTITY,
  ENTITY_NAME,
  BUSINESS_UNIT,
  UPDATE_LGLENT_PATH,
} from './LegalEntityConstants';
import { WrapperDiv } from '../../common/utils/StyleConstant';
import { BASE_URL } from '../utils/endpoints';
import env from '../../../env.json';

export const LegalEntityTable = ({ onAddEntity }) => {
  const { userRolesGroups } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { navigate, getLocation } = useRouter();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isHyperlinkDisabled, setIsHyperlinkDisabled] = useState(false);
  const newBraditem = [
    { title: 'Legal Entity', href: '/legal-entity' },
    {
      title: 'Edit Legal Entity',
      href: '/update-legel-entity',
    },
  ];
  // useEffect(() => {
  //   fetch(`${BASE_URL}legalEntity/get-legal-entity-items`)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setData(data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       return error;
  //     });
  // }, []);

  useEffect(() => {
    const hasWriteAccess = env.LegalEntity_Add.some((role) => {
      return userRolesGroups.includes(role);
    });
    setIsButtonDisabled(!hasWriteAccess);

    const hasUpdateAccess = env.LegalEntity_Update.some((role) => {
      return userRolesGroups.includes(role);
    });

    setIsHyperlinkDisabled(!hasUpdateAccess);

    setLoading(false);
  }, [userRolesGroups, env.LegalEntity_Update]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}legalEntity/get-legal-entity-items`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.log('Error Fetchin Data', error);
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      }
    };
    fetchData();
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        Header: LEGAL_ENTITY,
        accessor: 'LglEntyId',
        disableSortBy: true,
        Cell: ({ value, cell }) => {
          const rowValue = cell.row.original;
          const isDisabled = isHyperlinkDisabled
          return (
            <React.Fragment>
              {}

              {isHyperlinkDisabled ? (
                <Link
                  href="#"
                  isDisabled
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(UPDATE_LGLENT_PATH, {
                      state: { rowValue, newBraditem },
                    });
                  }}
                >
                  {value}
                </Link>
              ) : (
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(UPDATE_LGLENT_PATH, {
                      state: { rowValue, newBraditem },
                    });
                  }}
                >
                  {value}
                </Link>
              )}

            </React.Fragment>
          );
        },
      },
      {
        Header: ENTITY_NAME,
        accessor: 'LglEntyNm',
        disableSortBy: true,
      },
      {
        Header: BUSINESS_UNIT,
        accessor: 'Atr1',
        disableSortBy: true,
      },
    ];
  }, [isHyperlinkDisabled]);

  return (
    <React.Fragment>
      {loading ? (
        <MlreLoader />
      ) : (
        <MlreDataTable data={data} columns={columns} refreshColumn />
      )}
      <WrapperDiv>
        <Button onClick={onAddEntity} isDisabled={isButtonDisabled}>
          {ADD_ENTITY}
        </Button>
      </WrapperDiv>
    </React.Fragment>
  );
};
