import React, { useState, useEffect } from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import axios from 'axios';
import { Flex } from '@abyss/web/ui/Flex';
import { BASE_URL } from '../utils/endpoints';
import {
  POLICY,
  POLICY_TYPE,
  REBATE_YEAR,
  RESET,
  SEARCH,
  SOURCE_SYSTEM,
} from './CustomerConstants';
import styles from '../../styles/styles.module.css';

export const CustomerForm = ({ onSearch }) => {
  const form = useForm({
    defaultValues: {
      rebate_report_year: '',
      source_system: '',
      policy_Type: '',
      policy: '',
    },
  });

  const [year, setYear] = useState([]);
  const [systemId, setSystemId] = useState([]);
  const [atr, setAtr] = useState([]);
  const [error, setError] = useState('');
  const [isValidate, setisValidate] = useState(true);
  const [policyData, setPolicyData] = useState([]);
  const [showPolicyDetails, setShowPolicyDetails] = useState(false);
  const [isDropDownLoading, setIsDropDownLoading] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleValidate = (data) => {
    if (!data.rebate_report_year) {
      setError('Error');
      setisValidate(false);
    }
    if (!data.source_system) {
      setError('Error');
      setisValidate(false);
    }
    if (!data.policy) {
      setError('Error');
      setisValidate(false);
    }
    if (!data.policy_Type) {
      setError('Error');
      setisValidate(false);
    }

    setisValidate(true);
  };

  const onSubmit = async (data) => {
    localStorage.setItem('customerFormData', JSON.stringify(form.getValues()));
    handleValidate(data);
    setIsSearchDisabled(true);
    if (isValidate) {
      try {
        const payload = {
          rbtReportYr: data.rebate_report_year,
          srcSystemCd: data.source_system,
          srcSysCustId: data.policy,
          atr5: data.policy_Type,
        };

        const response = await axios.post(
          `${BASE_URL}customer/search-customer`,
          payload,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const policyDataResponse = response.data;

        console.log('response', response.status);

        if (!policyDataResponse || policyDataResponse.length === 0) {
          alert('Policy not found.');
          setShowPolicyDetails(false);
          setIsSearchDisabled(false);
          setLoading(false);
        } else {
          setPolicyData(policyDataResponse);
          setShowPolicyDetails(true);
          onSearch(policyDataResponse);
          setIsSearchDisabled(false);
        }
      } catch (error) {
        console.error(error);
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      }
    }
  };

  useEffect(() => {
    if (!form.formState.isValid) {
      setLoading(false);
    }
  }, [form.formState]);

  const handleDropDownData = async () => {
    // setIsDropDownLoading(true);
    try {
      const res = await axios.get(`${BASE_URL}customer/get-dropdowns`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });

      const uniqueValues = {
        year: new Set(),
        systemId: new Set(),
        atr: new Set(),
      };

      res.data.forEach((item) => {
        uniqueValues.year.add(item.rbtReportYr.toString());
        uniqueValues.systemId.add(item.srcSystemCd);
        uniqueValues.atr.add(item.atr5);
      });

      const yearArray = Array.from(uniqueValues.year);
      const systemIdArray = Array.from(uniqueValues.systemId).sort();
      const atrArray = Array.from(uniqueValues.atr);

      setYear(yearArray);
      setSystemId(systemIdArray);
      setAtr(atrArray);

      const savedYear = localStorage.getItem('selectedYear');
      const savedSystemId = localStorage.getItem('selectedSystemId');
      const savedAtr = localStorage.getItem('selectedAtr');

      const savedCustomerFormData = localStorage.getItem('customerFormData');

      if (savedCustomerFormData) {
        const parsedData = JSON.parse(savedCustomerFormData);

        form.reset(parsedData);
      } else {
        if (savedYear) {
          form.setValue('rebate_report_year', savedYear);
        } else if (yearArray.length > 0) {
          localStorage.setItem('selectedYear', yearArray[0]);
          form.setValue('rebate_report_year', yearArray[0]);
        }

        if (savedSystemId) {
          form.setValue('source_system', savedSystemId);
        } else if (systemIdArray.length > 0) {
          localStorage.setItem('selectedSystemId', systemIdArray[0]);
          form.setValue('source_system', systemIdArray[0]);
        }

        if (savedAtr) {
          form.setValue('policy_Type', savedAtr);
        } else if (atrArray.length > 0) {
          localStorage.setItem('selectedAtr', atrArray[0]);
          form.setValue('policy_Type', atrArray[0]);
        }
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    } finally {
      setIsDropDownLoading(false);
    }
  };

  useEffect(() => {
    const savedCustomerFormData = localStorage.getItem('customerFormData');

    if (savedCustomerFormData) {
      const parsedData = JSON.parse(savedCustomerFormData);
      form.reset(parsedData);
    }

    handleDropDownData();
  }, []);

  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <SelectInput
        label={REBATE_YEAR}
        model="rebate_report_year"
        isLoading={isDropDownLoading}
        options={year.map((item) => {
          return {
            value: item,
            label: item,
          };
        })}
      />
      <SelectInput
        label={SOURCE_SYSTEM}
        model="source_system"
        isLoading={isDropDownLoading}
        options={systemId.map((item) => {
          return {
            value: item,
            label: item,
          };
        })}
      />
      <SelectInput
        label={POLICY_TYPE}
        model="policy_Type"
        isLoading={isDropDownLoading}
        options={atr.map((item) => {
          return {
            value: item,
            label: item,
          };
        })}
      />
      <TextInput
        label={POLICY}
        model="policy"
        validators={{ required: 'Enter a Policy Number' }}
        onBlur={(e) => {
          if (e.target.value) {
            form.setValue('policy', e.target.value.toUpperCase());
          }
        }}
      />
      <Flex direction="row" justify="center" className={styles.Layout}>
        <Button
          type="submit"
          className={styles.Button}
          isDisabled={isSearchDisabled}
          isLoading={isLoading}
          ariaLoadingLabel="Importing data"
          onClick={() => {
            return setLoading(!isLoading);
          }}
        >
          {SEARCH}
        </Button>

        <Button
          onClick={() => {
            localStorage.removeItem('customerFormData');
            form.reset({
              rebate_report_year: year[0],
              source_system: systemId[0],
              policy_Type: atr[0],
              policy: '',
            });
            handleDropDownData();
          }}
          className={styles.Button}
        >
          {RESET}
        </Button>
      </Flex>
    </FormProvider>
  );
};
