import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Link } from '@abyss/web/ui/Link';
import { useRouter } from '@abyss/web/hooks/useRouter';
import axios from 'axios';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import { HelpContentCustomerSearchView } from '@src/components/Subscriber/utils';
import {
  MlreLoader,
  MlreDataTable,
  DataTableSearchLabel,
} from '../../common/MlreAbyssComponent';
import { BASE_URL } from '../utils/endpoints';

const CustomerSubscriberTable = ({ searchData }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { navigate } = useRouter();
  const [subscriberListData, setSubscriberListData] = useState([]);
  const { getLocation } = useRouter();
  const { location, state } = getLocation();
  const subSearchData = state?.subSearchData;

  const subSrcSystemContrId =
    state?.data?.SearchData?.[0]?.srcSystemContrId ||
    subSearchData?.subSrcSystemContrId;
  const subSrcSystemCd =
    state?.data?.SearchData?.[0]?.srcSystemCd || subSearchData?.subSrcSystemCd;

  const breadcrums = state?.breadcrums;
  const breadItem = breadcrums
    ? [
        ...breadcrums,
        {
          title: 'Subscribers List',
          href: '/customer/subscriber/list',
          state: {
            breadcrums,
            subSearchData: { subSrcSystemContrId, subSrcSystemCd },
          },
        },
      ]
    : [
        { title: 'Customer Search', href: '/Customer' },
        {
          title: 'Customer Policy',
          href: '/Customer#form',
          state: {
            breadcrumData: state?.breadcrumsInfo,
            SearchData: state?.data.SearchData,
          },
        },
        { title: 'Subscribers List', href: '/customer/subscriber/list' },
      ];

  const srcSysCustId = state?.data?.SearchData?.[0]?.srcSystemContrId;
  const rbtYear = state?.data?.SearchData?.[0]?.rbtReportYr;
  console.log('state', state?.data?.SearchData[0]);
  if (srcSysCustId) {
    localStorage.setItem('srcSysCustId', srcSysCustId);
  }

  if (rbtYear) {
    localStorage.setItem('rbtYear', rbtYear);
  }

  const handleSubscriberList = () => {
    const id = srcSysCustId || localStorage.getItem('srcSysCustId');
    const year = rbtYear || localStorage.getItem('rbtYear');
    axios
      .get(`${BASE_URL}customer/${id}/year/${year}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setSubscriberListData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error', error);
      });
  };

  useEffect(() => {
    if (state?.data) {
      handleSubscriberList();
    }
    if (window.location.pathname === '/customer/subscriber/list') {
      handleSubscriberList();
    }
  }, [srcSysCustId, window.location]);

  useEffect(() => {
    if (selectedRow != null) {
      const year =
        state?.data?.SearchData?.[0]?.rbtReportYr ||
        localStorage.getItem('rbtYear');
      const navigateData = subscriberListData[selectedRow];

      navigate('/subscriber-view', {
        state: {
          navigateData: { ...navigateData, year },
          subSrcSystemContrId,
          breadcrums: breadItem,
        },
      });
    }
  }, [selectedRow]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Subscriber ID',
        accessor: 'altId',
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                setSelectedRow(row.id);
              }}
              href="#"
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Last Name',
        accessor: 'sbscrLstNm',
        disableSortBy: true,
      },
      {
        Header: 'First Name',
        accessor: 'sbscrFstNm',
        disableSortBy: true,
      },
      {
        Header: 'Middle Init',
        accessor: 'sbscrMidlInitNm',
        disableSortBy: true,
      },
      {
        Header: 'Customer ID',
        accessor: 'srcSysCustId',
        disableSortBy: true,
      },
      {
        Header: 'Premium Contribution',
        accessor: 'totPremCntrbAmt',
        disableSortBy: true,
        Cell: ({ value }) => {
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      },
      {
        Header: 'Address',
        accessor: 'sbscrAdrLn1',
        disableSortBy: true,
        Cell: ({ row }) => {
          const address1 =
            row.original.sbscrAdrLn1 && row.original.sbscrAdrLn1 !== 'null'
              ? row.original.sbscrAdrLn1
              : '';
          const address2 =
            row.original.sbscrAdrLn2 && row.original.sbscrAdrLn2 !== 'null'
              ? row.original.sbscrAdrLn2
              : '';
          const address3 =
            row.original.sbscrAdrLn3 && row.original.sbscrAdrLn3 !== 'null'
              ? row.original.sbscrAdrLn3
              : '';
          const city =
            row.original.sbscrCtyNm && row.original.sbscrCtyNm !== 'null'
              ? row.original.sbscrCtyNm
              : '';
          const states =
            row.original.sbscrStCd && row.original.sbscrStCd !== 'null'
              ? row.original.sbscrStCd
              : '';
          const zip =
            row.original.sbscrZipCd && row.original.sbscrZipCd !== 'null'
              ? row.original.sbscrZipCd
              : '';
          return `${address1} ${address2} ${address3} ${city} ${states} ${zip}`;
        },
      },
    ];
  }, []);

  const searchLabel = [
    {
      fieldName: 'Policy#',
      value: subSrcSystemContrId,
    },
    {
      fieldName: 'Source System',
      value: subSrcSystemCd,
    },
  ];

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItem}
                infoTitle="Subscribers List"
                InfoContent={HelpContentCustomerSearchView}
              />
              {isLoading ? (
                <MlreLoader />
              ) : (
                <React.Fragment>
                  {' '}
                  <DataTableSearchLabel searchLabel={searchLabel} />
                  <MlreDataTable data={subscriberListData} columns={columns} />
                </React.Fragment>
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(CustomerSubscriberTable);
