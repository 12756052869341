/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Flex } from '@abyss/web/ui/Flex';
import { Label } from '@abyss/web/ui/Label';
import { Text } from '@abyss/web/ui/Text';
import { Table } from '@abyss/web/ui/Table';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { DateInput } from '@abyss/web/ui/DateInput';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { Hamburger } from '@src/components/Hamburger';
import { Header } from '@src/components/Header';
import { SubHeader } from '@src/common/components';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import withAuth from '@src/auth/withAuth';
import { useRouter } from '@abyss/web/hooks/useRouter';
import useAuth from 'src/auth/useAuth';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import axios from 'axios';
import {
  POLICY_NUM,
  LEGAL_ENTITY,
  MLR_CASE_SIZE,
  SITUS_STATE,
  CHECK_NUMBER,
  CHECK_DATE,
  CHECK_AMOUNT,
  DISTRIBUTION_STATUS,
  REMAILED_DATE,
  ADDRESS,
  PAYMENT_STATUS,
  POST_MARK_DATE,
  VOID,
  REDISTRIBUTE,
  REISSUE,
  CLOSE,
  SAVE,
  RESET,
  CANCEL,
  POLICYTYPE,
  POLICY_TYPE,
} from './Constant';
import {
  mediumSize,
  PaymentContainer,
  PaymentOuterContainer,
  PaymentMainContainer,
} from '../../common/utils/StyleConstant';
import editlogo from '../../assets/edit.gif';
import historyLogo from '../../assets/history_icon.png';
import { ConfirmSaveModal, ConfirmVoidModal } from './Modal';
import { BASE_URL } from '../utils/endpoints';
import { HelpContentPaymentDetail } from './utils';
import env from '../../../env.json';

export const PaymentDetail = () => {
  const form = useForm();
  const { userRolesGroups } = useAuth();
  const onSearch = () => {};
  const isMedium = useMediaQuery(mediumSize);
  const [paymentHistory, setPaymentHistory] = useState(false);
  const [history, setHistory] = useState(false);
  const [openVoidModal, setOpenVoidModal] = useState(false);
  const [editData, setEditData] = useState(false);
  const [sucessText, setSucessText] = useState('');
  const { getLocation, navigate } = useRouter();
  const { state, location } = getLocation();
  const { breadItem } = state;
  const { msid } = useAuth();
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [validatePaymentData, setValidatePaymentData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);
  const [showCancelText, setShowCancelText] = useState(false);
  const [showSaveWarr, setShowSaveWarr] = useState(false);
  const [selectedPaymentData, setSelectedPaymentData] = useState(
    state?.selectedPaymentData
  );
  const [distributionStatus, setDistributionStatus] = useState(
    state?.selectedPaymentData?.distributionStatus?.toUpperCase()
  );
  const [remailedDate, setRemailedDate] = useState(
    state?.selectedPaymentData?.atr7
  );

  const [isVoidDisabled, setIsVoidDisabled] = useState(true);
  const [isReissueDisabled, setIsReissueDisabled] = useState(true);
  const [isRedistributeDisabled, setIsRedistributeDisabled] = useState(true);

  const refreshData = state?.refreshData;
  const breadItems = breadItem
    ? [
        ...breadItem,
        {
          title: 'Payment Details',
          href: '/payment-detail/',
          state: { selectedPaymentData },
        },
      ]
    : [];

  // const paymentDate = selectedPaymentData.payPstMrkDt
  // const formattedDate = paymentDate.toString().slice(0, 10).replace(/-/g, '-');
  // const yy = paymentDate.getFullyear().toString().slice(2)
  // const newFromatetedDate = `${yy}-${formattedDate.slice(5, 7)}--${formattedDate.slice(8, 10)}`
  // console.log("newFromatetedDate----", formattedDate)

  const formateDate = (dateStr: string): string => {
    if (dateStr == null || dateStr == undefined || dateStr == '') {
      return '';
    }
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const fetchPaymentData = async () => {
    const checkNumb = selectedPaymentData.pmtDocNbr;
    const payload = {
      pmtDocTypCd: '',
      pmtDocNbr: checkNumb,
    };
    const result = await axios.post(`${BASE_URL}payment/search`, payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
        'Content-Type': 'application/json',
      },
    });
    if (result) {
      const subList = result?.data;
      if (subList) {
        const sub = subList.filter((item) => {
          return item.pmtDocNbr === checkNumb;
        });
        setSelectedPaymentData(sub[0]);

        if (
          breadItems.length &&
          breadItems[1]?.title === 'Payment Search Results'
        ) {
          breadItems[1].state.breadcrumData = subList;
        }
      }
    }
  };

  const checkStatus = selectedPaymentData?.payStsCd
    ? selectedPaymentData.payStsCd.toUpperCase()
    : selectedPaymentData?.payStsCd;
  const checkType = selectedPaymentData?.payeTypCd
    ? selectedPaymentData.payeTypCd.toUpperCase()
    : selectedPaymentData?.payeTypCd;
  const ATR12 = selectedPaymentData?.atr12
    ? selectedPaymentData.atr12.toUpperCase()
    : selectedPaymentData?.atr12;
  const ATR2 = selectedPaymentData?.atr2
    ? selectedPaymentData.atr2.toUpperCase()
    : selectedPaymentData?.atr2;
  const ATR5 = selectedPaymentData?.atr5
    ? selectedPaymentData.atr5.toUpperCase()
    : selectedPaymentData?.atr5;

  const validatePayment = async () => {
    try {
      const payload = {
        payDocNum: selectedPaymentData?.pmtDocNbr,
        modifiedDateTime: selectedPaymentData?.modifiedDateTime,
      };
      const result = await axios.post(`${BASE_URL}payment/validate`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      });
      if (result?.data) {
        setValidatePaymentData(result?.data);
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    validatePayment();
  }, []);

  const handlePaymentChange = () => {
    if (selectedPaymentData) {
      if (checkStatus === 'OUTSTANDING' || checkStatus === 'DORMANT') {
        if (checkType === 'EMPLOYER' || checkType === 'CUSTOMER') {
          setIsVoidDisabled(false);
          setIsRedistributeDisabled(false);
          setIsReissueDisabled(true);
        }
        if (checkType === 'INDIVIDUAL' || checkType === 'SUBSCRIBER') {
          setIsVoidDisabled(false);
          setIsRedistributeDisabled(true);
          setIsReissueDisabled(true);
        }
      }

      if (checkStatus === 'VOID') {
        setIsReissueDisabled(false);
        setIsVoidDisabled(true);
        setIsRedistributeDisabled(true);
        if (ATR12 !== null && ATR12 !== '5' && ATR12 !== '') {
          setIsReissueDisabled(true);
          setIsVoidDisabled(true);
          setIsRedistributeDisabled(true);
        }
      }

      if (
        checkStatus === 'PENDING VOID' ||
        checkStatus === 'PAID' ||
        checkStatus === 'REMITTED'
      ) {
        setIsReissueDisabled(true);
        setIsVoidDisabled(true);
        setIsRedistributeDisabled(true);
      }

      if (checkStatus === 'VOID' && ATR12 === 5) {
        if (checkType === 'EMPLOYER' || checkType === 'CUSTOMER') {
          setIsReissueDisabled(false);
          setIsVoidDisabled(true);
          setIsRedistributeDisabled(true);
        } else if (checkType === 'INDIVIDUAL' || checkType === 'SUBSCRIBER') {
          setIsReissueDisabled(true);
          setIsVoidDisabled(true);
          setIsRedistributeDisabled(true);
        }
      }

      if (checkStatus === 'VOID' && ATR2 === 'REISSUE REJECTED') {
        if (checkType === 'EMPLOYER' || checkType === 'CUSTOMER') {
          setIsRedistributeDisabled(false);
          setIsReissueDisabled(true);
          setIsVoidDisabled(true);
        } else if (checkType === 'INDIVIDUAL' || checkType === 'SUBSCRIBER') {
          setIsReissueDisabled(true);
          setIsVoidDisabled(true);
          setIsRedistributeDisabled(true);
        }
      }

      if (
        checkStatus === 'VOID' &&
        (ATR2 === 'REISSUE APPROVED' || ATR2 === 'PENDING REISSUE APPROVAL')
      ) {
        setIsReissueDisabled(true);
        setIsVoidDisabled(true);
        setIsRedistributeDisabled(true);
      }

      if (ATR5 === 'STT') {
        setIsRedistributeDisabled(true);
      }

      // if (
      //   ATR12 === 5 &&
      //   (checkType === 'Customer' || checkType === 'INDIVIDUAL') &&
      //   ATR2 !== 'Reissue Rejected'
      // ) {
      //
      //   setIsReissueDisabled(false);
      // }
      // if (
      //   ATR2 === 'Reissue Rejected' &&
      //   (checkType === 'Customer' || checkType === 'EMPLOYER')
      // ) {
      //
      //   setIsRedistributeDisabled(false);
      // }
    }
  };

  useEffect(() => {
    handlePaymentChange();
  }, [selectedPaymentData]);

  useEffect(() => {
    if (refreshData) {
      fetchPaymentData();
    }
  }, [refreshData]);

  useEffect(() => {
    const hasEditAccess = env.Payment_Edit_Distribution_Status.some((role) => {
      return userRolesGroups.includes(role);
    });

    const hasUpdateAccess = env.Payment_update.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasEditAccess) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups]);

  const historyColumn = [
    { name: 'Status', key: 'pmtDocStsCd', isRowHeader: true },
    { name: 'Modified Date', key: 'pmtDocStsDt' },
    { name: 'Modified BY', key: 'modById' },
  ];

  const paymentHistoryColumn = [
    { name: 'Payment Status', key: 'chkStsCd' },
    { name: 'Modified Date', key: 'chkStsModDttm' },
    { name: 'Modified BY', key: 'chkStsModById' },
  ];

  const changeData = () => {
    setEditData(true);
  };
  const historyPayload = {
    pmtDocNbr: selectedPaymentData?.pmtDocNbr,
  };
  const handleHistory = async () => {
    try {
      const result = await axios.post(
        `${BASE_URL}payment/distr/history`,
        historyPayload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (result) {
        setHistoryData(result.data);
        setPaymentHistory(false);
        setHistory(true);
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const paymentHistorypayload = {
    pmtDocNbr: selectedPaymentData?.pmtDocNbr,
  };

  const handlePaymentHistory = async () => {
    try {
      const result = await axios.post(
        `${BASE_URL}payment/history`,
        paymentHistorypayload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (result) {
        setPaymentHistoryData(result.data);
        setHistory(false);
        setPaymentHistory(true);
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  const distributionStatusPayload = {
    atr1: distributionStatus,
    atr7: remailedDate,
    modById: selectedPaymentData?.modById,
    pmtDocNbr: selectedPaymentData?.pmtDocNbr,
    modifiedDateTime: selectedPaymentData?.modifiedDateTime,
  };

  const handleDistributionStatusUpdate = async () => {
    const validationResult = await validatePayment();
    if (validationResult === 'validation passed') {
      setShowCancelText(false);
      setSaveIsButtonDisabled(true);

      try {
        const result = await axios.post(
          `${BASE_URL}payment/update`,
          distributionStatusPayload,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (result.status === 200) {
          setEditData(false);
          fetchPaymentData();
          alert(result.data);
          setSaveIsButtonDisabled(false);
        }
      } catch (error) {
        console.error(error);
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      } finally {
        setSaveIsButtonDisabled(false);
      }
    } else {
      alert('Failed: Current data has been modified by other user');
    }
  };

  const handleDistributionStatusReset = () => {
    setRemailedDate(selectedPaymentData.atr7);
    setDistributionStatus(selectedPaymentData.distributionStatus);
    setEditData(false);
  };

  const handleDistributionStatusCancel = () => {
    if (showCancelText) {
      setShowSaveWarr(true);
    } else {
      handleDistributionStatusReset();
    }
  };

  const handleRedistribute = async () => {
    const validationResult = await validatePayment();
    if (validationResult === 'validation passed') {
      const breadCumData = [
        ...breadItem,
        {
          title: 'Payment Details',
          href: '/payment-detail/',
          state: { selectedPaymentData, breadItem },
        },
      ];
      navigate('/employer-conversion-check', {
        state: { data: selectedPaymentData, breadcrums: breadCumData },
      });
    } else {
      alert('Failed: Current data has been modified by other user');
    }
  };

  const handleReissue = async () => {
    const validationResult = await validatePayment();
    if (validationResult === 'validation passed') {
      setIsReissueDisabled(true);
      setSucessText('Check Reissuance initiated..');
      if (
        selectedPaymentData.payStsCd !== 'Pending Void' &&
        selectedPaymentData.payStsCd !== 'OUTSTANDING'
      ) {
        const payloadData = {
          atr2: 'Pending Reissue Approval',
          modById: selectedPaymentData?.modById,
          pmtDocNbr: selectedPaymentData?.pmtDocNbr,
          userId: msid,
        };
        try {
          const { data, error } = await axios.post(
            `${BASE_URL}payment/update/reissue`,
            payloadData,
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );
        } catch (error) {
          setIsReissueDisabled(false);
          console.log(error);
          alert(
            'An error occurred while processing your request. Please try again later.'
          );
        }
      }
    } else {
      alert('Failed: Current data has been modified by other user');
    }
  };

  const handleVoid = async () => {
    const voidStatus = 'Pending Void';
    const payloadData = {
      payStsCd: voidStatus,
      pmtDocNbr: selectedPaymentData?.pmtDocNbr,
      modById: msid,
      userId: msid,
    };
    try {
      const { data, error } = await axios.post(
        `${BASE_URL}payment/update/void`,
        payloadData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!error) {
        fetchPaymentData();
        setSucessText('Check Void in progress');
      }
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  return (
    <React.Fragment>
      <div style={{ maxWidth: '100vw' }}>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ margin: '0px', background: '$headerBackgroundColor' }}
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
          >
            <HeaderDiv>
              <Header />
            </HeaderDiv>
          </Grid.Col>
        </Grid>
        <BodyContainer>
          <Grid css={{ margin: '0px' }}>
            <Grid.Col
              css={{ padding: '0px' }}
              span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
            >
              <MediaQuery largerThan="$md">
                <Hamburger />
              </MediaQuery>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
              <BodyPageContainer>
                <SubHeader
                  breadItem={breadItems}
                  infoTitle="Payment Details"
                  InfoContent={HelpContentPaymentDetail}
                />
                <PaymentOuterContainer>
                  <PaymentContainer medium={isMedium}>
                    <PaymentMainContainer>
                      <FormProvider state={form} onSubmit={onSearch}>
                        <Grid>
                          <Grid.Col>
                            <Text fontWeight="bold">{POLICY_NUM}</Text>
                          </Grid.Col>
                          <Grid.Col>
                            <Text
                              fontWeight="bold"
                              css={{ marginRight: '20px' }}
                            >
                              :{selectedPaymentData?.srcSysContrId}
                            </Text>
                          </Grid.Col>
                          <Grid.Col>
                            <Text fontWeight="bold">{LEGAL_ENTITY}</Text>
                          </Grid.Col>
                          <Grid.Col>
                            <Text fontWeight="bold">
                              :{selectedPaymentData?.lglEntyId}
                            </Text>
                          </Grid.Col>
                        </Grid>
                        <Grid>
                          <Grid.Col>
                            <Text fontWeight="bold">{MLR_CASE_SIZE}</Text>
                          </Grid.Col>

                          <Grid.Col>
                            <Text
                              fontWeight="bold"
                              css={{ marginRight: '80px' }}
                            >
                              :{selectedPaymentData?.mlrCseSz}
                            </Text>
                          </Grid.Col>
                          <Grid.Col>
                            <Text fontWeight="bold">{SITUS_STATE}</Text>
                          </Grid.Col>
                          <Grid.Col>
                            <Text
                              fontWeight="bold"
                              css={{ marginLeft: '10px' }}
                            >
                              :{selectedPaymentData?.mlrSitusStCd}
                            </Text>
                          </Grid.Col>
                        </Grid>
                        <Grid>
                          <Grid.Col>
                            <Text fontWeight="bold">{POLICY_TYPE}</Text>
                          </Grid.Col>

                          <Grid.Col>
                            <Text
                              fontWeight="bold"
                              css={{ marginLeft: '20px' }}
                            >
                              :{selectedPaymentData?.atr5}
                            </Text>
                          </Grid.Col>
                        </Grid>

                        {/* <Flex style={{ marginTop: '10px', flexShrink: 0 }}>
                          <Label size="md">{POLICY_NUM}</Label>
                          <Label
                            size="md"
                            css={{ marginLeft: '25px', marginRight: '25px' }}
                          >
                            {selectedPaymentData?.srcSysContrId}
                          </Label>
                          <Label
                            size="md"
                            style={{
                              marginLeft: '26px',
                              marginRight: '25px',
                              flexShrink: 0,
                            }}
                          >
                            {LEGAL_ENTITY}:
                          </Label>
                          <Label size="md">
                            {selectedPaymentData?.lglEntyId}
                          </Label>
                        </Flex>
                        <Flex style={{ marginTop: '10px', flexShrink: 0 }}>
                          <Label size="md">{MLR_CASE_SIZE}:</Label>
                          <Label
                            size="md"
                            style={{
                              marginLeft: '25px',
                              marginRight: '25px',
                              flexShrink: 0,
                            }}
                          >
                            {selectedPaymentData?.mlrCseSz}
                          </Label>
                          <Label
                            size="md"
                            style={{
                              marginLeft: '90px',
                              marginRight: '25px',
                              flexShrink: 0,
                            }}
                          >
                            {SITUS_STATE}:
                          </Label>
                          <Label size="md">
                            {selectedPaymentData?.mlrSitusStCd}
                          </Label>
                        </Flex> */}

                        <Flex style={{ marginTop: '40px' }}>
                          <Text size="$md">{CHECK_NUMBER}</Text>
                          <Text css={{ marginLeft: '80px' }} size="$md">
                            : {selectedPaymentData?.pmtDocNbr}
                          </Text>
                        </Flex>
                        <Flex style={{ marginTop: '20px' }}>
                          <Text size="$md">{CHECK_DATE}</Text>
                          <Text css={{ marginLeft: '105px' }} size="$md">
                            : {formateDate(selectedPaymentData?.pmtDocDt)}{' '}
                          </Text>
                        </Flex>
                        <Flex style={{ marginTop: '20px' }}>
                          <Text size="$md">{CHECK_AMOUNT}</Text>
                          <Text css={{ marginLeft: '80px' }} size="$md">
                            :{' '}
                            {selectedPaymentData?.totPayAmt?.toLocaleString(
                              'en-US',
                              {
                                style: 'currency',
                                currency: 'USD',
                              }
                            )}
                          </Text>
                        </Flex>

                        {editData ? (
                          <Flex
                            direction="column"
                            style={{
                              marginTop: '20px',
                              width: '50%',
                            }}
                          >
                            <SelectInput
                              label={DISTRIBUTION_STATUS}
                              placeholder="Select One"
                              value={distributionStatus}
                              onChange={(value) => {
                                setShowCancelText(true);
                                if (value === 'REMAILED') {
                                  if (
                                    window.confirm(
                                      `Address was last modified on ${selectedPaymentData?.modifiedDate} Do you want to Update the Address?`
                                    )
                                  ) {
                                    alert(
                                      'Please Update the address in Subscriber / Customer screen.'
                                    );
                                  }
                                }
                                return setDistributionStatus(value);
                              }}
                              options={[
                                { value: 'MAILED', label: 'MAILED' },
                                { value: 'REMAILED', label: 'REMAILED' },
                                { value: 'RETURNED', label: 'RETURNED' },
                                { value: 'FORWARDED', label: 'FORWARDED' },
                              ]}
                            />
                            <br />
                            <DateInput
                              subText=""
                              label={
                                distributionStatus
                                  ? `${
                                      distributionStatus
                                        .charAt(0)
                                        .toUpperCase() +
                                      distributionStatus.slice(1).toLowerCase()
                                    } Date`
                                  : 'Date'
                              }
                              value={remailedDate}
                              onChange={(value) => {
                                setShowCancelText(true);
                                return setRemailedDate(value);
                              }}
                            />
                          </Flex>
                        ) : (
                          <React.Fragment>
                            <Flex style={{ marginTop: '20px' }}>
                              <Text size="$md">{DISTRIBUTION_STATUS}</Text>
                              <Text css={{ marginLeft: '52px' }} size="$md">
                                : {selectedPaymentData?.distributionStatus}
                              </Text>
                              {/* <Button onClick={changeData}>Click Here!</Button>
                            <Button onClick={showHistory}>Click Here!</Button> */}
                              {!isEdit ? (
                                <IconMaterial
                                  icon="edit"
                                  css={{
                                    cursor: 'pointer',
                                    marginLeft: '80px',
                                    marginTop: '-5px',
                                  }}
                                  onClick={changeData}
                                />
                              ) : (
                                ''
                              )}
                              <Tooltip content="History">
                                <Button
                                  rounded
                                  size="$sm"
                                  css={{
                                    backgroundColor: 'none !important',
                                    marginLeft: '20px',
                                    marginTop: '-5px',
                                  }}
                                >
                                  <IconMaterial
                                    icon="history"
                                    css={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={handleHistory}
                                  />
                                </Button>
                              </Tooltip>
                            </Flex>

                            <Flex style={{ marginTop: '10px', flexShrink: 0 }}>
                              <Text
                                size="$md"
                                css={{
                                  flexShrink: 0,
                                  width: '140px',
                                  textAlign: 'left',
                                }}
                              >
                                {selectedPaymentData?.distributionStatus
                                  ? `${
                                      selectedPaymentData?.distributionStatus
                                        .charAt(0)
                                        .toUpperCase() +
                                      selectedPaymentData?.distributionStatus
                                        .slice(1)
                                        .toLowerCase()
                                    } Date`
                                  : 'Date'}
                              </Text>
                              <Text css={{ marginLeft: '50px' }} size="$md">
                                : {selectedPaymentData?.atr7}
                              </Text>
                            </Flex>
                          </React.Fragment>
                        )}
                        <Grid css={{ marginTop: '13px' }}>
                          <Grid.Col>
                            <Text size="$md">{ADDRESS}</Text>
                            <Text size="$md" css={{ marginLeft: '130px' }}>
                              : {selectedPaymentData?.address}
                            </Text>
                          </Grid.Col>
                        </Grid>

                        <Flex style={{ marginTop: '20px' }}>
                          <Text size="$md">{PAYMENT_STATUS}</Text>
                          <Text css={{ marginLeft: '72px' }} size="$md">
                            : {selectedPaymentData?.payStsCd}
                          </Text>
                          {/* <Button onClick={showPaymentHistory}>
                          Click Here!
                        </Button> */}
                          <Tooltip content="History">
                            <Button
                              rounded
                              size="$sm"
                              css={{
                                backgroundColor: 'none !important',
                                marginLeft: '40px',
                                height: '30px',
                                width: '30px',
                                marginTop: '-5px',
                              }}
                            >
                              <IconMaterial
                                css={{ cursor: 'pointer' }}
                                icon="history"
                                onClick={handlePaymentHistory}
                              />
                            </Button>
                          </Tooltip>
                        </Flex>
                        <Flex style={{ marginTop: '20px' }}>
                          <Text size="$md">{POST_MARK_DATE}</Text>
                          <Text css={{ marginLeft: '76px' }} size="$md">
                            : {formateDate(selectedPaymentData?.payPstMrkDt)}
                          </Text>
                        </Flex>
                        {editData ? (
                          <Flex style={{ marginTop: '40px' }}>
                            <Button
                              onClick={handleDistributionStatusUpdate}
                              isDisabled={isSaveButtonDisabled}
                            >
                              {SAVE}
                            </Button>
                            <Button
                              style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                              }}
                              onClick={handleDistributionStatusReset}
                            >
                              {RESET}
                            </Button>
                            <Button onClick={handleDistributionStatusCancel}>
                              {CANCEL}
                            </Button>
                          </Flex>
                        ) : (
                          <Flex style={{ marginTop: '40px' }}>
                            <Button
                              onClick={async () => {
                                const validationResult =
                                  await validatePayment();
                                if (validationResult === 'validation passed') {
                                  setOpenVoidModal(true);
                                } else {
                                  alert(
                                    'Failed: Current data has been modified by other user'
                                  );
                                }
                              }}
                              isDisabled={isButtonDisabled || isVoidDisabled}
                            >
                              {VOID}
                            </Button>
                            <Button
                              style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                              }}
                              onClick={handleRedistribute}
                              isDisabled={
                                isButtonDisabled || isRedistributeDisabled
                              }
                            >
                              {REDISTRIBUTE}
                            </Button>
                            <Button
                              onClick={handleReissue}
                              isDisabled={isButtonDisabled || isReissueDisabled}
                            >
                              {REISSUE}
                            </Button>
                          </Flex>
                        )}
                      </FormProvider>
                    </PaymentMainContainer>
                    <br />
                    {sucessText}
                    {paymentHistory ? (
                      <Flex
                        justify="center"
                        style={{ marginTop: '20px', width: '100%' }}
                      >
                        <Table
                          title="Table Scroll"
                          columns={paymentHistoryColumn}
                          rows={paymentHistoryData}
                          footer={
                            !paymentHistoryData.length ? (
                              <Table.Row>
                                <Table.FooterCell
                                  colSpan={paymentHistoryColumn.length}
                                >
                                  No Payment records found
                                </Table.FooterCell>
                              </Table.Row>
                            ) : (
                              ''
                            )
                          }
                        />
                        <Button
                          style={{ marginTop: '20px' }}
                          onClick={() => {
                            return setPaymentHistory(false);
                          }}
                        >
                          {CLOSE}
                        </Button>
                      </Flex>
                    ) : null}
                    {history ? (
                      <Flex
                        justify="center"
                        style={{ marginTop: '20px', width: '100%' }}
                      >
                        <Table
                          title="Table Scroll"
                          columns={historyColumn}
                          rows={historyData}
                          footer={
                            !historyData.length ? (
                              <Table.Row>
                                <Table.FooterCell
                                  colSpan={historyColumn.length}
                                >
                                  No Distribution Status records found.
                                </Table.FooterCell>
                              </Table.Row>
                            ) : (
                              ''
                            )
                          }
                        />
                        <Button
                          style={{ marginTop: '20px' }}
                          onClick={() => {
                            return setHistory(false);
                          }}
                        >
                          {CLOSE}
                        </Button>
                      </Flex>
                    ) : null}
                  </PaymentContainer>
                </PaymentOuterContainer>
                <ConfirmVoidModal
                  isOpen={openVoidModal}
                  onClose={() => {
                    setOpenVoidModal(false);
                  }}
                  onConfirm={handleVoid}
                  data={selectedPaymentData}
                />

                <ConfirmSaveModal
                  isOpen={showSaveWarr}
                  onClose={() => {
                    setShowSaveWarr(false);
                  }}
                  onConfirm={() => {
                    setShowCancelText(false);
                    handleDistributionStatusReset();
                  }}
                />
              </BodyPageContainer>
            </Grid.Col>
          </Grid>
        </BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ background: '$pageFooter-color-background-root' }}
            span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
          >
            <Footer />
          </Grid.Col>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default withAuth(PaymentDetail);
