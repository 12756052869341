import React, { useEffect, useState } from 'react';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import axios from 'axios';
import { BASE_URL } from '../utils/endpoints';

export const AggregationOprationForm = ({ onSearch }) => {
  const form = useForm();

  const [selectBoxData, setSelectBoxData] = useState({
    rbtRptYr: [],
    runTypCd: [],
    runTypSeqNbr: [],
    srcSysCd: [],
    mlrCseSz: [],
    mlrSitusCd: [],
    lglEntyId: [],
    glBuId: [],
    glLocCd: [],
    policy: [],
  });
  const [isDropDownLoading, setIsDropDownLoading] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = (data) => {
    onSearch(data);
    localStorage.setItem('aggregationOperationFormData', JSON.stringify(data));
  };

  useEffect(() => {
    if (!form.formState.isValid) {
      setLoading(false);
    }
  }, [form.formState]);

  const fetchSelectBoxRows = async (payload = {}) => {
    try {
      const result = await axios.post(
        `${BASE_URL}aog/get-aog-dropdowns`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const rows = result?.data?.response;
      const selectBoxRows = [];
      rows.map((item) => {
        selectBoxRows.push({ value: item, label: item });
      });
      return selectBoxRows;
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    } finally {
      setIsDropDownLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchSelectBoxRows();
        setSelectBoxData({
          rbtRptYr: result,
          runTypCd: [],
          runTypSeqNbr: [],
          srcSysCd: [],
          mlrCseSz: [],
          mlrSitusCd: [],
          lglEntyId: [],
          glBuId: [],
          glLocCd: [],
          policy: [],
        });

        const savedFormData = localStorage.getItem(
          'aggregationOperationFormData'
        );
        if (savedFormData) {
          const parsedFormData = JSON.parse(savedFormData);
          Object.keys(parsedFormData).forEach((key) => {
            form.setValue(key, parsedFormData[key]);
          });

          // Fetch dependent dropdown data based on saved form data

          const inputValues = [];
          let currentRow = {};
          for (const item in parsedFormData) {
            if (parsedFormData[item]) {
              currentRow = { ...currentRow, [item]: parsedFormData[item] };
              inputValues.push({
                ...currentRow,
                [item]: parsedFormData[item],
              });
            } else {
              break;
            }
          }

          try {
            const response = await Promise.all(
              inputValues.map((inputValue) => {
                return fetchSelectBoxRows(inputValue);
              })
            );
            setSelectBoxData({
              rbtRptYr: result,
              runTypCd: response[0] || [],
              runTypSeqNbr: response[1] || [],
              srcSysCd: response[2] || [],
              mlrCseSz: response[3] || [],
              mlrSitusCd: response[4] || [],
              lglEntyId: response[5] || [],
              glBuId: response[6] || [],
              glLocCd: response[7] || [],
              policy: response[8] || [],
            });
          } catch (error) {
            console.error('Error fetching initial data:', error);
          }
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <SelectInput
        label="Rebate Report Year"
        placeholder="Select One"
        model="rbtRptYr"
        isSearchable={false}
        isLoading={isDropDownLoading}
        validators={{ required: true }}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
          });
          setSelectBoxData({
            ...selectBoxData,
            runTypCd: result,
            runTypSeqNbr: [],
            srcSysCd: [],
            mlrCseSz: [],
            mlrSitusCd: [],
            lglEntyId: [],
            glBuId: [],
            glLocCd: [],
            policy: [],
          });
        }}
        options={selectBoxData.rbtRptYr}
      />

      <SelectInput
        label="Run Type"
        placeholder={selectBoxData.runTypCd.length === 0 ? '' : 'Select One'}
        model="runTypCd"
        isSearchable={false}
        isLoading={isDropDownLoading}
        validators={{ required: true }}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
          });
          setSelectBoxData({
            ...selectBoxData,
            runTypSeqNbr: result,
            srcSysCd: [],
            mlrCseSz: [],
            mlrSitusCd: [],
            lglEntyId: [],
            glBuId: [],
            glLocCd: [],
            policy: [],
          });
        }}
        options={selectBoxData.runTypCd}
        isDisabled={selectBoxData.runTypCd.length === 0}
      />
      <SelectInput
        label="Run Sequence"
        placeholder={
          selectBoxData.runTypSeqNbr.length === 0 ? '' : 'Select One'
        }
        model="runTypSeqNbr"
        isLoading={isDropDownLoading}
        validators={{ required: true }}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
          });
          setSelectBoxData({
            ...selectBoxData,
            srcSysCd: result,
            mlrCseSz: [],
            mlrSitusCd: [],
            lglEntyId: [],
            glBuId: [],
            glLocCd: [],
            policy: [],
          });
        }}
        options={selectBoxData.runTypSeqNbr}
        isDisabled={selectBoxData.runTypSeqNbr.length === 0}
      />

      <SelectInput
        label="Source System"
        placeholder={selectBoxData.srcSysCd.length === 0 ? '' : 'Select One'}
        model="srcSysCd"
        isLoading={isDropDownLoading}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
            srcSysCd: form.control._formValues.srcSysCd,
          });
          setSelectBoxData({
            ...selectBoxData,
            mlrCseSz: result,
            mlrSitusCd: [],
            lglEntyId: [],
            glBuId: [],
            glLocCd: [],
            policy: [],
          });
        }}
        options={selectBoxData.srcSysCd}
        isDisabled={selectBoxData.srcSysCd.length === 0}
      />

      <SelectInput
        label="MLR Case Size"
        placeholder={selectBoxData.mlrCseSz.length === 0 ? '' : 'Select One'}
        model="mlrCseSz"
        isLoading={isDropDownLoading}
        isSearchable={false}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
            mlrCseSz: form.control._formValues.mlrCseSz,
            srcSysCd: form.control._formValues.srcSysCd,
          });
          setSelectBoxData({
            ...selectBoxData,
            mlrSitusCd: result,
            lglEntyId: [],
            glBuId: [],
            glLocCd: [],
            policy: [],
          });
        }}
        options={selectBoxData.mlrCseSz}
        isDisabled={selectBoxData.mlrCseSz.length === 0}
      />

      <SelectInput
        label="Situs State"
        placeholder={selectBoxData.mlrSitusCd.length === 0 ? '' : 'Select One'}
        model="mlrSitusCd"
        isLoading={isDropDownLoading}
        isSearchable={false}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
            mlrCseSz: form.control._formValues.mlrCseSz,
            mlrSitusCd: form.control._formValues.mlrSitusCd,
            srcSysCd: form.control._formValues.srcSysCd,
          });
          setSelectBoxData({
            ...selectBoxData,
            lglEntyId: result,
            glBuId: [],
            glLocCd: [],
            policy: [],
          });
        }}
        options={selectBoxData.mlrSitusCd}
        isDisabled={selectBoxData.mlrSitusCd.length === 0}
      />

      <SelectInput
        label="Legal Entity"
        placeholder={selectBoxData.lglEntyId.length === 0 ? '' : 'Select One'}
        model="lglEntyId"
        isSearchable={false}
        isLoading={isDropDownLoading}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
            mlrCseSz: form.control._formValues.mlrCseSz,
            mlrSitusCd: form.control._formValues.mlrSitusCd,
            lglEntyId: form.control._formValues.lglEntyId,
            srcSysCd: form.control._formValues.srcSysCd,
          });
          setSelectBoxData({
            ...selectBoxData,
            glBuId: result,
            glLocCd: [],
            policy: [],
          });
        }}
        options={selectBoxData.lglEntyId}
        isDisabled={selectBoxData.lglEntyId.length === 0}
      />

      <SelectInput
        label="G/L Business Unit"
        placeholder={selectBoxData.glBuId.length === 0 ? '' : 'Select One'}
        model="glBuId"
        isSearchable={false}
        isLoading={isDropDownLoading}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
            mlrCseSz: form.control._formValues.mlrCseSz,
            mlrSitusCd: form.control._formValues.mlrSitusCd,
            lglEntyId: form.control._formValues.lglEntyId,
            glBuId: form.control._formValues.glBuId,
            srcSysCd: form.control._formValues.srcSysCd,
          });
          setSelectBoxData({
            ...selectBoxData,
            glLocCd: result,
            policy: [],
          });
        }}
        options={selectBoxData.glBuId}
        isDisabled={selectBoxData.glBuId.length === 0}
      />

      <SelectInput
        label="G/L Location"
        placeholder={selectBoxData.glLocCd.length === 0 ? '' : 'Select One'}
        model="glLocCd"
        isSearchable={false}
        isLoading={isDropDownLoading}
        onChange={async () => {
          const result = await fetchSelectBoxRows({
            rbtRptYr: form.control._formValues.rbtRptYr,
            runTypCd: form.control._formValues.runTypCd,
            runTypSeqNbr: form.control._formValues.runTypSeqNbr,
            mlrCseSz: form.control._formValues.mlrCseSz,
            mlrSitusCd: form.control._formValues.mlrSitusCd,
            lglEntyId: form.control._formValues.lglEntyId,
            glBuId: form.control._formValues.glBuId,
            glLocCd: form.control._formValues.glLocCd,
            srcSysCd: form.control._formValues.srcSysCd,
          });
          setSelectBoxData({
            ...selectBoxData,
            policy: result,
          });
        }}
        options={selectBoxData.glLocCd}
        isDisabled={selectBoxData.glLocCd.length === 0}
      />

      <SelectInput
        label="Policy#"
        placeholder={selectBoxData.policy.length === 0 ? '' : 'Select One'}
        model="Policy"
        isSearchable={false}
        isLoading={isDropDownLoading}
        options={selectBoxData.policy}
        isDisabled={selectBoxData.policy.length === 0}
      />

      <Button
        type="submit"
        isDisabled={isSearchDisabled}
        isLoading={isLoading}
        ariaLoadingLabel="Importing data"
        onClick={() => {
          return setLoading(!isLoading);
        }}
        style={{ marginTop: '15px', marginRight: '5px' }}
      >
        Search
      </Button>

      <Button
        onClick={() => {
          form.reset();
          localStorage.removeItem('aggregationOperationFormData');
          setSelectBoxData({
            ...selectBoxData,
            runTypCd: [],
            runTypSeqNbr: [],
            srcSysCd: [],
            mlrCseSz: [],
            mlrSitusCd: [],
            lglEntyId: [],
            glBuId: [],
            glLocCd: [],
            policy: [],
          });
        }}
        style={{ marginTop: '15px', marginLeft: '5px' }}
      >
        Reset
      </Button>
    </FormProvider>
  );
};
