import React, { useState } from 'react';
import { PageFooter } from '@abyss/web/ui/PageFooter';
import { Link } from '@abyss/web/ui/Link';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { Grid } from '@abyss/web/ui/Grid';

const PrivacySecurityModal = ({ openModal, onClose }) => {
  return (
    <Modal
      title="Privacy & Security"
      isOpen={openModal}
      onClose={onClose}
      titleAlign="center"
    >
      <Modal.Section>
        <Text size="$lg">
          This is a private web site containing proprietary and confidential
          information protected by Federal and State law.
        </Text>
        <br />
        <br />
        <Text size="$lg">
          Authorized users may use this web site for business purposes only.
        </Text>
        <br />
        <br />
        <Text size="$lg">
          Unauthorized or inappropriate access, use or distribution of the
          information contained within this web site is strictly prohibited and
          may result in the imposition of civil or criminal penalties.
        </Text>
      </Modal.Section>
    </Modal>
  );
};

export const Footer = () => {
  const [openModal, setOpenModal] = useState(false);
  const handlePrivacyClick = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  return (
    <Grid css={{ justifyContent: 'flex-start' }}>
      <Link
        href="/"
        onClick={handlePrivacyClick}
        css={{
          color: 'white',
          fontSize: '16px',
          fontWeight: 'normal',
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'underline',
            color: 'white',
          },
          paddingLeft: '24px',
        }}
      >
        Privacy & Security
      </Link>
      {/* <div style={{ marginRight: '550px' }} /> */}
      <Grid css={{ marginRight: '550px' }} />
      <PageFooter
        css={{
          background: '$interactive1',
          'abyss-page-footer-copyright': {
            fontSize: '16px',
            fontWeight: 'bold',
          },
        }}
        subFooterTitle="United HealthCare Services, Inc."
      />
      <PrivacySecurityModal
        openModal={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </Grid>
  );
};
