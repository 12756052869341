import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { Card } from '@abyss/web/ui/Card';

export const MlreLoader = () => {
  return (
    <LoadingOverlay
      loadingTitle="Loading..."
      loadingMessage="Please Wait.."
      ariaLoadingLabel=""
      isLoading
    >
      <Card
        css={{
          'abyss-card-root': {
            height: '250px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '$pageBackgroundColor',
          },
        }}
      />
    </LoadingOverlay>
  );
};
