import React, { useState, useEffect } from 'react';
import { Heading } from '@abyss/web/ui/Heading';
import {
  SubscriberForm,
  SubscriberDataTable,
} from '@src/components/Subscriber/index';
import {
  HelpContent,
  HelpContentResult,
} from '@src/components/Subscriber/utils';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';

import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import {
  FormOuterContainer,
  FormContainer,
  mediumSize,
} from '../../common/utils/StyleConstant';
import { SubHeader } from '../../common/components';

const Subscriber = () => {
  const [showSearchData, setShowSearchData] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const isMedium = useMediaQuery(mediumSize);
  const [breadItem, setBreadItem] = useState([
    { title: 'Subscriber Search', href: '/subscriber' },
  ]);
  const { navigate, getLocation } = useRouter();
  const { state, hash } = getLocation();
  const isTable = hash === '#table';
  const breadcrumsData = state?.breadcrumsData;

  const handleSearch = (data) => {
    navigate('#table');
    setBreadItem([
      { title: 'Subscriber Search', href: '/subscriber/' },
      {
        title: 'Subscribers List',
        href: '/subscriber#table',
      },
    ]);
    setShowSearchData(true);
    setSearchData(data);
  };

  useEffect(() => {
    if (!isTable) {
      setShowSearchData(false);
    } else if (breadcrumsData) {
      handleSearch(breadcrumsData);
    } else if (!searchData) {
      navigate('');
    }
  }, [isTable]);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItem}
                infoTitle={isTable ? 'Subscribers List' : 'Subscriber Search'}
                InfoContent={isTable ? HelpContentResult : HelpContent}
              />
              {!showSearchData ? (
                <FormOuterContainer>
                  <FormContainer medium={isMedium}>
                    <SubscriberForm onSearch={handleSearch} />
                  </FormContainer>
                </FormOuterContainer>
              ) : (
                <SubscriberDataTable searchData={searchData} />
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(Subscriber);
