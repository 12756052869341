import { Grid } from '@abyss/web/ui/Grid';
import React from 'react';
import { Text } from '@abyss/web/ui/Text';

export const DataSearchLabel = ({ searchLabel }) => {
  return (
    <Grid css={{ padding: '20px 30px' }}>
      {searchLabel.map((item) => {
        if (!item.value) {
          return '';
        }
        return (
          <React.Fragment>
            <Grid.Col css={{ marginBottom: '0px' }} span={6}>
              <Text color="#000000" fontWeight="bold">
                {item.fieldName}
                <Text fontWeight="bold" color="#000000">
                  : {item.value}
                </Text>
              </Text>
            </Grid.Col>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
