import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { CustomerPolicy } from '@src/components/customer/CustomerPolicy';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import { BASE_URL } from '@src/components/utils/endpoints';
import { MlreLoader } from '@src/common/MlreAbyssComponent';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import { HelpContent } from '@src/components/configuration/utils';
import axios from 'axios';

const AOGCustomer = () => {
  const { getLocation } = useRouter();
  const { state } = getLocation();
  const [apiData, setApiData] = useState([]);
  const [isFetched, setIsisFetched] = useState(false);
  const payloadData = state?.payload;
  const breadcrums = state?.breadcrums;
  const [breadItem, setBreadItem] = useState(breadcrums || []);
  const isPolicy = state.isPolicyList ? state.isPolicyList : null;

  const fetchData = async () => {
    try {
      const { data, error } = await axios.post(
        `${BASE_URL}customer/search-customer`,
        payloadData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!error) {
        setApiData(data);
      }
      setIsisFetched(true);
    } catch (error) {
      console.log(error);
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (payloadData && !isPolicy) {
      fetchData();
    }
  }, [payloadData, isPolicy]);

  useEffect(() => {
    if (isPolicy) {
      setApiData(state?.SearchData);
      setIsisFetched(true);
    }
  }, [isPolicy]);

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              {isFetched ? (
                <React.Fragment>
                  <SubHeader
                    breadItem={[
                      ...breadItem,
                      {
                        title: 'Customer Policy',
                        href: '/aog-customer/',
                      },
                    ]}
                    infoTitle="Customer Policy"
                    InfoContent={HelpContent}
                  />
                  <CustomerPolicy
                    SearchData={apiData}
                    breadItem={[
                      ...breadItem,
                      {
                        title: 'Customer Policy',
                        href: '/aog-customer/',
                        state: {
                          payload: payloadData,
                          breadcrums: breadItem,
                        },
                      },
                    ]}
                  />
                </React.Fragment>
              ) : (
                <MlreLoader />
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default withAuth(AOGCustomer);
