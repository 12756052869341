import React, { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { InteractionStatus } from '@azure/msal-browser';
import useAuth from './useAuth';

const Login = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { navigate } = useRouter();
  const { getToken } = useAuth();

  const getTokens = async () => {
    try {
      await getToken();
      navigate('/');
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  useEffect(() => {
    const handleLogin = async () => {
      try {
        await instance.loginRedirect();
      } catch (error) {
        console.error(error);
      }
    };

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      handleLogin();
    }
    if (isAuthenticated) {
      getTokens();
    }
  }, [isAuthenticated, inProgress, instance]);

  return <React.Fragment />;
};

export default Login;
