/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Flex } from '@abyss/web/ui/Flex';
import axios from 'axios';
import { MlreLoader } from '@src/common/MlreAbyssComponent';
import {
  ALERT,
  CHECK_REISSUE_STATUS,
  PLACEHOLDER,
  RESET,
  SEARCH,
} from './CheckReissueContant';
import styles from '../../styles/styles.module.css';
import { BASE_URL } from '../utils/endpoints';

export const CheckReissueApprovalForm = () => {
  const { navigate } = useRouter();
  const form = useForm();
  const [dropdownData, setDropdownData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropDownLoading, setIsDropDownLoading] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}checkReissue/dropdown`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
            'Content-Type': 'application/json',
          },
        });
        setDropdownData(res.data);
        setIsDropDownLoading(false);
      } catch (error) {
        alert(
          'An error occurred while processing your request. Please try again later.'
        );
      }
    };

    fetchData();
  }, []);

  const handleSearch = async (data) => {
    const selectedStatus = data.reissue_status;

    setIsSearchDisabled(true);
    try {
      let response;
      switch (selectedStatus) {
        case 'P':
          response = await axios.post(
            `${BASE_URL}checkReissue/count`,
            { atr2: 'Pending Reissue Approval' },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );
          break;
        case 'A':
          response = await axios.post(
            `${BASE_URL}checkReissue/count`,
            { atr2: 'Reissue Approved' },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );
          break;
        case 'R':
          response = await axios.post(
            `${BASE_URL}checkReissue/count`,
            { atr2: 'Reissue Rejected' },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );
          break;
        case 'PR':
          response = await axios.post(
            `${BASE_URL}checkReissue/count`,
            { atr2: 'Pending Redistribute Approval' },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );
          break;
        default:
          response = await axios.post(
            `${BASE_URL}checkReissue/count`,
            { atr2: 'Pending Reissue Approval' },
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                'Content-Type': 'application/json',
              },
            }
          );
          break;
      }

      const res = response.data?.message;
      const count = response.data;

      if (
        res ===
        'No Records found for the entered search criteria. Please try again'
      ) {
        alert(res);
        setIsSearchDisabled(false);
        setIsLoadingSearch(false);
      } else {
        switch (selectedStatus) {
          case 'P':
            navigate('/pending-reissue-approval', {
              state: { selectedStatus, count },
            });
            break;
          case 'A':
            navigate('/reissue-approved', { state: { selectedStatus, count } });
            break;
          case 'R':
            navigate('/reissue-rejected', { state: { selectedStatus, count } });
            break;
          case 'PR':
            navigate('/pending-redistribute-approval', {
              state: { selectedStatus, count },
            });
            break;
          default:
            navigate('/pending-reissue-approval', {
              state: { selectedStatus, count },
            });
            break;
        }
        setIsSearchDisabled(false);
      }
    } catch (error) {
      alert(
        'An error occurred while processing your request. Please try again later.'
      );
    }
    // setIsLoading(false);
  };

  const menuItems =
    dropdownData.length > 0
      ? dropdownData.map((item) => {
          return {
            value: item.code,
            label: item.description,
          };
        })
      : [];

  if (isLoading) {
    return <MlreLoader />;
  }
  return (
    <FormProvider state={form} onSubmit={handleSearch}>
      <SelectInput
        label={CHECK_REISSUE_STATUS}
        placeholder={PLACEHOLDER}
        model="reissue_status"
        isLoading={isDropDownLoading}
        width={380}
        options={menuItems}
      />
      <Flex direction="row" justify="center" className={styles.Layout}>
        <Button
          type="submit"
          isDisabled={isSearchDisabled}
          isLoading={isLoadingSearch}
          ariaLoadingLabel="Importing data"
          onClick={() => {
            return setIsLoadingSearch(!isLoadingSearch);
          }}
          className={styles.Button}
        >
          {SEARCH}
        </Button>
        <Button
          onClick={() => {
            form.reset();
          }}
          className={styles.Button}
        >
          {RESET}
        </Button>
      </Flex>
    </FormProvider>
  );
};
