/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from '@abyss/web/ui/Link';
import { MlreDataTable, MlreLoader } from '@src/common/MlreAbyssComponent';
import { useRouter } from '@abyss/web/hooks/useRouter';

export const PaymentSearchResults = ({ paymentData, breadcrums }) => {
  const [isLoading, setLoading] = useState(true);
  const { navigate } = useRouter();

  const [paymentTableData, setPaymentTableData] = useState(() => {
    const savedPaymentTableData = localStorage.getItem('paymentTableData');
    return savedPaymentTableData ? JSON.parse(savedPaymentTableData) : [];
  });

  const [breadcrumbs, setBreadcrumbs] = useState(breadcrums || []);

  useEffect(() => {
    if (paymentData && paymentData.length > 0) {
      setPaymentTableData(paymentData);
      setLoading(false);
      localStorage.setItem('paymentTableData', JSON.stringify(paymentData));
    }
  }, [paymentData]);

  useEffect(() => {
    const savedPaymentTableData = localStorage.getItem('paymentTableData');
    const savedBreadcrumbs = localStorage.getItem('breadcrumbs');

    if (savedPaymentTableData) {
      setPaymentTableData(JSON.parse(savedPaymentTableData));
    }

    if (savedBreadcrumbs) {
      setBreadcrumbs(JSON.parse(savedBreadcrumbs));
    }
  }, []);

  useEffect(() => {
    if (breadcrumbs && breadcrumbs.length > 0) {
      localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
    }
  }, [breadcrumbs]);

  const handleRedirect = (paymentRowData) => {
    const breadItem =
      breadcrumbs && breadcrumbs.length > 0
        ? [...breadcrumbs]
        : [
            { title: 'Payment Search', href: '/Payment' },
            {
              title: 'Payment Search Results',
              href: '/Payment#table',
              state: { breadcrumData: paymentTableData },
            },
          ];
    navigate('/payment-detail', {
      state: { selectedPaymentData: paymentRowData, breadItem },
    });
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Check Number',
        accessor: 'pmtDocNbr',
        disableSortBy: true,
        width: 50,
        Cell: ({ value, row }) => {
          return (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleRedirect(row.original);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Policy Number',
        accessor: 'srcSysContrId',
        disableSortBy: true,
        width: 50,
      },
      {
        Header: 'Policy Type',
        accessor: 'atr5',
        disableSortBy: true,
      },
      {
        Header: 'Legal Entity',
        accessor: 'lglEntyId',
        disableSortBy: true,
        width: 50,
      },
      {
        Header: 'MLRE Case Size',
        accessor: 'mlrCseSz',
        disableSortBy: true,
      },
      {
        Header: 'Situs State',
        accessor: 'mlrSitusStCd',
        disableSortBy: true,
        width: 50,
      },
      {
        Header: 'Payee Name',
        accessor: 'payeeName',
        disableSortBy: true,
        width: 50,
      },
      {
        Header: 'Address',
        accessor: 'address',
        disableSortBy: true,
        width: 50,
        align: 'center',
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
      },
      {
        Header: 'Check Status',
        accessor: 'payStsCd',
        disableSortBy: true,
      },
      {
        Header: 'Post Mark date',
        accessor: 'payPstMrkDt',
        disableSortBy: true,
        Cell: ({ value }) => {
          const payData = value ? value.substring(0, value.indexOf('T')) : '';
          return payData;
        },
      },
    ];
  }, []);

  // if (isLoading) return <MlreLoader />;

  return (
    <React.Fragment>
      {isLoading ? (
        <MlreLoader />
      ) : (
        <MlreDataTable data={paymentTableData} columns={columns} />
      )}
    </React.Fragment>
  );
};
