import React from 'react';

export const HelpContent = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          In Medical Loss Ratio Rebate Tool, Payment of Rebate amount is done in
          2 ways, either through Check or by Premium Credit.
        </li>
        <br />
        <li>
          User can search the Check number or Invoice number by using this
          screen.
        </li>
      </ul>
    </div>
  );
};

// export const HelpContentResult = () => {
//   return (
//     <React.Fragment>
//       <Text size="$lg">
//         This screen displays the Check Number or Invoice Number that user has
//         entered and related information such as Payee Name, Address, Check
//         Status and Post Mark Date.
//       </Text>
//       <br />
//       <br />
//       <Text size="$lg">
//         By clicking on the Check Number or Invoice Number, the user can navigate
//         to the details of the check or invoice.
//       </Text>
//     </React.Fragment>
//   );
// };
export const HelpContentResult = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen displays the Check Number or Invoice Number that user has
          entered and related information such as Payee Name, Address, Check
          Status and Post Mark Date.
        </li>
        <br />
        <li>
          By clicking on the Check Number or Invoice Number, the user can
          navigate to the details of the check or invoice.
        </li>
      </ul>
    </div>
  );
};

// export const HelpContentPaymentDetail = () => {
//   return (
//     <React.Fragment>
//       <Text size="$lg">
//         This screen displays the check details such as Number, date,
//         Distribution Status, the Address the check is sent, payment status &
//         Post Mark Date..
//       </Text>
//       <br />
//       <br />
//       <Text size="$lg">
//         User can Void or Reissue the check if require. A separate workflow will
//         be triggered for approval.
//       </Text>
//       <br />
//       <br />
//       <Text size="$lg">
//         Validations here refers to all checks belonging to this policy are
//         employer checks. Status of all these checks should be outstanding/
//         Dormant to void the check. and should be Void for conversion/
//         Redistribution to subscribers
//       </Text>
//     </React.Fragment>
//   );
// };
export const HelpContentPaymentDetail = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen displays the check details such as Number, date,
          Distribution Status, the Address the check is sent, payment status &
          Post Mark Date.
        </li>
        <br />
        <li>
          User can Void or Reissue the check if require. A separate workflow
          will be triggered for approval.
        </li>
        <br />

        <li>
          Validations here refers to all checks belonging to this policy are
          employer checks. Status of all these checks should be outstanding/
          Dormant to void the check. and should be Void for conversion/
          Redistribution to subscribers
        </li>
      </ul>
    </div>
  );
};

export const HelpContentPaymentRedistribute = () => {
  return (
    <React.Fragment>
      <div>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
          <li>
            This screen displays the check numbers that are issued for the
            policy along with the Legal Entity, MLR Case Size, Situs State and
            Check Status
          </li>
          <br />
          <li>Select "Submit" button to void all the sibling checks</li>
          <br />
          <li>The Submit button is disabled in the following instances:</li>
          <br />
          <div style={{ alignItems: 'center', justifyContent: 'center' }}>
            <p>o Sibling checks fail validations</p>
            <p>o Subscriber Payee Type</p>
          </div>
          <br />
          <li>
            Select "Dismiss" button to navigate back to the "Payment Details"
            screen
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};
