import React from 'react';

export const CheckingReissueIcon = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen allows the user to search different Check Reissue Status.
          They are Pending Reissue Approval, Reissue Approved & Reissue
          Rejected.
        </li>
        <li>
          User can select one of the status and can view the list of check
          numbers following the same status category .
        </li>
      </ul>
    </div>
  );
};

export const PendingReissueApprovalIcon = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen displays the list of check numbers that follows the status
          category selected in previous screen.
        </li>
        <li>
          User can Approve or reject the Check Reissue request for the checks
          where the Check Reissue Status is "Pending Reissue Approval".
        </li>
      </ul>
    </div>
  );
};

export const PendingApprovalResultIcon = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen displays the list of Policy numbers that follows the
          status category selected in previous screen.
        </li>
        <li>User can Approve or Reject the Check Redistribution request.</li>
      </ul>
    </div>
  );
};
