import React from 'react';

export const HelperContent = () => {
  return (
    <div>
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        <li>
          This screen displays the list of keys and values associated with the
          parameter selected in the previous screen.
        </li>
        <li>
          The user can click on the EDIT button to modify the values. User Can
          edit all the fields except the Rebate Reporting year, Parameter Type &
          Parameter Category.
        </li>
      </ul>
    </div>
  );
};
