import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import React, { useEffect, useState } from 'react';
import useAuth from '@src/auth/useAuth';
import env from '../../../env.json';

type Props = {
  row: any;
  setReIssueStatus: () => void;
  reIssueStatus: any;
};

const Row = ({
  row,
  reset,
  setReset,
  setCheckValue,
  setCheckStatus,
}: Props) => {
  const [value, setValue] = useState('Reject');
  const [isChecked, setIsChecked] = useState(true);

  const [isRejectDisabled, setIsRejectDisabled] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { userRolesGroups } = useAuth();

  useEffect(() => {
    const hasUpdateAccess = env.Check_Reissue_Status_Reissue_Rejected.some(
      (role) => {
        return userRolesGroups.includes(role);
      }
    );

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups]);

  useEffect(() => {
    if (reset) setValue('Reject');
  }, [reset]);

  const handleChecked = (e) => {
    setReset(false);
    setValue(e.target.checked);
    // setStatusValue('Approve');
    setIsRejectDisabled(false);
    setCheckValue(row.original);
    if (e.target.value === 'Approve') {
      setCheckStatus('Approve');
    } else {
      setIsRejectDisabled(true);
      setCheckStatus('reject');
      setIsChecked(true);
      setValue(e.target.value);
      setIsRejectDisabled(true);
    }
    // setIsRejectDisabled(true);
    setIsChecked(true);
    setValue(e.target.value);
  };

  return (
    <RadioGroup
      label="Radio Sandbox"
      value={value}
      display="row"
      hideLabel
      onChange={handleChecked}
      model={`radio_reset_${row.original.payDocNbr}`}
    >
      <RadioGroup.Radio
        label={<span style={{ color: '#000000' }}>Approval</span>}
        // value={value}
        value="Approve"
        isDisabled={isButtonDisabled}
        // value="Approve"
      />
      <RadioGroup.Radio
        label={<span style={{ color: '#000000' }}>Reject</span>}
        value="Reject"
        isDisabled={isRejectDisabled}
        // checked={isChecked}
        // value="Reject"
      />
    </RadioGroup>
  );
};

export default Row;
