/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Text } from '@abyss/web/ui/Text';
import { Heading } from '@abyss/web/ui/Heading';

export const NameLookUpTableModal = ({
  isOpen,
  onClose,
  searchFieldName,
  nameList,
  onSelectValue,
  sourceSystem,
  inputName,
}) => {
  const [selectedName, setSelectedName] = useState('');

  const handleSelect = (searchFieldName, value) => {
    onSelectValue(searchFieldName, value); // Emit the event with the selected value and field name
  };

  useEffect(() => {
    setSelectedName('');
  }, [searchFieldName]);

  useEffect(() => {
    if (isOpen && inputName !== selectedName) {
      setSelectedName('');
    }
  }, [isOpen]);

  const displayFieldName =
    searchFieldName == 'lastName' ? 'Last Name' : 'First Name';
  return (
    <Modal
      title=""
      isOpen={isOpen}
      onClose={() => {
        // setSelectedName('');
        onClose();
      }}
      size="lg"
      closeOnClickOutside={false}
    >
      <Modal.Section>
        <Heading textAlign="center">{displayFieldName} Look Up</Heading>
        <React.Fragment>
          <Grid css={{ padding: '20px 30px' }}>
            <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 8, md: 3 }}>
              <Text color="$interactive1" fontWeight="bold">
                Source System
              </Text>
            </Grid.Col>
            <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 4, md: 3 }}>
              <Text fontWeight="bold">: {sourceSystem}</Text>
            </Grid.Col>
            <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 8, md: 3 }}>
              <Text color="$interactive1" fontWeight="bold">
                {displayFieldName} Selected
              </Text>
            </Grid.Col>
            <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 4, md: 3 }}>
              <Text fontWeight="bold">: {selectedName}</Text>
            </Grid.Col>
          </Grid>
        </React.Fragment>
      </Modal.Section>

      <Modal.Section>
        <Box align="center" color="white" css={{ paddingTop: '0px' }}>
          <Box
            width="50%"
            align="center"
            padding="$sm"
            color="$primary1"
            css={{ border: '0.1px solid white' }}
          >
            <b style={{ color: 'white' }}>{displayFieldName}</b>
          </Box>
          <Box
            align="center"
            color="white"
            width="50%"
            css={{ padding: '0px', height: '200px', overflow: 'overlay' }}
          >
            {nameList.map((name) => {
              return (
                <Box
                  padding="$sm"
                  css={{
                    border: '0.1px solid white',
                    height: '20%',
                    backgroundColor:
                      selectedName === name ? '$interactive2' : '$gray2',
                    color: selectedName === name ? 'white' : 'black',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '$interactive2',
                      color: 'white',
                    },
                  }}
                  onClick={() => {
                    setSelectedName(name);
                  }}
                >
                  {name}
                </Box>
              );
            })}
          </Box>
        </Box>
        <Layout.Group alignLayout="center">
          <Button
            onClick={() => {
              handleSelect(searchFieldName, selectedName);
              onClose();
            }}
          >
            OK
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              // setSelectedName('');
              onClose();
            }}
          >
            Cancel
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};

export const ApprovePaymentModal = ({ isOpen, onClose }) => {
  const paymentRreleaseInfo = [
    { fieldName: 'Release Reporting year', value: '2017' },
    { fieldName: 'Legal Entity', value: '9865' },
    { fieldName: 'Run Type', value: 'FINAL' },
    { fieldName: 'Status ', value: 'AB' },
    { fieldName: 'Run Sequence', value: '001' },
    { fieldName: 'MLR Case Size', value: 'AB' },
  ];
  return (
    <Modal
      title="Approval for Release Payment"
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      titleAlign="center"
      css={{
        'abyss-modal-content-container': {
          background: '$pageBackgroundColor',
        },
      }}
    >
      <Modal.Section>
        <Grid css={{ padding: '20px 30px' }}>
          {paymentRreleaseInfo.map((item) => {
            return (
              <React.Fragment>
                <Grid.Col
                  css={{ paddingBottom: '0px' }}
                  span={{ xs: 8, md: 4 }}
                >
                  <Text color="$interactive1" fontWeight="bold">
                    {item.fieldName}
                  </Text>
                </Grid.Col>
                <Grid.Col
                  css={{ paddingBottom: '0px' }}
                  span={{ xs: 4, md: 2 }}
                >
                  <Text fontWeight="bold">: {item.value}</Text>
                </Grid.Col>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid css={{ margin: '0px 50px', borderBottom: '1px solid' }}>
          <br />
        </Grid>

        <Grid css={{ padding: '20px 30px' }}>
          <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 6, md: 3 }}>
            <Text color="$interactive1" fontWeight="bold">
              Approver ID
            </Text>
          </Grid.Col>
          <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 6, md: 3 }}>
            <Text fontWeight="bold">: 123445</Text>
          </Grid.Col>

          <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 6, md: 3 }}>
            <Text color="$interactive1" fontWeight="bold">
              Approval Date
            </Text>
          </Grid.Col>
          <Grid.Col css={{ paddingBottom: '0px' }} span={{ xs: 6, md: 3 }}>
            <DateInput
              label=""
              subText=""
              hideLabel
              value="01/01/2024"
              onChange={() => {}}
            />
          </Grid.Col>
        </Grid>
      </Modal.Section>

      <Modal.Section>
        <Layout.Group alignLayout="center">
          <Button
            onClick={() => {
              alert('DONE');
            }}
          >
            Submit
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};
