import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { useRouter } from '@abyss/web/hooks/useRouter';

import { loginRequest } from './authConfig';

const useAuth = () => {
  const { instance, accounts } = useMsal();
  const [roles, setRoles] = useState<string[] | undefined>([]);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [idToken, setIdToken] = useState<string | undefined>(undefined);
  const [readOnly, setReadOnly] = useState(false);
  const [msid, setMsid] = useState('');
  const [userName, setUserName] = useState('');
  const [userRolesGroups, setUserRolesGroups] = useState([]);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const { navigate } = useRouter();

  const getRemainingTime = (token: string | undefined) => {
    if (!token) return 0;
    try {
      const decodedToken = jwtDecode(token);

      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp - currentTime;
    } catch (error) {
      console.error('Invalid token specified:', error);
      return 0;
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    instance.logoutRedirect();
    setIsLoggedOut(true);
  };

  const getToken = async () => {
    if (isLoggedOut || !accounts || accounts.length === 0) {
      return; // Prevent further actions if logged out
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      setAccessToken(response?.accessToken);
      setIdToken(response?.idToken);
      localStorage.setItem('jwttoken', response?.idToken);
    } catch (error) {
      console.error('Token acquisition failed', error);
    }
  };

  const handleTokenExpiry = async () => {
    if (isLoggedOut || isInactive) return;

    if (accounts && accounts.length > 0) {
      const account = accounts[0];

      const localToken = localStorage.getItem('jwttoken');

      const idTokenRemainingTime = getRemainingTime(localToken);

      if (localToken != null && idTokenRemainingTime < 1200) {
        try {
          const response = await instance.acquireTokenSilent({
            scopes: ['User.Read'],
            account,
            forceRefresh: true,
            refreshTokenExpirationOffsetSeconds: 3600,
          });

          setIdToken(response.account.idToken);
          await getToken();
        } catch (error) {
          console.error('ID token refresh failed', error);
        }
      }
    }
  };

  // Effect to store ID token in local storage
  useEffect(() => {
    if (idToken !== null && idToken !== undefined && idToken !== '') {
      localStorage.setItem('jwttoken', idToken);
    }
  }, [idToken]);

  useEffect(() => {
    getToken(); // Attempt to get the token if it exists

    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      setRoles(account.idTokenClaims?.roles);
      setUserRolesGroups(account?.idTokenClaims?.groups);
      setMsid(account?.idTokenClaims.MSID);
      setIdToken(account?.idToken);

      if (account.idTokenClaims?.roles?.includes('Read')) {
        setReadOnly(true);
      }

      if (account.name) {
        const formattedName = account.name.includes(',')
          ? account.name.split(', ').reverse().join(' ')
          : account.name;
        setUserName(formattedName);
      }
    }

    // Set up event listeners for user activity
    const handleActivity = () => {
      sessionStorage.setItem('inactivityStartTime', Date.now().toString());
      // setLastActivityTime(Date.now());
      setIsInactive(false);
    };
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Set interval to check token expiry and inactivity every minute
    const interval = setInterval(() => {
      const activityTime = sessionStorage.getItem('inactivityStartTime');
      // Check for inactivity
      if (Date.now() - parseInt(activityTime) > 30 * 60 * 1000) {
        setIsInactive(true);
        const userConfirmed = window.confirm(
          'You have been inactive for a while. Do you want to log out ?'
        );

        if (userConfirmed) {
          // User chose to log out
          handleLogout();
        } else {
          // User chose to continue the session
          setIsInactive(false); // Reset inactivity state
          sessionStorage.setItem('inactivityStartTime', Date.now().toString()); // Reset the inactivity timer
          // Redirect to home page (assuming you have a function for this)
          // navigate('/');
        }
      }
      handleTokenExpiry();
    }, 1 * 60 * 1000); // Check every ten minutes

    // Cleanup function
    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      // Remove the listener
    };
  }, [instance, accounts]);

  return {
    roles,
    accessToken,
    readOnly,
    msid,
    userName,
    userRolesGroups,
    idToken,
    getToken,
  };
};

export default useAuth;
