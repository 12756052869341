import React, { useEffect, useState } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { MlreDataTable, MlreLoader } from '@src/common/MlreAbyssComponent';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import * as XLSX from 'xlsx';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { Flex } from '@abyss/web/ui/Flex';
import { Label } from '@abyss/web/ui/Label';
import { Text } from '@abyss/web/ui/Text';
import { useRouter } from '@abyss/web/hooks/useRouter';
import useAuth from '@src/auth/useAuth';
import axios from 'axios';
import { SubHeader } from '@src/common/components';
import { WrapperDiv, mediumSize } from '../../common/utils/StyleConstant';
import { subscriberCLabels } from './constants';
import { BASE_URL } from '../utils/endpoints';
import { SubscriberRevenueNoSubscriber } from './SubscriberHelpIcon';

const RevenueNoSubsciber = () => {
  const isMedium = useMediaQuery(mediumSize);
  const { navigate, getLocation } = useRouter();
  const { state } = getLocation();
  const [tableData, setTableData] = useState(state?.response);
  const payload = state?.searchData;
  const { token } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [breadItem, setBreadItem] = useState([
    { title: 'Subscriber Clone Search', href: '/subscriber-clone/' },
    {
      title: 'Revenue No Subscriber',
      href: '/subscriber-clone-result/',
      state: {
        response: state.response,
        radioValue: state.radioValue,
      },
    },
  ]);

  useEffect(() => {
    if (tableData?.length > 0) {
      setLoading(false);
    }
  }, []);


  const columns = React.useMemo(() => {
    return [
      {
        Header: subscriberCLabels.SOURCE_SYSTEM,
        accessor: 'srcSysCd',
      },
      {
        Header: subscriberCLabels.POLICY_NUMBER,
        accessor: 'srcSysCustContrId',
      },
      {
        Header: subscriberCLabels.LEGAL_ENTITY,
        accessor: 'lglEntyId',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.CASE_SIZE,
        accessor: 'mlrecsSz',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.SITUS_STATE,
        accessor: 'mlrestCd',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.BUSINESS_UNIT,
        accessor: 'glBuId',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.LOCATION,
        accessor: 'glLocCd',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.REBATE_INDICATOR,
        accessor: 'inclInRbtInd',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.SUBSCRIBER_COUNT,
        accessor: 'sbcrCnt',
        disableSortBy: false,
      },
      {
        Header: subscriberCLabels.CLONE_SELECTED,
        accessor: 'is_clone',
        disableSortBy: false,
        Cell: ({ value }) => {
          return value ? 'Y' : 'N';
        },
      },
      {
        Header: subscriberCLabels.CLONE,
        accessor: 'clone',
        disableSortBy: false,
        Cell: ({ row }) => {
          return (
            <React.Fragment>
              <Button
                onClick={() => {
                  const searchDataPay = {
                    rebateYear: state?.searchData?.rebateYear,
                    srcSysCd: row?.original?.srcSysCd,
                    lglEntyId: row?.original?.lglEntyId,
                    mlrestCd: row?.original?.mlrestCd,
                    mlrecsSz: row?.original?.mlrecsSz,
                    mlrestcd: row?.original?.mlrestCd,
                    srcSysCustContrId: row?.original?.srcSysCustContrId,
                  };
                  navigate('/ssaog', {
                    state: {
                      response: tableData,
                      searchData: searchDataPay,
                      breadItem,
                      radioValue: state.radioValue,
                    },
                  });
                }}
              >
                {subscriberCLabels.SELECT}
              </Button>
            </React.Fragment>
          );
        },
      },
    ];
  }, []);

  const exportToExcel = () => {
    const exportData = tableData.map((item) => {
      const formattedRow = {};
      columns.forEach((col) => {
        if (col.accessor === 'clone') {
          return;
        }
        if (col.accessor === 'is_clone') {
          formattedRow[col.Header] = item[col.accessor] === 1 ? 'Y' : 'N';
        } else {
          formattedRow[col.Header] = item[col.accessor];
        }
      });
      return formattedRow;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const exportFileName = `SubscriberCloneDetails.xlsx`;
    XLSX.writeFile(wb, exportFileName);
  };

  return (
    <React.Fragment>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={breadItem}
                infoTitle="Revenue No Subscriber"
                InfoContent={SubscriberRevenueNoSubscriber}
              />
              <Flex style={{ marginTop: '10px', marginBottom: '30px' }}>
                <Label size="md">{subscriberCLabels.REBATE_REPORT_YEAR}</Label>
                <Text
                  size="md"
                  css={{ marginLeft: '25px', marginRight: '25px' }}
                >
                  :2023
                </Text>
                <Label
                  size="md"
                  style={{ marginLeft: '25px', marginRight: '25px' }}
                >
                  {subscriberCLabels.RUN_TYPE}
                </Label>
                <Text
                  size="md"
                  css={{ marginLeft: '25px', marginRight: '25px' }}
                >
                  :F
                </Text>
              </Flex>
              {isLoading ? (
                <MlreLoader />
              ) : (
                <Grid css={{ justifyContent: 'center' }}>
                  <MlreDataTable data={tableData} columns={columns} />
                  <Button onClick={exportToExcel}>Download To Excel</Button>
                </Grid>
              )}
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};
export default withAuth(RevenueNoSubsciber);
