import React, { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import { useRouter } from '@abyss/web/hooks/useRouter';
import { InteractionStatus } from '@azure/msal-browser';

const withAuth = (WrappedComponent) => {
  const AuthenticatedComponent = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { navigate } = useRouter();

    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      navigate('/login');
    }

    return isAuthenticated ? <WrappedComponent /> : null;
  };

  return AuthenticatedComponent;
};

export default withAuth;
