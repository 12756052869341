/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Box } from '@abyss/web/ui/Box';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { Heading } from '@abyss/web/ui/Heading';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import axios from 'axios';
import useAuth from '@src/auth/useAuth';
import styles from '../../styles/styles.module.css';
import { CANCEL, SUBMIT } from './CustomerConstants';
import { BASE_URL } from '../utils/endpoints';

export const CustomerHoldStatusModal = ({
  isOpen,
  onClose,
  SearchData,
  holdStatus,
  msid,
}) => {
  const form = useForm({
    defaultValues: {
      textForm: 'Form Value',
    },
  });
  const { token } = useAuth();

  const getCurrentDate = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const onSubmit = (data) => {
    console.log('data', data);
  };

  const formData = form.getValues();

  const handleUpdate = async () => {
    const payload = {
      mstrCustContrHldInd: 'Y',
      rsnForHldDesc: formData?.rows,
      custContrId: SearchData[0]?.custContrId,
      modifiedDate: SearchData[0]?.modifiedDate
    };

    const result = await axios
      .post(`${BASE_URL}customer/hold-payment-status`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response) {
          alert(response.data);
          onClose();
          form.reset();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      title=""
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnClickOutside={false}
    >
      <Modal.Section>
        <Box color="#A5DFE7">
          <Heading
            textAlign="center"
            color="#000000"
            display="h6"
            css={{ background: '#A5DFE7' }}
          >
            Hold Payment For Policy
          </Heading>
        </Box>

        <div>
          <Divider margin="$md" height={4} color="green" />
        </div>
        <React.Fragment>
          <Text color="#000000" size={16} fontWeight={800}>
            Policy
          </Text>
          <Divider color="green" />
          <Text color="#000000" size={16} fontWeight={800}>
            Please Specify the reason for Hold
          </Text>
          <FormProvider state={form} onSubmit={onSubmit}>
            <TextInputArea rows={10} label="Notes" model="rows" hideLabel />
            <React.Fragment>
              <Grid css={{ padding: '20px 30px' }}>
                <Grid.Col>
                  <Text color="#000" fontWeight="bold">
                    Payment Hold by
                  </Text>
                </Grid.Col>
                <Grid.Col>
                  <Text color="#000" fontWeight="bold">
                    :{msid}
                  </Text>
                </Grid.Col>
                <Grid.Col css={{ marginLeft: '100px' }}>
                  <Text color="#000" fontWeight="bold">
                    Hold on Date
                  </Text>
                </Grid.Col>
                <Grid.Col>
                  <Text color="#000" fontWeight="bold">
                    :{getCurrentDate()}
                  </Text>
                </Grid.Col>
              </Grid>
            </React.Fragment>
          </FormProvider>
        </React.Fragment>
      </Modal.Section>

      <Modal.Section>
        <Flex direction="row" justify="center" className={styles.Layout}>
          <Button
            type="submit"
            className={styles.Button}
            onClick={handleUpdate}
          >
            {SUBMIT}
          </Button>

          <Button className={styles.Button} onClick={onClose}>
            {CANCEL}
          </Button>
        </Flex>
      </Modal.Section>
    </Modal>
  );
};
