export const CHECK_REISSUE_STATUS: string = 'Check Reissue Status';
export const PLACEHOLDER: string = 'Pending Reissue Approval';
export const SEARCH: string = 'Search';
export const SAVE: string = 'Save';

export const RESET: string = 'Reset';
export const ALERT: string =
  'No Records found for the entered search criteria. Please try again';
export const PENDING_REISSUE_APPROVAL: string = 'Pending Reissue Approval';
export const REISSUE_APPROVED: string = ' Reissue Approved';
export const REISSUE_REJECTED: string = 'Reissue Rejected';
export const PENDING_REDISTRIBUTE_APPROVAL: string =
  'Pending Redistribute Approval';
