import React, { useEffect, useState } from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { Flex } from '@abyss/web/ui/Flex';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Grid } from '@abyss/web/ui/Grid';
import { Footer } from '@src/components/Footer';
import { Header } from '@src/components/Header';
import { Hamburger } from '@src/components/Hamburger';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import {
  HeaderDiv,
  BodyContainer,
  BodyPageContainer,
} from '@src/common/customStyle';
import withAuth from '@src/auth/withAuth';
import { SubHeader } from '@src/common/components';
import { HelpContent } from '@src/components/Subscriber/utils';
import useAuth from '@src/auth/useAuth';
import axios, { Axios } from 'axios';
import styles from '../../styles/styles.module.css';
import {
  BUSINESS_UNIT,
  ENTITY_ID,
  ENTITY_NAME,
  EDIT_ENTITY_CONFIRMATION,
  SAVE,
  RESET,
  CANCEL,
  ID_VALIDATION,
  UNIT_VALIDATION,
  CANCEL_UPDATE,
  NOCHANGE_VALIDATION,
} from './LegalEntityConstants';
import { BASE_URL } from '../utils/endpoints';
import {
  FormOuterContainer,
  FormContainer,
  mediumSize,
} from '../../common/utils/StyleConstant';
import { AddHelpContent } from './LegalEntityHelpIcon';
import env from '../../../env.json';

const UpdateLegalEntity = () => {
  const { msid } = useAuth();
  const [data, setData] = useState('');
  const { userRolesGroups } = useAuth();
  const [legalEntityName, setLegalEntityName] = useState('');
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const isMedium = useMediaQuery(mediumSize);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSaveButtonDisabled, setSaveIsButtonDisabled] = useState(false);

  const url = `${BASE_URL}legalEntity/update-legal-entity`;

  const { getLocation } = useRouter();
  const { state } = getLocation();
  const { navigate } = useRouter();
  const leID = state.rowValue.LglEntyId;
  const leName = state.rowValue.LglEntyNm;
  const bUnit = state.rowValue.Atr1;
  const { modifiedDate } = state.rowValue;

  const rowDefaultValue = {
    LglEntyId: leID,
    LglEntyNm: leName,
    Atr1: bUnit,
  };

  const [selecteddata, setSelectedData] = useState(rowDefaultValue);
  const form = useForm({
    defaultValues: {
      LglEntyId: selecteddata.LglEntyId,
      LglEntyNm: selecteddata.LglEntyNm,
      Atr1: selecteddata.Atr1,
    },
  });
  useEffect(() => {
    const hasUpdateAccess = env.LegalEntity_Update.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups]);

  const updateEntity = async () => {
    const formValues = form.getValues();
    setSaveIsButtonDisabled(true);

    if (legalEntityName !== '') {
      if (formValues?.LglEntyNm !== selecteddata.LglEntyNm) {
        try {
          await axios
            .put(
              `${url}`,
              {
                LglEntyNm: formValues.LglEntyNm,
                LglEntyId: formValues.LglEntyId,
                Atr1: formValues.Atr1,
                modifiedById: msid,
                modifiedDate,
              },
              {
                headers: {
                  authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then((dataa) => {
              setData(dataa);
              if(dataa.data === 'Modification error'){
                alert('Error: This data is already modified by other user');
              }else{
                alert(dataa.data);
              }
              setSaveIsButtonDisabled(false);
              navigate('/legal-entity');
            });
        } catch (error) {
          console.log(error);
          alert(
            'An error occurred while processing your request. Please try again later.'
          );
          setSaveIsButtonDisabled(false);
        }
      } else {
        alert(NOCHANGE_VALIDATION);
        setSaveIsButtonDisabled(false);
      }
    } else {
      alert(NOCHANGE_VALIDATION);
      setSaveIsButtonDisabled(false);
    }
  };
  const onSubmit = () => {
    // onSearch(data);
  };
  const cancelAlert = () => {
    const userConfirmed = window.confirm(CANCEL_UPDATE);
    if (userConfirmed) {
      navigate('/legal-entity');
    }
  };
  const cancelUpdate = () => {
    if (leID && legalEntityName && bUnit !== '') {
      cancelAlert();
    } else {
      navigate('/legal-entity');
    }
  };

  return (
    <div style={{ maxWidth: '100vw' }}>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ margin: '0px', background: '$headerBackgroundColor' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <HeaderDiv>
            <Header />
          </HeaderDiv>
        </Grid.Col>
      </Grid>
      <BodyContainer>
        <Grid css={{ margin: '0px' }}>
          <Grid.Col
            css={{ padding: '0px' }}
            span={{ xs: 0, md: 3, lg: 3, xl: 3 }}
          >
            <MediaQuery largerThan="$md">
              <Hamburger />
            </MediaQuery>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
            <BodyPageContainer>
              <SubHeader
                breadItem={state.newBraditem}
                infoTitle="Edit Legal Entity"
                InfoContent={AddHelpContent}
              />
              <FormProvider state={form} onSubmit={onSubmit}>
                <FormOuterContainer>
                  <FormContainer medium={isMedium}>
                    <TextInput
                      label={ENTITY_ID}
                      model="LglEntyId"
                      validators={{ required: ID_VALIDATION }}
                      value={selecteddata.LglEntyId}
                      isDisabled={isUpdateDisabled}
                    />

                    <TextInput
                      label={ENTITY_NAME}
                      model="LglEntyNm"
                      value={selecteddata.LglEntyNm}
                      onChange={(e) => {
                        return setLegalEntityName(e.target.value);
                      }}
                      maxLength={240}
                    />
                    <TextInput
                      label={BUSINESS_UNIT}
                      model="Atr1"
                      validators={{ required: UNIT_VALIDATION }}
                      value={selecteddata.Atr1}
                      isDisabled={isUpdateDisabled}
                    />
                    <Flex
                      direction="row"
                      justify="center"
                      className={styles.Layout}
                    >
                      <Button
                        type="submit"
                        className={styles.Button}
                        onClick={updateEntity}
                        isDisabled={isButtonDisabled || isSaveButtonDisabled}
                      >
                        {SAVE}
                      </Button>

                      <Button
                        onClick={() => {
                          form.setValue('LglEntyNm', leName);
                        }}
                        className={styles.Button}
                        isDisabled={isButtonDisabled}
                      >
                        {RESET}
                      </Button>
                      <Button
                        className={styles.Button}
                        onClick={cancelUpdate}
                        isDisabled={isButtonDisabled}
                      >
                        {CANCEL}
                      </Button>
                    </Flex>
                  </FormContainer>
                </FormOuterContainer>
              </FormProvider>
            </BodyPageContainer>
          </Grid.Col>
        </Grid>
      </BodyContainer>
      <Grid css={{ margin: '0px' }}>
        <Grid.Col
          css={{ background: '$pageFooter-color-background-root' }}
          span={{ xs: 12, md: 12, lg: 12, xl: 12 }}
        >
          <Footer />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default withAuth(UpdateLegalEntity);
