/* eslint-disable react/react-in-jsx-scope */
import { Grid } from '@abyss/web/ui/Grid';
import React, { useEffect, useState } from 'react';
import { Button } from '@abyss/web/ui/Button';
import axios from 'axios';
import { MlreDataTable } from '@src/common/MlreAbyssComponent';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { BASE_URL } from '../utils/endpoints';

export const CustomerHoldStatus = ({ data, onClose }) => {
  const [holdStatusData, setHoldStatusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alerted, setAlerted] = useState(false);
  const { navigate } = useRouter();

  const checkIfAllNull = (resonseData) => {
    return resonseData.every((item) => {
      return Object.values(item).every((val) => {
        return val === null || val === 'null' || val === 'N';
      });
    });
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Hold/Release By',
        accessor: 'payHldByUserId',
        disableSortBy: true,
      },
      {
        Header: 'Hold/Release Date',
        accessor: 'HLD_DT',
        disableSortBy: true,
        Cell: ({ value }) => {
          if (!value || value === null || value === 'null') {
            return '';
          }
          const date = new Date(value);
          const formattedDate = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}/${date
            .getDate()
            .toString()
            .padStart(2, '0')}/${date.getFullYear()}`;
          return formattedDate;
        },
      },

      {
        Header: 'Reason',
        accessor: 'rsnForHldDesc',
        disableSortBy: true,
      },
    ];
  }, []);

  useEffect(() => {
    // if (data) {
    //   const fetchData = async () => {
    try {
      axios
        .post(
          `${BASE_URL}customer/get-hold-list`,
          {
            custContrId: data[0]?.custContrId,
            // id: '1362480',
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('jwttoken')}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          const responseData = response.data;
          console.log('response data', responseData);
          if (checkIfAllNull(responseData)) {
            // setAlerted(true);
            alert('No policy on hold.');
            onClose();
            setHoldStatusData([]);
          } else if (responseData[0]?.mstrCustContrHldInd === 'Y') {
            setHoldStatusData(responseData);
            // setAlerted(false);
          } else {
            setHoldStatusData(response.data);
            alert('No policy on hold.');
            onClose();
          }
        });
    } catch (error) {
      setHoldStatusData([]);
      // setAlerted(true);
    }
    // }
    //   fetchData();
    // }
  }, []);

  console.log('outside holddata', holdStatusData);

  return (
    <Grid css={{ marginTop: '30px' }}>
      {/* {loading ? (
        <p />
      ) : alerted ? (
        alert('No policy on hold.')
      ) : */}
      {holdStatusData[0]?.mstrCustContrHldInd === 'Y' ? (
        <React.Fragment>
          <MlreDataTable data={holdStatusData} columns={columns} />
          <Grid.Col span={12} css={{ textAlign: 'center', marginTop: '10px' }}>
            <Button onClick={onClose}>Close</Button>
          </Grid.Col>
        </React.Fragment>
      ) : // : alerted ? (
      //   alert('No policy on hold.')
      // )
      null}
    </Grid>
  );
};
