import { Checkbox } from '@abyss/web/ui/Checkbox';
import React, { useEffect, useState } from 'react';
import useAuth from '@src/auth/useAuth';
import env from '../../../env.json';

type Props = {};

const ReissueCheckbox = ({
  row,
  value,
  checkStatus,
  handleCheckStatus,
}: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { userRolesGroups } = useAuth();

  useEffect(() => {
    const hasUpdateAccess = env.Pending_Reissue_Approval.some((role) => {
      return userRolesGroups.includes(role);
    });

    if (!hasUpdateAccess) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [userRolesGroups]);

  useEffect(() => {
    if (checkStatus[row.original.payDocNbr] !== value) {
      setIsChecked(false);
    }
  }, [checkStatus]);

  const handleChecked = (e) => {
    setIsChecked(e.target.checked);

    if (e.target.checked) {
      handleCheckStatus(row.original.payDocNbr, value, e.target.checked);
    } else {
      handleCheckStatus(row.original.payDocNbr, value, e.target.checked);
    }
  };
  return (
    <React.Fragment>
      {value === 'Approve' && (
        <Checkbox
          label="Select"
          hideLabel
          isChecked={isChecked}
          model={`approve_checked_${row.original.payDocNbr}`}
          onChange={handleChecked}
          isDisabled={isButtonDisabled}
        />
      )}
      {value === 'Reject' && (
        <Checkbox
          label="Select"
          hideLabel
          isChecked={isChecked}
          model={`reject_checked_${row.original.payDocNbr}`}
          onChange={handleChecked}
          isDisabled={isButtonDisabled}
        />
      )}
    </React.Fragment>
  );
};

export default ReissueCheckbox;
